
import { useState } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

const ModalAlert = (props) => {

    return (
        <Modal size="lg" isOpen={props.Show}>
            <ModalHeader>
                Info
            </ModalHeader>
            <ModalBody>
                <p>{props.Message}</p>
            </ModalBody>
            <ModalFooter>
                <Button onClick={props.Toggle}>
                    Ok
                </Button>
            </ModalFooter>
        </Modal>
        );
}

export default ModalAlert;