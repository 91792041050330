import React from "react";
import { Row, Col, Button } from "reactstrap";
import loadingchatpanel from "../../../assets/incident_load.png";
import "./State.css";
const LoaderPost = () => {
    return (
        <>
            <Row>
                <img src={loadingchatpanel} className="chatuserloader" />
            </Row>
            <Row>
                <img src={loadingchatpanel} className="chatuserloader" />
            </Row>
          
        </>
    );
};
export default LoaderPost;
