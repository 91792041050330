/*eslint unicode-bom: ["error", "always"]*/
import React, {useCallback, useState, useEffect } from "react";
import Avatar from 'react-avatar';
import Pagination from "../../components/Common/pagination";
import { Button, Modal,  Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faXmark,faSearch} from '@fortawesome/free-solid-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core';
import './AdminAgency.css';
import AuthService from "../../services/auth.service";
import {  ModalBody, ModalFooter, ModalHeader } from "react-bootstrap";
import authService from "../../services/auth.service";
import api from "../../services/api";
import joincard from "../../assets/join_card.png";
import { PostShimmeringEffect} from "./RequestAgency.Style";
import {NoResultFoundContainer} from "../../components/Department/Department.Style";

library.add(faCheck, faXmark, faSearch);
const RequestAgency = () => {
  
    // Modal open state

    const [isLoading, setIsLoading] = useState(true);

    const [listDepartments, setListDepartments] = useState([]);
    const [departmentValue, setDepartmentValue] = useState("");

    const [listDivisions, setListDivisions] = useState([]);
    const [divisionValue, setDivisionValue] = useState("");

    const [listStatus, setListStatus] = useState([]);
    const [statusValue, setStatusValue] = useState("");

    const [searchValue, setSearchValue] = useState("");
    const [isExecuteSearchPost, setIsExecuteSearchPost] = useState(false);
    const [isTextSearchExecuted, setIsTextSearchExecuted] = useState(false);

    const [listData, setListData] = useState([]);
    // eslint-disable-next-line no-unused-vars
    const [totalPages, setTotalPages] = useState(0);
    const [totalData, setTotalData] = useState(0);

    const [currentPage, setCurrentPage] = useState(1);

    // Use this kind of approach in Pagination especially the isPaginationLoaded that it needs to dispose the component once the totalData has been updated, selected previous page number is selected is shown
    const [isExecutePageChange, setIsExecutePageChange] = useState(false);
    const [isPaginationShow, setIsPaginationShow] = useState(false);
    const [isPaginationLoaded, setIsPaginationLoaded] = useState(false);

    const [selectedAll, setSelectedAll] = useState(false);
    const [allowBulk, setAllowBulk] = useState(true);

    const [listSelected, setListSelected] = useState([]);

    const [modalDenied, setModalDenied] = React.useState({
        show: false,
        id: "",
        name: "",
        userid:""
    });

    const [modalAccept, setModalAccept] = React.useState({
        show: false,
        id: "",
        name: "",
        userid:""
    });

    const ChangeDepartment = (e) => {
        setIsPaginationShow(false);
        setIsPaginationLoaded(false);
        setDepartmentValue(e.target.value);
        setCurrentPage(1);
        setIsExecutePageChange(true);
    }

    const ChangeDivision = (e) => {
        setIsPaginationShow(false);
        setIsPaginationLoaded(false);
        setDivisionValue(e.target.value);
        setCurrentPage(1);
        setIsExecutePageChange(true);
    }

    const ChangeSearch = (e) => {
        setSearchValue(e.target.value);
        setIsTextSearchExecuted(true);
        setIsExecuteSearchPost(false);
    }

    const ChangeStatus = (e) => {
        setIsPaginationShow(false);
        setIsPaginationLoaded(false);
        setStatusValue(e.target.value);
        setCurrentPage(1);
        setIsExecutePageChange(true);
    }

    const ChangeRadio = (e, item) => {
        const newElement = item.id;
        if (e.target.checked) {
            setListSelected(listSelected => [...listSelected, newElement]);
        }
        else {
            let filteredArray = listSelected.filter(op => op !== item.id);
            setListSelected(filteredArray);
        }
    }

    const ChangePage = useCallback((e) => {
        if(isPaginationLoaded) {
            setCurrentPage(e);
            setIsExecutePageChange(true);
        } else {
            setIsPaginationLoaded(true);
        }
        
    },[isPaginationLoaded])
  
    const SelectAll = useCallback(() => {
        let newarr = [];
        var ele = document.getElementsByClassName('radcheck');
        for (var i = 0; i < ele.length; i++) {
            if (ele[i].type === 'checkbox')
                ele[i].checked = true;
        }
        setSelectedAll(true);
        listData.forEach(x => {
            newarr.push(x.id);
        });
        setListSelected(newarr);
    },[listData])

    const UnSelectAll = () => {
        var ele = document.getElementsByClassName('radcheck');
        for (var i = 0; i < ele.length; i++) {
            if (ele[i].type === 'checkbox')
                ele[i].checked = false;
        }
        setSelectedAll(false);
        setListSelected([]);
    }

    const DenyRequest = (id,userid) => {
        setIsPaginationShow(false);
        setIsPaginationLoaded(false);
        setIsLoading(true);
     
        api.post("/agency/requestacceptordeny", {
            id: id,
            act: false,
            userid:userid
        }, AuthService.getTokenPost())
            .then(response => {
                if (response.data) {
                    toogleDeniedModal(false);
                    setCurrentPage(1);
                    setIsExecutePageChange(true);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const AcceptRequest = (id,userid) => {
        setIsPaginationShow(false);
        setIsPaginationLoaded(false);
        setIsLoading(true);

        api.post("/agency/requestacceptordeny", {
            id:id,
            act: true,
            userid:userid
        }, AuthService.getTokenPost())
            .then(response => {
                if (response.data) {
                    toogleAcceptModal(false);
                    setCurrentPage(1);
                    setIsExecutePageChange(true);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const SendConfirmations = () => {
        setIsPaginationShow(false);
        setIsPaginationLoaded(false);
        setIsLoading(true);

        api.post("/agency/acceptbulk", {
            ids: listSelected.toString()
        }, AuthService.getTokenPost())
            .then(response => {
                setCurrentPage(1);
                setIsExecutePageChange(true);
            })
            .catch((error) => {
                console.log(error);
            })
            ;
    }

    const toogleDenied = (agency) => {
        setModalDenied({
            ...modalDenied,
            show: !modalDenied.show,
            id: agency.id,
            name: agency.name,
            userid: agency.userId
        });

    }

    const toogleAccept = (agency) => {
        setModalAccept({
            ...modalAccept,
            show: !modalAccept.show,
            id: agency.id,
            name: agency.name,
            userid: agency.userId
        });

    }

    const toogleDeniedModal = (shomwod) => {
        setModalDenied({
            ...modalDenied,
            show: shomwod,
            id: "",
            name: "",
            userid:""
        });
    }

    const toogleAcceptModal = (shomwod) => {
        setModalAccept({
            ...modalAccept,
            show: shomwod,
            id: "",
            name: "",
            userid:""
        });

    }

    const getData = async (pageNo = 1, division = "", department = "", search = "",status="") => {
        const response = await api.get(
            `/agency/requests?page=${pageNo}&take=15&division=${division}&departament=${department}&search=${search}&status=${status}`
        );
        return response.data;

    };
   
    //Initialize data
    useEffect(() => {
        api.get(
            "/officer/divisions",
            authService.getToken())
            .then((response) => {
                setListDivisions(response.data);
            })
            .catch((error) => {
                console.log(error);
            });

        api.get(
            "/officer/admindepartaments"
            , authService.getToken())
            .then((response) => {
                if (response.data.length === 1) {
                    setAllowBulk(response.data[0].bulkRequest);
                }
                setListDepartments(response.data);
            })
            .catch((error) => {
                console.log(error);
            });

        api.get(
            "/officer/status"
            , authService.getToken())
            .then((response) => {
                setListStatus(response.data);
            })
            .catch((error) => {
                console.log(error);
            })

         //[Start] - Initialize list of data
         getData(1).then((info) => {
            const { totalPages, data, totalData } = info;
            setListData(data);
            setTotalPages(totalPages);
            setTotalData(totalData);
            setIsLoading(false);
            setIsPaginationShow(true);
        });
        //[End] - Initialize list of data
    }, []);

    //Set some delay when typing
    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            setIsExecuteSearchPost(false);
            setIsExecuteSearchPost(true);
        }, 1500)

        return () => clearTimeout(delayDebounceFn)
    }, [searchValue])

    //Reload data
    useEffect(() => {
        if(isExecutePageChange) {
            setIsLoading(true);
            setIsExecutePageChange(false); 
            getData(currentPage,divisionValue, departmentValue, searchValue,statusValue).then((info) => {
                const { totalPages, data, totalData } = info;
                setListData(data);
                setTotalPages(totalPages);
                setTotalData(totalData);
                setIsLoading(false);
                setIsPaginationShow(true);
            });
        }
    },[currentPage,isExecutePageChange,divisionValue,departmentValue,searchValue,statusValue]);

    useEffect(() => {
        if(isExecuteSearchPost === true && isTextSearchExecuted === true) {
            setIsPaginationShow(false);
            setIsPaginationLoaded(false);
            setCurrentPage(1);
            setIsExecutePageChange(true);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[isExecuteSearchPost,isTextSearchExecuted])

    return (
        <>
          
                <div className="row initialtop">

                    <div className="col-3">
                        <div className="input-group mb-12">
                           <span className="input-group-prepend">
                                <div className="input-group-text  border-right-0"> <FontAwesomeIcon icon={faSearch} /></div>
                            </span>
                            <input type="text" className="form-control" placeholder="Search officers..." onChange={ChangeSearch} value={searchValue} />
                           
                      
                    </div>
                        <div className="row">

                        </div>
                    </div>
                {listDepartments.length > 1 &&
                    <div className="col-3">

                        <div className="input-group mb-5">


                            <select className="form-control" onChange={ChangeDepartment} value={departmentValue} >
                                <option value="">All Departments</option>
                                {listDepartments.map((item) => (
                                    <option key={item.id} value={item.id}>{item.name}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                }
                    <div className="col-2">

                        <div className="input-group mb-5">
                         

                            <select className="form-control" onChange={ChangeDivision} value={divisionValue}>
                                <option value="">All Divisions</option>
                                {listDivisions.map((item) => (
                                    <option key={item.id} value={item.id}>{item.name}</option>
                                ))}

                            </select>
                        </div>
                    </div>
                    <div className="col-2">

                        <div className="input-group mb-5">
                      

                            <select className="form-control" value={statusValue} onChange={ChangeStatus}>
                                <option value="">All</option>
                                {listStatus.map((item) => (
                                    <option key={item.id} value={item.id}>{item.name}</option>
                                ))}


                            </select>
                        </div>
                </div>
                {allowBulk &&
                    <div className="col-2">
                        <button className="btn btn-primary" onClick={SendConfirmations} disabled={listSelected.length===0}>Accept Selected</button>
                    </div>
                     
                }
                {!selectedAll &&
                    <div className="col-2">
                        <button className="btn btn-primary btn-consistent-width" onClick={SelectAll}>Select all</button>
                    </div>
                }
                {selectedAll &&
                    <div className="col-2">
                        <button className="btn btn-secondary btn-consistent-width" onClick={UnSelectAll}>Unselected</button>
                    </div>
                }
                </div>
                <div>
                    <div className="row">

                    {
                        isLoading ? 
                        <>
                            <PostShimmeringEffect />
                        </>
                        : 
                        <>
                             {listData.length > 0 ? 
                             <>
                                {listData.map((item) => (
                                    <div className="col-3" key={['idagency', item.id]}>
                                        <div className="row  card bg-white">
                                            <div className="row">
                                                <div className="col-12 rowimgcard">
                                                <div className="row imgcardofficers">
                                                    {item.urlPic &&
                                                        <Avatar src={item.urlPic} size="80" round className="avatarcard"/> 

                                                    }
                                                    {!item.urlPic &&
                                                        <Avatar name={item.userName} size="80" round className="avatarcard" />

                                                        }
                                                    
                                                    </div>
                                                    {allowBulk && item.state !== 'Accepted' && item.state !== "Removed" &&


                                                        <input type="checkbox" className="radcheck" onClick={(e) => ChangeRadio(e, item)} />
                                                    }
                                            </div>
                                        
                                            </div>
                                            <div className="col-12">
                                                <div className="row mg03">   <label className="namelabel">{item.userName}</label></div>
                                                <div className="row mg03">   <label className="divisionlabel">{item.division}</label></div>
                                                <div className="row mg03">   <label className="bluelabel">{item.state == "Pending" ? "Pending Request" : "Approved Request"}</label></div>
                                                <div className="row mg03 cardaddress"> <div className="col-3"> <img alt="" className="imgjoincard" src={joincard}/></div><div className="col-9"><label className="agencylabel">{item.agencyName}</label></div>  </div>

                                            </div>
                                            <div className="col-12">
                                                {item.state === 'Removed' &&
                                                    <div className="row mg1">
                                                    <div className="col-12"><button disabled className="btn btn-primary btncancel btnfullsize" onClick={() => toogleAccept(item)}>Rejected</button></div>
                                                    </div>
                                                }
        
                                                {item.state === 'Accepted' &&
                                                    <div className="row mg1">
                                                        <div className="col-12"><button disabled className="btn btn-primary btnaccept btnfullsize" onClick={() => toogleAccept(item)}>Accepted</button></div>
                                                </div>
                                                    }
                                                    {item.state !== 'Accepted'&& item.state !=="Removed" &&
                                                    <div className="row mg1">
                                                    <div className="col-6"><button className="btn btn-secondary btncancel btnfullsize" onClick={() => toogleDenied(item)}>Reject</button></div>
                                                    <div className="col-6"><button className="btn btn-primary btnaccept btnfullsize" onClick={()=>toogleAccept(item)}>Accept</button></div>
                                                    </div>
                                                    }
                                                    
                                            </div>
                                        </div>
                                    </div>
                                ))
                                }
                             </> 
                             : 
                             <NoResultFoundContainer message={"No results found"} />
                             }
                         </>
                    }

                </div>
                <div className="row" >
                    {isPaginationShow &&  <Pagination
                        totalRows={totalData}
                        pageChangeHandler={ChangePage}
                        rowsPerPage={15}
                        />
                    }
                  </div>
               </div>

            <Modal size="sm" isOpen={modalAccept.show}>
                <ModalHeader>
                    Accept Request
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <label >Are you sure you want to accept this request?</label>
                    </Row>

                </ModalBody>
                <ModalFooter>
                    <Button
                        color="primary"
                        onClick={() => AcceptRequest(modalAccept.id,modalAccept.userid)}
                    >
                        Accept
                    </Button>
                    {' '}
                    <Button onClick={toogleAccept}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
            <Modal size="sm" isOpen={modalDenied.show}>
                <ModalHeader>
                    Deny Request
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <label >Are you sure you want to deny this request?</label>
                    </Row>

                </ModalBody>
                <ModalFooter>
                    <Button
                        color="primary"
                        onClick={() => DenyRequest(modalDenied.id,modalDenied.userid)}
                    >
                        Deny
                    </Button>
                    {' '}
                    <Button onClick={toogleDenied}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
          
        </>
    );
}

export const PrimaryFontFamily = "Work Sans";
export const ButtonFontStyle = {
    fontfamily: PrimaryFontFamily,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
}

export default RequestAgency;