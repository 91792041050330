/*eslint unicode-bom: ["error", "always"]*/
import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from 'react-router-dom';
import Pagination from "../../components/Common/pagination";
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import './GridOfficer.css';
import Avatar from 'react-avatar';
import authService from "../../services/auth.service";
import api from "../../services/api";
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NotificationManager } from 'react-notifications';
import StatusBadge from "./StatusBadge";
import {
    PostShimmeringEffect,
    NoResultFoundContainer
} from "../../components/Department/Department.Style";
library.add(faEdit, faTrash, faSearch);

const GridOfficer = (props) => {
    let history = useHistory();

    const [isLoading, setIsLoading] = useState(true);

    const [listDepartments, setListDepartments] = useState([]);
    const [departmentValue, setDepartmentValue] = useState("");

    const [listDivisions, setListDivisions] = useState([]);
    const [divisionValue, setDivisionValue] = useState("");

    const [searchValue, setSearchValue] = useState("");
    const [isExecuteSearchPost, setIsExecuteSearchPost] = useState(false);
    const [isTextSearchExecuted, setIsTextSearchExecuted] = useState(false);

    const [listData, setListData] = useState([]);
    // eslint-disable-next-line no-unused-vars
    const [totalPages, setTotalPages] = useState(0);
    const [totalData, setTotalData] = useState(0);

    const [currentPage, setCurrentPage] = useState(1);

    // Use this kind of approach in Pagination especially the isPaginationLoaded that it needs to dispose the component once the totalData has been updated, selected previous page number is selected is shown
    const [isExecutePageChange, setIsExecutePageChange] = useState(false);
    const [isPaginationShow, setIsPaginationShow] = useState(false);
    const [isPaginationLoaded, setIsPaginationLoaded] = useState(false);

    const ChangeDepartment = (e) => {
        setIsPaginationShow(false);
        setIsPaginationLoaded(false);
        setDepartmentValue(e.target.value);
        setCurrentPage(1);
        setIsExecutePageChange(true);
    }

    const ChangeDivision = (e) => {
        setIsPaginationShow(false);
        setIsPaginationLoaded(false);
        setDivisionValue(e.target.value);
        setCurrentPage(1);
        setIsExecutePageChange(true);
    }

    const ChangeSearch = (e) => {
        setSearchValue(e.target.value);
        setIsTextSearchExecuted(true);
        setIsExecuteSearchPost(false);
    }

    const ChangePage = useCallback((e) => {
        if(isPaginationLoaded) {
            setCurrentPage(e);
            setIsExecutePageChange(true);

        } else {
            setIsPaginationLoaded(true);
        }
        
    },[isPaginationLoaded])


    const RedirectMessage = (state, userId, name, type, urlPic, phone, email, address, typechat) => {
        if (state != null && (state === "Suspended" || state === "Removed")) {
            NotificationManager.info('Information', 'The account cannot be contacted because it was deleted or suspended.', 5000);
            return;
        }

        let img = urlPic;
        if (props.Incident !== undefined && (props.Incident.filesIncidents != null && props.Incident.filesIncidents !== undefined && props.Incident.filesIncidents.length > 0)) {
            img = props.Incident.filesIncidents[0].file.path;
        }

        history.push({
            pathname: `/messages`,
            search: `?userId=${userId}&name=${name}&type=${props.Incident !== undefined ? props.Incident.type : type}&typechat=${typechat}&incident=${props.Incident !== undefined ? props.Incident.id : ""}&address=${props.Incident !== undefined ? props.Incident.address : ""}`,
            state: {
                urlPic: img,
                userId: userId,
                name: name,
                type: (props.Incident !== undefined ? props.Incident.type : type),
                typechat: typechat,
                incident: props.Incident !== undefined ? props.Incident.id : "",
                address: props.Incident !== undefined ? props.Incident.address : "",
                phone: phone,
                email: email,
                userAddress: address
            }

        });

    }

    const getData = async (pageNo, division = "", departament = "",search = "") => {
        const response = await api.get(`/officer/agencies/get?page=${pageNo}&take=10&division=${division}&departament=${departament}&search=${search}`, authService.getToken());
        return response.data;
    };

    //Initialize data
    useEffect(() => {

        //[Start] - Initialize department list
        api.get(
          "agency/officer/departments"
        , authService.getToken())
            .then((response) => {
                setListDepartments(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
        //[End] - Initialize department list

        //[Start] - Initialize division list
        api.get(
            "/officer/divisions",
         authService.getToken())
            .then((response) => {
                setListDivisions(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
        //[End] - Initialize division list

        //[Start] - Initialize list of data
        getData(1).then((info) => {
            const { totalPages, data, totalData } = info;
            setListData(data);
            setTotalPages(totalPages);
            setTotalData(totalData);
            setIsLoading(false);
            setIsPaginationShow(true);
        });
        //[End] - Initialize list of data

    }, []);

    //Reload data
    useEffect(() => {
        if(isExecutePageChange) {
            setIsLoading(true);
            setIsExecutePageChange(false); 
            getData(currentPage,divisionValue, departmentValue, searchValue).then((info) => {
                const { totalPages, data, totalData } = info;
                setListData(data);
                setTotalPages(totalPages);
                setTotalData(totalData);
                setIsLoading(false);
                setIsPaginationShow(true);
            });
        }
    },[currentPage,isExecutePageChange,divisionValue,departmentValue,searchValue]);

    //Set some delay when typing
    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            setIsExecuteSearchPost(false);
            setIsExecuteSearchPost(true);
        }, 1500)

        return () => clearTimeout(delayDebounceFn)
    }, [searchValue])

    useEffect(() => {
        if(isExecuteSearchPost === true && isTextSearchExecuted === true) {
            setIsPaginationShow(false);
            setIsPaginationLoaded(false);
            setCurrentPage(1);
            setIsExecutePageChange(true);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[isExecuteSearchPost,isTextSearchExecuted])
  
    return (
        <>
            <div>
                <div className="row">
                    <div className="col-4">
                        <div className="input-group mb-5">
                            <select className="form-control" onChange={ChangeDepartment} value={departmentValue} >
                            <option value="">Departments...</option>
                                {listDepartments.map((item) => (
                                    <option key={item.id} value={item.id}>{item.name}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="input-group mb-5">
                            <select className="form-control" onChange={ChangeDivision} value={divisionValue}>
                                <option value="">Divisions...</option>
                                {listDivisions.map((item) => (
                                    <option key={item.id} value={item.id}>{item.name}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="input-group mb-12">
                            <span className="input-group-prepend">
                                <div className="input-group-text  border-right-0"> <FontAwesomeIcon icon={faSearch} /></div>
                            </span>
                            <input type="text" className="form-control" placeholder="Search officers..." onChange={ChangeSearch} value={searchValue} />
                            <span className="input-group-append"></span>
                        </div>
                    </div>
                </div>

                {
                        isLoading ? 
                        <div>
                            <div className="row inline-elem">
                                <div className="col-6" style={{ marginBottom: "25px" }}><PostShimmeringEffect /></div>
                                <div className="col-6" style={{ marginBottom: "25px" }}><PostShimmeringEffect /></div>
                            </div>
                            <div className="row inline-elem">
                                <div className="col-6" style={{ marginBottom: "25px" }}><PostShimmeringEffect /></div>
                                <div className="col-6" style={{ marginBottom: "25px" }}><PostShimmeringEffect /></div>
                            </div>
                        </div>
                        : 
                        <div>
                            <div className="row">
                                {listData.length > 0 ? 
                                <>
                                    {listData.map((item) => (
                                        <div className="col-6" key={['idoffice', item.id]}>
                                            <div className="row  card-officer">
                                                <div className="col-3 card no-mgr">
                                                    <div className="row">
                                                        {item.urlPic && <Avatar src={item.urlPic} size="80" round  className="avatarofficer" />}
                                                        {!item.urlPic && <Avatar name={item.name} size="80" round />}
                                                    </div>
                                                    <div className="row" style={{flex: "1", alignItems:"center", justifyContent:"center", fontFamily: "Work Sans"}}>
                                                        <button type="button" 
                                                                disabled={item.deleted ? true : item.statusApp === "Suspended" ? true : false}
                                                                className="btn btn-outline-primary btn-lg btn-bloc col-lg-10"  
                                                                onClick={() => RedirectMessage(item.statusApp, item.id, item.name, item.division, item.urlPic, item.phone, item.email, item.address, "user")}>
                                                            <div style={{fontSize: "14px"}}>Message</div>
                                                        </button>
                                                    </div>
                                                </div>
                                                
                                                <div className="col-8 card bg-white no-mgl">
                                                        <div className="row" style={{textAlign: "right", paddingRight: "15px"}}><span>{StatusBadge(item.deleted ? 3 : item.statusApp === "Suspended" ? 2 : 1)}</span></div>
                                                        <div className="row"> <label className="titlename">{item.name}</label></div>
                                                        <div className="row"> <label className="lblinfo">Division:  <span className="txtinfo">{item.division}</span></label></div>
                                                        <div className="row"> <label className="lblinfo">Department:  <span className="txtinfo">{item.department}</span></label></div>
                                                        <div className="row"> <label className="lblinfo">Phone:   <span className="txtinfo"><a href={`tel:${item.phone ?? ""}`}>{item.phone}</a></span></label></div>
                                                        <div className="row"> <label className="lblinfo">Email: <span className="txtinfo"><a href={`mailto:${item.email}`}>{item.email}</a></span></label> </div>
                                                        <div className="row"> <label className="lblinfo">Address: <span className="txtinfo">{item.address}</span></label> </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </> 
                                : 
                                <NoResultFoundContainer message={"No results found"} />
                                }
                            </div>                            
                        </div>
                }

                  <div className="row" >
                    {isPaginationShow &&  <Pagination
                        totalRows={totalData}
                        pageChangeHandler={ChangePage}
                        rowsPerPage={10}
                        />
                    
                    }
                  </div>


            </div>
        </>
    );
}
export default GridOfficer;