import { useState, useEffect} from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader, Row, Col, Button, Form } from "reactstrap"
import api from "../../../services/api";
import AuthService from "../../../services/auth.service";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import Table from "../../../components/Common/table";
import Pagination from "../../../components/Common/pagination";
import { useForm } from "react-hook-form";

const AddRelateCrimeModal = (props) => {
    const [loading, setLoading] = useState(false);
    const [name, setName] = useState();
    const { data } = props.data;

    const saveRelateCrime = (data) => {
        setLoading(true);
        var formData = new FormData();

        formData.append("Name", data.name);
        formData.append("IncidentId", props.data.post.id);
        formData.append("FolderId", props.data.data.id);

        api.post("/releatedcrimes/relatecrime",formData, AuthService.getTokenPost()).then(response => {
            NotificationManager.success('The relate crime created successfully', 'Success', 5000);
            props.ReloadRelated();
            setLoading(false);
            props.closeRelateCrime();
        });
    }

    const { register, handleSubmit, setValue, formState: { errors }, setError, reset } = useForm({
        mode: 'onChange'
    });

    const onNameChange = (e) => {
        setName(e.target.value);
    }
    const [pageData, setPageData] = useState({
        rowData: [],
        isLoading: false,
        totalPages: 0,
        totalData: 0,
    });
    const loadRelated =  () => {

        if (data !== undefined && data.id !== undefined)
        {
            api.get(
                `/releatedcrimes/getfolderrelated?page=${currentPage}&take=7&folder=${data.id}`, AuthService.getToken()
            ).then((info) => {
                const { totalPages, data, totalData } = info.data;
                setPageData({
                    isLoading: false,
                    rowData: data,
                    totalPages,
                    totalData,
                });
            });
        }
       
    }

    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        loadRelated();
    }, [data]);
       
    const columns = [
        {
            Header: "Related crime",
            accessor: "name",
        },
       
    ];

    return <>
        <Modal size="md" isOpen={props.show}>
            <ModalHeader>
                <center>Relate Crime</center>
            </ModalHeader>
            <ModalBody>
                <Form onSubmit={handleSubmit(saveRelateCrime)}>
                    <Row>
                        <Col sm="2"></Col>
                        <Col>
                            Related CrimeX: {props.data === undefined || props.data.post === undefined ? "" : <a>{props.data.post.incidentNum}</a>}
                        </Col>
                        <Col sm="2">
                        </Col>
                    </Row>
                    <br />
                    <hr />
                    <Row>
                        <Col></Col>
                        <Col><center>To</center></Col>
                        <Col></Col>
                    </Row>
                    <br />
                    <Row>
                        <Col>
                            <div className="input-group mb-12">
                                <input type="text" className={errors.name ? 'form-control is-invalid' : 'form-control'} {...register("name", { required: true, maxLength: 200 })} placeholder="Save as..." />
                                <div className="input-group-append">
                                    <span className="input-group-text" id="basic-addon2" >GO!</span>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <br />

                    <Row>
                        <div>
                            <div>
                                <Table columns={columns} data={pageData.rowData}></Table>
                            </div>

                            <Pagination
                                totalRows={pageData.totalData}
                                pageChangeHandler={setCurrentPage}
                                rowsPerPage={15}
                            />
                        </div>

                    </Row>

                    <ModalFooter>
                        <Button
                            type="submit"
                            color="primary"
                           
                            disabled={loading}
                        >
                            Save
                            {loading && (
                                <span className="spinner-border spinner-border-sm"></span>
                            )}
                        </Button>
                        {' '}
                        <Button onClick={() => {
                            props.closeRelateCrime();
                        }}>

                            Cancel
                        </Button>
                    </ModalFooter>
                </Form>
              
            </ModalBody>
          
        </Modal>
        <NotificationContainer />
    </>
}

export default AddRelateCrimeModal;