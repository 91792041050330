import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Route } from 'react-router';
import { HomePost } from './components/HomePost';
import { Layout } from './components/Layout';
import { LayoutNoLogged } from './components/LayoutNoLogged';
import { LayoutAdmin } from './components/LayoutAdmin';
import { Login } from './components/Login';
import { NewLogin } from './v2/Authentication/Login'
import { MyPost, SavedPost } from './components/MyPost';
import { Post, GridPost, PostInfo } from './components/Post';
import { Account } from './components/Account'
import { CreateAgency } from './components/Agency';
import ViewAgency from './components/Agency/ViewAgency';
import { GridAccount } from './components/Officer';
import { AdminAgency, RequestAgency } from './components/Admin';
import ForgotPassword from './components/ForgotPassword/ForgotPassword';
import { NewForgotPassword } from './v2/Authentication/ForgotPassword';
import ResetPassword from './components/ForgotPassword/ResetPassword';
import { NewResetPassword } from './v2/Authentication/ResetPassword';
import './custom.css'
import ChatPanel from './components/Chat/ChatPanel';
import UserInvitationResponse from './components/Agency/UserAgencyInvitation';
import { NewUserInvitationResponse } from './v2/Authentication/UserAgencyInvitation';
import IdleTimer from "./services/IdleTimer";
import RequestedAgencies from './components/Agency/Officer/RequestedAgencies';
import ResetPasswordGenerate from './components/Account/resetPasswordGenerate';
import { NewResetPasswordGenerate } from './v2/Authentication/ResetPasswordGenerate';
import RelatedCrimes from './components/ReleatedCrimes/RelatedCrimes';
import Folders from './components/ReleatedCrimes/Folders/Folders';
import authService from './services/auth.service';
import { Department } from "./components/Department"
import { ReportedPost } from './components/ReportedPost';
import "@fontsource/work-sans";
import signalRserive from './services/signalRserive';



const App = () => {
    let history = useHistory();
    const [isTimeout, setIsTimeout] = useState(false);
    const [user, setUser] = useState(authService.getCurrentUser());
    const [roles, setRoles] = useState(authService.getRoles());
    

    
    useEffect(() => {
        const timer = new IdleTimer({
            timeout: 14400, //expire after 4 hours
            onTimeout: () => {
                history.push("/login");
                history.go(0);
                authService.logout();
            },
            onExpired: () => {
                // do something if expired on load
                this.cleanUp();
                history.push("/login");
                history.go(0);
                authService.logout();
                
            }
        });

        if (roles && roles != null && roles.length > 0) {
            signalRserive.initConnection();
        }

        return () => {
            timer.cleanUp();
        };
    }, []);


   if (roles.length > 0 && roles.includes("SuperAdmin")) {
        return (

            //SUPERADMIN
            <LayoutAdmin>
                <Route exact path='/' component={GridPost} />
                <Route exact path='/home' component={GridPost} />
                <Route exact path='/posts' component={GridPost} />
                <Route exact path='/requests' component={RequestAgency} />
                <Route exact path='/account' component={Account} />
                <Route exact path='/manageagencies' component={AdminAgency} />
                <Route exact path={`/post/view/:id`} component={PostInfo} />
                <Route exact path='/agency/create' component={CreateAgency} />    {/*agency/create?agency=1*/}
                <Route exact path='/agency/view' component={ViewAgency} />
                <Route path="/forgotpassword" component={ForgotPassword} />
                <Route path="/resetpassword" component={ResetPassword} />
                <Route path='/messages' component={ChatPanel} />
                <Route path='/account/welcome' component={UserInvitationResponse} />
                <Route exact path='/officers' component={GridAccount} />
                <Route exact path='/my-agencies' component={RequestedAgencies} />
                <Route exact path='/department' component={Department} />
                <Route exact path='/reportedpost' component={ReportedPost} />
            </LayoutAdmin>
        );
    }
    if (roles.length > 0 && roles.includes("Admin")) {
        return (

            //ADMIN
            <LayoutAdmin>
                <Route exact path='/' component={GridPost} />
                <Route exact path='/home' component={GridPost} />
                <Route exact path='/posts' component={GridPost} />
                <Route exact path='/requests' component={RequestAgency} />
                <Route exact path='/myposts' component={MyPost} />
                <Route exact path='/mysavedposts' component={SavedPost} />
                <Route exact path={`/post/edit/:id`} component={Post} />
                <Route exact path={`/post/view/:id`} component={PostInfo} />
                <Route exact path='/account' component={Account} />
                <Route exact path='/agencies' component={RequestedAgencies} />
                <Route exact path='/department' component={Department} />
                <Route exact path='/reportedpost' component={ReportedPost} />
                <Route exact path='/manageagencies' component={AdminAgency} />
                <Route exact path='/post/create' component={Post} />
                <Route path="/forgotpassword" component={ForgotPassword} />
                <Route path="/resetpassword" component={ResetPassword} />
                <Route path='/messages' component={ChatPanel} />
                <Route path='/account/welcome' component={UserInvitationResponse} />
                <Route exact path='/my-agencies' component={RequestedAgencies} />
                <Route exact path='/officers' component={GridAccount} />
                <Route exact path='/agency/create' component={CreateAgency} />
                <Route exact path='/agency/view' component={ViewAgency} />
                <Route path='/folders' component={Folders} />
                <Route path='/releatedcrimes/view/:id' component={RelatedCrimes} />
            </LayoutAdmin>
        );
    }
    if (roles.length > 0 && roles.includes("Officer")) {
        //OFFICER
        return (
            <Layout>
                <Route exact path='/' component={GridPost} />
                <Route exact path='/home' component={GridPost} />
                <Route exact path='/myposts' component={MyPost} />
                <Route exact path='/mysavedposts' component={SavedPost} />
                <Route exact path={`/post/edit/:id`} component={Post} />
                <Route exact path={`/post/view/:id`} component={PostInfo} />
                <Route exact path='/account' component={Account} />
                <Route exact path='/agencies' component={AdminAgency} />
                <Route exact path='/department' component={Department} />
                <Route exact path='/officers' component={GridAccount} />
                <Route exact path='/agency/create' component={CreateAgency} />
                <Route exact path='/agency/view' component={ViewAgency} />
                <Route exact path='/post/create' component={Post} />
                <Route path="/forgotpassword" component={ForgotPassword} />
                <Route path="/resetpassword" component={ResetPassword} />
                <Route path='/messages' component={ChatPanel} />
                <Route path='/account/welcome' component={UserInvitationResponse} />

                <Route path='/folders' component={Folders} />
                <Route path='/releatedcrimes/view/:id' component={RelatedCrimes} />
            </Layout>
        );
    }
    if (roles.length === 0) {
        
        return (
            //NO LOGGED
            <LayoutNoLogged>
                <Route exact path='/' component={NewLogin} />
                <Route exact path='/login' component={NewLogin} />
                <Route path="/forgotpassword" component={NewForgotPassword} />
                <Route path="/resetpassword" component={NewResetPassword} />
                <Route path='/account/welcome' component={NewUserInvitationResponse} />
                <Route path='/resetpasswordRegenerate' component={NewResetPasswordGenerate} />

            </LayoutNoLogged>
        );
    }
}
export default App;