/*eslint unicode-bom: ["error", "always"]*/
import axios from "axios";
import api from "../services/api";
let Currentchat = {};
class MessageService
{
     
    sendMessage(message)

    {
        
        return api
            .post("/api/chat/messages", message,this.getToken())
            .then(response =>
            {
                Currentchat = response.data;
                return response.data;
            });
    }

    getChats(filter) {
        return api.get("/api/chat/chats?filter="+filter, this.getToken())
            .then(response => {
                return response.data;
            });
    }

    getChatGroup(incident) {
        return api.get("/api/chat/groupchat?incident=" + incident, this.getToken())
            .then(response => {
                return response.data;
            });
    }

    getToken() {
        var userData = JSON.parse(localStorage.getItem("user"));
        return {
            headers: {
                'Authorization': `Bearer ${userData.token}`
            }
        };
    }

    getMessages(chatId)
    {
        if (chatId === undefined) return {
            data: {}
        };

        return axios.get("/api/chat/getmessages?chatId="+chatId, this.getToken())
            .then(response => {
                return response.data;
            });
    }

    setChatId(id) {
        Currentchat.chat_Id = id;
    }
    getChatId() {
        return Currentchat.chat_Id;
    }
}

export default new MessageService();