
import Message from './Message';


const ChatWindow = (props) => {
    const chat = props.chat !== undefined? props.chat
        .map(m => <Message
            key={Date.now() * Math.random()}
            chat={m} />) : "";
    return (
        <>
            {chat}
        </>
           
    )
};
export default ChatWindow;