
import React, {useState} from "react";
import "./ReportedPost.css"
import { CardContent } from "@mui/material";
import { PopoverBody, UncontrolledPopover } from 'reactstrap';
import { ReactComponent as ClockIcon } from './../../assets/clock.svg';
import { ReactComponent as DepartmentIcon } from './../../assets/Department.svg';  
import { ReactComponent as EmailIcon } from './../../assets/Email.svg'; 
import { ReactComponent as FolderIcon } from './../../assets/folder.svg'; 
import { ReactComponent as NoPreviewIcon }  from "./../../assets/nopreviewv2.svg"; 
import { ReactComponent as NoResultFoundImage } from './../../assets/no_result_found.svg';  
import { ReactComponent as PhoneIcon } from './../../assets/Phone.svg'; 
import { ReactComponent as PinLocationIcon } from './../../assets/pin_location.svg'; 
import { ReactComponent as WarningIcon } from './../../assets/warning.svg';  
import { red } from '@mui/material/colors';
import { UsedReportedPostContext } from "./ReportedPost.Provider";
import AuthService from "../../services/auth.service";
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import Col from 'react-bootstrap/Col';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import moment from 'moment';
import Paper from '@mui/material/Paper';
import Row from 'react-bootstrap/Row';
import SearchIcon from '@mui/icons-material/Search';
import Select from 'react-select';
import Skeleton from '@mui/material/Skeleton';
import StatusBadge from "../Officer/StatusBadge";

const CommonPaperStyle = {
    component: "form", 
    elevation: 0,
    variant: "outlined",
    sx: {
        display: 'flex', alignItems: 'center'
    }
};

export const StatusReport = {
    Pending: 0,
    Ignored: 1,
    Resolved: 2,
    Removed: 3
}

export const PrimaryFontFamily = "Work Sans";

//[Start] - Header
export const HeaderLabel = ({label}) => {
    return (
        <Row>
            <Col>
                <div className="common_font_style header_label_style">{label}</div>
            </Col>
        </Row>
    );
}
//[End] - Header

//[Start] - Search Keywords Text Field
export const SearchKeywordsField = ({onChange}) => {
    return (
        <Paper {...CommonPaperStyle} >
            <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                <SearchIcon />
            </IconButton>
            <InputBase sx={{ ml: 1, flex: 1 }} placeholder="Search keywords.." inputProps={{ 'work sans': 'search keywords' }} onChange={onChange}/>
        </Paper>
    );
}
//[End] - Search Keywords Text Field

//[Start] - Date Range Picker Field
const dateRangeInitialSettings = ({startDate, endDate}) => {
    return {
        startDate: startDate ? startDate.toDate() : moment().subtract(29, 'days'),
        endDate: endDate? endDate.toDate() : moment(),
        ranges: {
            Today: [moment().toDate(), moment().toDate()],
            Yesterday: [
                moment().subtract(1, 'days').toDate(),
                moment().subtract(1, 'days').toDate(),
            ],
            'Last 7 Days': [
                moment().subtract(6, 'days').toDate(),
                moment().toDate(),
            ],
            'Last 30 Days': [
                moment().subtract(29, 'days').toDate(),
                moment().toDate(),
            ],
            'This Month': [
                moment().startOf('month').toDate(),
                moment().endOf('month').toDate(),
            ],
            'Last Month': [
                moment().subtract(1, 'month').startOf('month').toDate(),
                moment().subtract(1, 'month').endOf('month').toDate(),
            ],
        },
    }
}

export const InitialDateRangeState = () => {
    return {
        startDate: moment().subtract(1, 'years'),
        endDate: moment(),
    }
}

export const DatePickerField = (props) => {
    const [selectedDateRange, setSelectedDateRange] = useState(InitialDateRangeState);

    const { startDate, endDate } = selectedDateRange;

    const handleCallback = (startDate, endDate) => {
        setSelectedDateRange({ startDate, endDate });
        props.onChangeDateValues({ startDate, endDate });
    };

    return (
        <Paper {...CommonPaperStyle}  >
            <InputBase sx={{ ml: 1, flex: 1 }} 
                       placeholder="All time" 
                       inputProps={{ 'work sans': 'all time' }} 
                       value={startDate.format('MMMM D, YYYY') + ' - ' + endDate.format('MMMM D, YYYY')} />
            <DateRangePicker initialSettings={dateRangeInitialSettings(startDate.toDate(), endDate.toDate())} onCallback={handleCallback}>
                <IconButton type="button" sx={{ p: '10px' }} aria-label="all time" >
                    <CalendarMonthIcon />
                </IconButton>
            </DateRangePicker>
        </Paper>
    );

}
//[End] - Date Range Picker Field

//[Start] - Department drodown
export const DepartmentDropDownField = ({data, onChange}) => {
    return (
        <Select className="basic-single"
                classNamePrefix="departments"
                components={{IndicatorSeparator: () => null}}
                defaultValue={data[0]}
                isDisabled={false}
                isLoading={false}
                isRtl={false}
                isSearchable={true}
                name="color"
                onChange={onChange}
                options={data}
                styles={{control: styles => 
                    ({ ...styles
                        , minHeight: "45px"
                        , borderColor: "#cccccc"
                        , boxShadow: "0 0 0 0px #cccccc"
                        , "&:hover": {
                            borderColor: "#cccccc"
                        }}
                    )
                }}
    />);
}
//[End] - Department drodown

//[Start] - Reported Post Container
export const ReportedPostContainer = ({data}) => {
    return (
        <Card {...ReportedPostMainContainerStyle}>
            <ReportedPostHeaderContainer data={data}/>
            <IncidentPostInnerDetailContainer id={data.id} data={data.incident} />
        </Card>

    );
}

const SubHeaderContainerStyle = {
    display: "flex", 
    flexDirection: "row", 
    fontFamily: PrimaryFontFamily,
    fontSize: "16px",
    fontWeight: 400, 
    paddingTop: "10px"
}

export const UserPopOverContainer = (props) => {
    return (<div>
       <div className="user_popover_container">
            <div className="user_popover_header_container">
                <div className="user_popover_header_avatar_container">
                    <Avatar src={props.profilePicSource} sx={{ bgcolor: red[500], width: 80, height: 80 }} aria-label="recipe">{props.initials}</Avatar> 
                </div>
                <div className="user_popover_header_info_container">
                    <div style={{fontWeight: 600, color: "#002542"}}>{props.fullName}</div>
                    <div style={{fontWeight: 400, color: "#5F798C"}}>{props.division}</div>
                </div>
            </div>
            <div className="user_popover_body_container">
                <div className="user_popover_body_row_container">
                    <div style={{marginRight: "15px"}}><DepartmentIcon width={25} height={25} /></div>
                    <div>{props.department ? (props.department.trim() === "" ? "-" : props.department.trim()) 
                                            : 
                                            ("-")}
                    </div>
                </div>
                <div className="user_popover_body_row_container">
                    <div style={{marginRight: "15px"}}><PhoneIcon width={25} height={25} /></div>
                    <div>{props.phoneNumber.trim() === "" ? "-" : props.phoneNumber.trim()}</div>
                </div>
                <div className="user_popover_body_row_container">
                    <div style={{marginRight: "15px"}}><EmailIcon width={25} height={25} /></div>
                    <div>{props.email.trim() === "" ? "-" : props.email.trim()}</div>
                </div>
            </div>
            <div className="user_popover_footer_container">

                <Button variant="outlined" sx={{borderColor: "#008EEE"}} disabled={props.isUserDeleted || props.isUserSuspended}>
                    <div style={{...ButtonFontStyle, color: "#008EEE"}}>Message</div>
                </Button>
            </div>   
        </div>
    </div>);
}

const ReportedPostHeaderContainer = ({data}) => {

    const reportedPostContext = UsedReportedPostContext();

    // eslint-disable-next-line no-unused-vars
    const [userRoles, setUserRoles] = useState(AuthService.getRoles());

    const handleReportPostUpdate = (statusReportId, id) => {
        reportedPostContext.onSetStatusReportId(statusReportId);
        reportedPostContext.onSetReportedPostId(id);
        reportedPostContext.onShowStatusReportDialog(true);
    }

    const AnonymousContainer = () => {
        return (<>
            <div style={{fontWeight: 400, fontSize: "14px", lineHeight: "24px"}}>Anonymous</div>
            </>);
    }

    const ReportedByContainer = () => {
        return (
            <>
                <div style={{fontWeight: 500, fontSize: "16px", color: "#008EEE"}}>{data.fullName}</div>
                <div style={{cursor: "pointer", color: "#8494A0"}} id={"header-id-" + data.id + "-" + data.userId}>
                    <ExpandMoreIcon width={4.95} height={8.48} />
                </div>
                <UncontrolledPopover id={"uncontrolled-id-" + data.id} trigger="legacy" placement="bottom-end" target={`${"header-id-" + data.id + "-" + data.userId}`} style={{minWidth: _minWidth + "px"}}>
                    <PopoverBody>
                        <UserPopOverContainer
                        department={data.department}
                        division={data.division}
                        email={data.email}
                        fullName={data.fullName}
                        initilas={data.initials}
                        phoneNumber={data.phoneNumber}
                        profilePicSource={data.profilePicSource}
                        isUserDeleted={data.isUserDeleted}
                        isUserSuspended ={data.suspended}
                        />
                    </PopoverBody>
                </UncontrolledPopover>
            </>
        );
    }

    const _minWidth = data.fullName.length * 11; // Fix on the bug where text is floating over the popover body and not flexible to expand the container
    const TitleContainer = () => {
        return (
            <div key={"popover-id-" + data.id} id={"popover-header-id-" + data.id} style={{display: "flex", flexDirection: "row",  alignItems: "center", fontFamily: PrimaryFontFamily}}>
                { 
                    data.anonymous ? 
                    (   
                        (userRoles.length > 0 && userRoles.includes("SuperAdmin")) ? 
                            (<ReportedByContainer />) 
                            : 
                            (<AnonymousContainer />)
                    ) 
                    : 
                    ( <ReportedByContainer />)
                }
            </div>
        );
    }

    const PendingContainer = () => {
        return (<>
                    <div style={{marginRight: "15px"}}>
                        <Button variant="outlined" sx={{borderColor: "#D9DFE1"}} onClick={() => {handleReportPostUpdate(StatusReport.Ignored, data.id)}}>
                            <div style={{...ButtonFontStyle, color: "#576F80"}}>Ignore</div>
                        </Button>
                    </div>
                    <div>
                        <Button variant="contained" onClick={() => {handleReportPostUpdate(StatusReport.Removed, data.id)}}>
                            <div style={{...ButtonFontStyle, color: "#FFFFFF"}}>Remove Post</div>
                        </Button>
                    </div>
                </>);
    }

    const TextStatusContainer = (props) => {
        return (<div style={{fontFamily: PrimaryFontFamily,fontSize: 14,fontWeight: 500,color: props.color}}>
                {props.message}
                </div>);
    }

    const ActionContainer = () => {
        return (
            <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center"}}>
                <div style={{display: "flex", ...CenterAllStyle}}>
                {(() => {
                    switch(data.statusReportId) {
                        case StatusReport.Pending: return <PendingContainer />;
                        case StatusReport.Ignored: return <TextStatusContainer message={"Ignored"} color={"#576F80"} />;
                        case StatusReport.Resolved: return <TextStatusContainer message={"Resolved"} color={"#008EEE"} />;
                        case StatusReport.Removed: return <TextStatusContainer message={"Removed"} color={"#EC0000"} />;
                        default: return <></>;
                        }
                    }
                  )()
                }
                </div>
            </div>
        );
    }

    const SubHeaderContainer = () => {
        return (
            <div style={SubHeaderContainerStyle}>
                <div style={{display: "flex", width: "25px", alignItems: "flex-start",}}>
                   <WarningIcon width={20} height={20} />
                </div>
                <div style={{display: "flex", flex: 4, alignItems: "flex-start", }}>
                    <p style={{fontFamily: PrimaryFontFamily, fontWeight: 400, fontSize: "16px"}}>
                        {data.reportPostMessage}
                    </p>
                </div>
                <div style={{display: "flex", flex: 1, alignItems: "flex-start", justifyContent: "flex-end"}}>
                   <ActionContainer />
                </div>
            </div>
        );
    }

    return (
        <CardHeader title={ <TitleContainer />}
                    subheader={ <SubHeaderContainer /> }
                    style={{paddingTop: "10px"}}/>
    );
}

const ReportedPostMainContainerStyle = {
    sx: {
        boxShadow: "0px 4px 24px rgba(0, 31, 77, 0.1)",
        maxWidth: "99%",borderRadius: "14px"
    }
}

const IncidentPostInnerDetailContainer = (props) => {

    const _minWidth = props.data.fullName.length * 11; // Fix on the bug where text is floating over the popover body and not flexible to expand the container
    return ( 
        <CardContent sx={{backgroundColor: "#F7F8F8"}}>
           <Box sx={{  display: 'flex', flexDirection: 'row', paddingLeft: "10px" }}>
                <div style={{display: "flex", flex: 1,}}>
                    <InnerDetailImageContainer src={props.data.imageSource}  />
                </div>
                <div style={{display: "flex", flex: 3, flexDirection:"column"}}>
                    <div style={{flex: 2}}>
                        <InnerDetailInfoContainer data={props.data} /> 
                    </div>
                    <div style={{display: "flex", flex: 1, paddingLeft: "51px"}}>
                        <div style={{display: "flex", flexDirection: "column-reverse"}}>

                            <div style={{display: "flex", flexDirection: "row",  alignItems: "center", fontFamily: PrimaryFontFamily,}}>
                                <div style={{fontWeight: 500, fontSize: "16px", color: "#5F798C", marginRight: "5px"}}>
                                    {props.data.division + ":"}
                                </div>
                                <div style={{fontWeight: 500, fontSize: "16px", color: "#008EEE"}}>
                                    {props.data.fullName}
                                </div>
                                <div >
                                  &nbsp; {StatusBadge(props.data.isUserDeleted ? 3 : props.data.suspended ? 2 : 1)} &nbsp;
                                </div>
                                <div style={{cursor: "pointer", color: "#8494A0"}} id={"footer-owner-id-" + props.id}>
                                    <ExpandMoreIcon width={4.95} height={8.48} />
                                </div>
                                <UncontrolledPopover trigger="legacy" placement="bottom-end" target={`${"footer-owner-id-" + props.id}`} style={{minWidth: _minWidth + "px"}}>
                                    <PopoverBody>
                                    <UserPopOverContainer
                                        department={props.data.department}
                                        division={props.data.division}
                                        email={props.data.email}
                                        fullName={props.data.fullName}
                                        initilas={props.data.initials}
                                        phoneNumber={props.data.phoneNumber}
                                        profilePicSource={props.data.profilePicSource}
                                        isUserDeleted={props.data.isUserDeleted}
                                        isUserSuspended={props.data.suspended}
                                        />
                                    </PopoverBody>
                                </UncontrolledPopover>
                            </div>
                        
                        </div>
                    </div>
                </div>
            </Box>
        </CardContent>);
}

const InnerDetailImageContainer = (props) => {
    return (
    <>
    {
        props.src !== null ? 
        ( <CardMedia component="img" sx={{ width: "308px", height: "291px", borderRadius: "15px" }} image={props.src} alt="Incident"/>) 
        : 
        (<NoPreviewIcon />) 
    }
    </>
    );
}

const InnerDetailInfoContainer = (props) => {

    //Commented this temporarily just in case it's needed to include this action button
    // const ActionContainer = () => {
    //     return (
    //         <div>
    //             <IconButton aria-label="settings" id={"detail-incident-id-" + props.data.incidentId}><MoreVertIcon /></IconButton>
    //             <UncontrolledPopover trigger="legacy" placement="bottom" target={`${"detail-incident-id-" + props.data.incidentId}`}>
    //                 <PopoverBody>
    //                     <PopoverEditRemoveContainer data={props.data}/>
    //                 </PopoverBody>
    //             </UncontrolledPopover> 
    //         </div>
    //     );
    // }

    const TitleContainer = () => {
        return (
            <div className="common_font_style" style={{display: "flex", flexDirection: "column", justifyContent: "flex-start"}}>
                <div style={{fontWeight: 500, fontSize: "14px", color: "#008EEE"}}>{props.data.agencyName}</div>
                <div style={{fontWeight: 700, fontSize: "26px", color: "#002542"}}>Incident: {props.data.incidentNum}</div>
            </div>
        );
    }

    const SubHeaderContainerStyle = {
        alignItems: "center", 
        display: "flex", 
        flexDirection: "row", 
        fontFamily: PrimaryFontFamily,
        fontSize: "16px",
        fontWeight: 400, 
        paddingTop: "16px", 
    }

    const SubHeaderTextContainerStyle = {
        color: "#002542",
        padding: "3px 25px 0px 10px",
    }

    // const GenerateCreationTimeSpan = (data) => {
    //     return data.days > 0 ? data.days + (data.days === 1 ? " day ago" : " days ago") :
    //                 data.hours > 0 ? data.hours + (data.hours === 1 ? " hr ago" : " hrs ago") :
    //                     data.minutes > 0 ? data.minutes + (data.minutes === 1 ? " minute ago" : " minutes ago") :
    //                         data.seconds > 0 ? data.seconds + (data.seconds === 1 ? " second ago" : " seconds ago") : ""
    // }

    const SubHeaderContainer = () => {
        return (
            <div style={SubHeaderContainerStyle}>
                <ClockIcon width={25} height={25} />
                <div style={SubHeaderTextContainerStyle}>{props.data.date2}</div>

                <PinLocationIcon width={25} height={25} />
                <div style={SubHeaderTextContainerStyle}>{props.data.address1}</div>

                <FolderIcon width={25} height={25} />
                <div style={SubHeaderTextContainerStyle}>{props.data.incidentType}</div>
            </div>
        );
    }

    return (
        <CardContent sx={{ padding: "0px 0px 0px 35px", width: "100%" }}>
                <CardContent sx={{ flex: '1 0 auto' }}>
                    <CardHeader style={{padding: "0px 25px 0px 0px"}} 
                                // action={ <ActionContainer />} //Commented this temporarily just in case it's needed to include this action button
                                title={ <TitleContainer />} 
                                subheader={ <SubHeaderContainer /> }/>
                </CardContent>
                <CardContent>
                    <ReadMore>
                        {props.data.description}
                    </ReadMore>
                </CardContent>
            </CardContent>
    );
}

// Need to separate the Read More under InnerDetailInfoContainer due to bug found when collapsing the item description
const ReadMore = ({ children }) => {
    const text = children;
    const [isReadMore, setIsReadMore] = useState(true);
    const toggleReadMore = () => {
      setIsReadMore(!isReadMore);
    };
    return (
        <p className="text" style={{fontFamily: PrimaryFontFamily, fontWeight: 500, fontSize: "16px"}}>
            {isReadMore ? text.slice(0, 200) : text} {"  "}
            { text.length > 200 ? 
                ( <span onClick={toggleReadMore} className="read-or-hide" style={{color: "#002542", cursor: "pointer", fontSize: "16px", fontWeight: 400, textDecoration: "underline",fontFamily: PrimaryFontFamily}}>
                    {isReadMore ? "Read More..." : "Show Less..."}
                </span>) 
                : (<></>)
            }
        </p>
    );
  };

//[End]

export const CenterAllStyle = {
    alignItems: "center", 
    justifyContent: "center", 
    alignContent: "center",
}

export const ButtonFontStyle ={
    fontfamily: PrimaryFontFamily,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
}

export const NoResultFoundContainer = (props) => {
    return (
        <div style={{display: "flex", flexDirection: "column", width: "100%", alignItems: "center", padding: "15px"}}>
            <div style={{paddingLeft: "20px"}}>
                <NoResultFoundImage/>
            </div>
            <div style={{fontFamily: PrimaryFontFamily, fontWeight: 400, fontSize: "16px", paddingTop: "15px"}}>
                {props.message ? (<>{props.message}</>) : ("No results found.")}
            </div>
        </div>
    );
}

export const PostShimmeringEffect = () => {

    const TitleContainer = () => {
        return (
            <div  style={{display: "flex", flexDirection: "row",  alignItems: "center"}}>
                 <div >
                    <Skeleton variant="rounded" width={100} height={15} />
                </div>
            </div>
        );
    }

    const SubHeaderContainer = () => {
        return (
            <div style={SubHeaderContainerStyle}>
                <div style={{display: "flex", width: "25px", alignItems: "flex-start",}}>
                    <Skeleton variant="rounded" width={20} height={20} />
                </div>
                <div style={{display: "flex", flex: 4, alignItems: "flex-start"}}>
                    <Skeleton variant="rounded" width={300} height={15} />
                </div>
                <div style={{display: "flex", flex: 1, alignItems: "flex-start", justifyContent: "flex-end"}}>
                    <Skeleton variant="rounded" width={200} height={25} />
                </div>
            </div>
        );
    }

    const TitleInnerContainer = () => {
        return (
            <div style={{display: "flex", flexDirection: "column", justifyContent: "flex-start"}}>
                <Skeleton variant="rounded" width={150} height={15} />
                <div style={{marginTop: "5px"}}><Skeleton variant="rounded" width={350} height={35} /></div>
            </div>
        );
    }

    const SubHeaderInnerTextContainerStyle = {
        color: "#002542",
        padding: "3px 25px 0px 10px",
    }

    const SubHeaderInnerContainer = () => {
        return (
            <div style={SubHeaderContainerStyle}>
                <div style={{marginTop: "3px"}}><Skeleton variant="rounded" width={25} height={25} /></div>
                <div style={SubHeaderInnerTextContainerStyle}><Skeleton variant="rounded" width={100} height={25} /></div>

                <div style={{marginTop: "3px"}}><Skeleton variant="rounded" width={25} height={25} /></div>
                <div style={SubHeaderInnerTextContainerStyle}><Skeleton variant="rounded" width={100} height={25} /></div>

                <div style={{marginTop: "3px"}}><Skeleton variant="rounded" width={25} height={25} /></div>
                <div style={SubHeaderInnerTextContainerStyle}><Skeleton variant="rounded" width={100} height={25} /></div>
            </div>
        );
    }

    return (<Card {...ReportedPostMainContainerStyle}>
               <CardHeader title={ <TitleContainer />}
                           subheader={ <SubHeaderContainer /> }
                />
                <CardContent sx={{backgroundColor: "#F7F8F8"}}>
                    <Box sx={{  display: 'flex', flexDirection: 'row', paddingLeft: "10px" }}>
                        <div style={{display: "flex", flex: 1,}}>
                            <Skeleton variant="rounded" width={308} height={291} />
                        </div>
                        <div style={{display: "flex", flex: 3, flexDirection:"column"}}>
                            <div style={{flex: 2}}>
                                <CardContent sx={{ padding: "0px 0px 0px 35px", width: "100%" }}>
                                    <CardContent sx={{ flex: '1 0 auto' }}>
                                        <CardHeader style={{padding: "0px 25px 0px 0px"}} 
                                                    title={ <TitleInnerContainer />} 
                                                    subheader={ <SubHeaderInnerContainer /> }/>
                                    </CardContent>
                                    <CardContent>
                                        <Skeleton variant="rounded" width={"100%"} height={100} />
                                    </CardContent>
                                </CardContent>
                            </div>
                            <div style={{display: "flex", flex: 1, paddingLeft: "51px"}}>
                                <div style={{display: "flex", flexDirection: "column-reverse"}}>
                                    <div style={{display: "flex", flexDirection: "row",  alignItems: "center"}}>
                                        <div style={{marginRight: "5px"}}>
                                            <Skeleton variant="rounded" width={100} height={15} />
                                        </div>
                                        <div style={{marginRight: "5px"}}>
                                            <Skeleton variant="rounded" width={100} height={15} />
                                        </div>
                                      
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </Box>
                </CardContent>
            </Card>);
}