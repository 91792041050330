import React from "react";
import { Row, Col, Button } from "reactstrap";
import anyincident from "../../../assets/any_incident.png";
import "./State.css";

const AnyPost = (props) => {

    return (
        <>
            <Row>
                <img src={anyincident} className="anypostimg" />
            </Row>
            <Row>
                <label className="lblnochats">There are no posts here</label>
            </Row>

            {!props.isSuperAdmin && 
            (
            <>
                <Row>
                    <label className="lblsecnochats">You can create your first post and join other agencies to see what they post</label>
                </Row>
                <Row>
                    <Col md="5">
                    </Col>
                    <button className="btn btn-primary btncreatechat">+ Create Post</button>
                </Row>
            </>
            )
            }
            
        </>
    );
};
export default AnyPost;
