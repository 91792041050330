import axios, {
    AxiosError,
    AxiosInstance,
    AxiosRequestConfig,
    AxiosResponse,
} from "axios";


const API_URL = process.env.NEXT_PUBLIC_ENDPOINT_AUTH;

const onRequest = (config: AxiosRequestConfig): AxiosRequestConfig => {
    const token = JSON.parse(localStorage.getItem("user"));
    config.headers["Authorization"] = `Bearer ${token.token}`;

    return config;
};

const onRequestError = (error: AxiosError): Promise<AxiosError> => {
    return Promise.reject(error);
};

const onResponse = (response: AxiosResponse): AxiosResponse => {
    return response;
};

const onResponseError = async (error: AxiosError): Promise<AxiosError> => {
    if (error.response) {
        // Access Token was expired
        if (
            error.response.status === 401 
        ) {
            const originalRequest = error.config;
            const storedToken = JSON.parse(localStorage.getItem("user"));

            try {
                const rs = await axios.post(`/authenticate/refresh`, {
                    RefreshToken: storedToken.refreshToken,
                    AccessToken: storedToken.token
                });

                localStorage.setItem("user", JSON.stringify(rs.data));
                originalRequest.headers["Authorization"] = `Bearer ${rs.data.token}`;
                return axios(originalRequest);
                
            } catch (_error) {
                return Promise.reject(_error);
            }
        }
    }
    return Promise.reject(error);
};

export const setupInterceptorsTo = (
    axiosInstance: AxiosInstance
): AxiosInstance => {
    axiosInstance.interceptors.request.use(onRequest, onRequestError);
    axiosInstance.interceptors.response.use(onResponse, onResponseError);
    return axiosInstance;
};