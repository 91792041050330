/*eslint unicode-bom: ["error", "always"]*/
import React, { useState, useEffect } from "react";
import Table from "../../components/Common/table";
import Axios from "axios";
import { Link } from 'react-router-dom';
import Pagination from "../../components/Common/pagination";
import { Button, Input, Modal, NavItem, NavLink, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core';
import './Agency.css';
import AuthService from "../../services/auth.service";
import { useHistory } from 'react-router-dom';
import { Col, ModalBody, ModalFooter, ModalHeader } from "react-bootstrap";
import ModalAlert from "../Common/ModalAlert";
import api from "../../services/api";

library.add(faEdit, faTrash);
const Agency = () => {
    let history = useHistory();
    // Modal open state
    const [modal, setModal] = React.useState(false);
    const [modalDelete, setModalDelete] = React.useState({
        show: false,
        id: "",
        name:""
    });
    const [modalChief, setmodalChief] = useState(false);
    const [modalInvitation, setmodalmodalInvitation] = useState(false);
    const [usersAvailables, setUsersAvailables] = useState([]);
    const [filter, setFilter] = useState("");
    const [sendInvitation, setSendInvitation] = useState(false);
    const [agencyId, setAgencyId] = useState(false);
    // Toggle for Modal
    const toggle = (agency) => {
        setModal(!modal);
        setAgencyId(agency);
    }
    const DeleteAgency = (id) => {

        const formDatadelete = new FormData();

        formDatadelete.append("id", id);

        api.post("/agency/delete", formDatadelete, AuthService.getToken())
            .then(response => {

                if (response.data) {
                    toggleDeleteModal(false);
                    getData(1).then((info) => {
                        const { totalPages, data, totalData } = info;
                        setPageData({
                            isLoading: false,
                            rowData: formatRowData(data),
                            totalPages,
                            totalData,
                        });
                    });
                }


            });
    }
    const toggleDelete = (agency) => {
        setModalDelete({
            ...modalDelete,
            show: !modalDelete.show,
            id: agency.id,
            name:agency.name
        });
       
    }
    const toggleDeleteModal = (shomwod) => {
        setModalDelete({
            ...modalDelete,
            show: shomwod,
            id: "",
            name: ""
        });

    }


    const formatRowData = (rawData) => {
        var mapdata = rawData.map((info) => ({
            name: info.name,
            state: info.address.state,
            joined: "-",
            id:info.id
        }));
        return mapdata;
    }

    const toggleInvitation = () => {
        setmodalmodalInvitation(false);
        setFilter("");
        setSendInvitation(false);
    };


    const toggleAddedShief = () => {
        setmodalChief(false);
        setFilter("");
        setSendInvitation(false);

    };

    const getUsers = async () => {
        const responseUser = await api.get(
            `/agency/users?filter=${filter}&agencyId=${agencyId}`
        );
        setUsersAvailables(responseUser.data);

        //enables user invitation
        const regexemail = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        if (usersAvailables !== undefined && usersAvailables.length === 0 && regexemail.test(filter) === true) {
            setSendInvitation(true);
        }
    }

    const onFilterChanged = (e) => {
        setFilter(e.target.value);
        getUsers();
    }

    const makeAsChief = async (userId) => {
        var addChiefViewModel = {
            Agency: agencyId,
            UserId: userId
        };
        const responseUser = await api.post(
            `/agency/addChief`, addChiefViewModel
        ).then(() => {
            setModal(false);
            setmodalChief(true);
        });
    }

    const sendUserInvitation = async () => {
        var addChiefViewModel = {
            Email: filter,
            Agency: agencyId,
            UserId: filter
        };
        const responseUser = await api.post(
            `/authenticate/sendInvitation`, addChiefViewModel
        ).then(() => {
            setModal(false);
            setmodalmodalInvitation(true);
        });
    }


    const getData = async (pageNo = 1) => {
        const response = await api.get(`/agency/get?page=${pageNo}&take=15`, AuthService.getToken());
        return response.data;

    };
    const columns = [
        {
            Header: "Agency Name",
            accessor: "name",
        },
        {
            Header: "State",
            accessor: "state",
        },
        {
            Header: "Joined",
            accessor: "joined",
        }
    ];
    const [pageData, setPageData] = useState({
        rowData: [],
        isLoading: false,
        totalPages: 0,
        totalData: 0,
    });
    const [currentPage, setCurrentPage] = useState(1);
    useEffect(() => {
        setPageData((prevState) => ({
            ...prevState,
            rowData: [],
            isLoading: true,
        }));
        getData(currentPage).then((info) => {
            const { totalPages, data, totalData } = info;
            setPageData({
                isLoading: false,
                rowData: formatRowData(data),
                totalPages,
                totalData,
            });
        });
    }, [currentPage]);
    return (
        <>
            <div>
                <p>Total: {pageData.totalData || "Loading..."}</p>
                <div>
                    <Table
                        columns={columns}
                        data={pageData.rowData}
                        isLoading={pageData.isLoading}
                    />
                </div>
                <Pagination
                    totalRows={pageData.totalData}
                    pageChangeHandler={setCurrentPage}
                    rowsPerPage={15}
                />
            </div>
           
          
        </>
    );
}
export default Agency;