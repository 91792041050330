import { faUserGroup, faBookmark, faShareSquare, faBell, faTrashCan, faTriangleExclamation, faPencil, faBrain } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row, Col } from 'reactstrap';

import { library } from '@fortawesome/fontawesome-svg-core';
import { Link } from 'react-router-dom';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import AuthService from "../../services/auth.service";
import { useState, useEffect } from 'react';
import api from "../../services/api";
library.add(faUserGroup, faBookmark, faShareSquare, faBell, faTrashCan, faTriangleExclamation, faPencil, faBrain)
const OptionsPostSaved = (props) => {
    const { data,reloadData } = props;
    const [profile, SetProfile] = useState({});
    const [loadingunsaved, setloadingunsaved] = useState(false);

    const UnSavePost = (data) => {
       setloadingunsaved(true);
        if (!loadingunsaved) {
            api.post("/incidents/unsavedpost", { Id: data.id }, AuthService.getToken())
                .then(response => {
                    console.log(response);
                    if (response.data)
                        NotificationManager.success('Success', 'POST UN-SAVED', 5000);
                    reloadData();
                    setTimeout(setloadingunsaved(false), 1000);

                });


                }
        }
    

    return (

        <>
            <Row className="option">
                <Col md="3">
                    <FontAwesomeIcon className="svgIconGrey" icon={faBrain} size="xl" />
                </Col>
                <Col md="7">
                    Relate Crime &#62;
                </Col>


            </Row>
            <Row className="option">
                <Col md="3">
                    <FontAwesomeIcon className="iconPopup" icon={faBell} size="xl" />
                </Col>
                <Col md="7">
                    Get Notified &#62;
                </Col>

            </Row>
            <Row disabled={loadingunsaved} className="option" onClick={() => UnSavePost(data)}>
                <Col md="3">
                    <FontAwesomeIcon icon={faBookmark} size="xl" />
                </Col>
                <Col md="7">
                    Un-Save Post &#62;
                    {loadingunsaved && (
                        <span className="spinner-border spinner-border-sm"></span>
                    )}
                </Col>

            </Row>
            <Row className="option">
                <Col md="3">
                    <FontAwesomeIcon icon={faShareSquare} size="xl" />
                </Col>
                <Col md="7">
                    Share Post with fellow user &#62;
                </Col>

            </Row>
            <Row className="option">
                <Col md="3">
                    <FontAwesomeIcon icon={faTrashCan} size="xl" />
                </Col>
                <Col md="7">
                    Delete Post &#62;
                </Col>

            </Row>
            <Row className="option">
                <Col md="3">
                    <FontAwesomeIcon icon={faTriangleExclamation} size="xl" />
                </Col>
                <Col md="7">
                    Report Post &#62;
                </Col>

            </Row>
            <Row className="option">
                <Col md="3">
                    <FontAwesomeIcon icon={faPencil} size="xl" />
                </Col>
                <Col md="7">
                    Edit Post &#62;
                </Col>

            </Row>
          
        </>
               
    );
}
export default OptionsPostSaved;