import React, { useState,useEffect } from 'react';
import { styled } from '@mui/material/styles';
import belllogo from "../../assets/bell.png"
import {  NavItem, NavLink, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Link, useHistory } from 'react-router-dom';
import './UserBar.css';
import Badge from '@mui/material/Badge';
import Avatar from 'react-avatar';
import AuthService from "../../services/auth.service";
import api from "../../services/api";
import InfiniteScroll from 'react-infinite-scroller';
import signalRserive from '../../services/signalRserive';

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        backgroundColor: '#44b700',
        color: '#44b700',
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        '&::after': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            animation: 'ripple 1.2s infinite ease-in-out',
            border: '1px solid currentColor',
            content: '""',
        },
    },
    '@keyframes ripple': {
        '0%': {
            transform: 'scale(.8)',
            opacity: 1,
        },
        '100%': {
            transform: 'scale(2.4)',
            opacity: 0,
        },
    },
}));
const UserBar = () => {
    const scrollStyle = {
        height: '10rem',
        overflow: 'auto'
    }

    // eslint-disable-next-line no-unused-vars
    const [user, setUser] = useState(AuthService.getCurrentUser());

    let history = useHistory();
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [dropdownOpenNotif, setDropdownOpenNotif] = useState(false);

    const [items, setItems] = useState(Array.from({ length: 0 }));
    const [hasMore, setHasMore] = useState(true);
    const [countnot, setCountNot] = useState();

    const [totalN, setTotalN] = useState(0);



    const toggle = () => {
        setDropdownOpen(!dropdownOpen);
    }
    const toggleNotif = () => {
        setDropdownOpenNotif(!dropdownOpenNotif);
    }
    const logout = () => {
        history.push("/login");
        history.go(0);
        AuthService.logout();
    }
    const getCountNotif = () => {
        api.get(
            "/notification/countnotif")
            .then((response) => {
                setCountNot(response.data);
            });
    }
    const getAllCountNotif = () => {
        api.get(
            "/notification/allnotif")
            .then((response) => {
                setTotalN(response.data);
            });
    }
    const RedirectNot = (item) => {
        if (item.generalNotificationType === 0) {
            history.push("/home");
        }
        if (item.generalNotificationType === 1) {
            history.push("/requests");
        }
        if (item.generalNotificationType === 2) {
            history.push("/messages");
        }
        if (item.generalNotificationType === 3) {
            history.push("/post/view/" + item.incidentId);
        }
        if (item.generalNotificationType === 4) {
            history.push("/reportedpost");
        }
        if (item.generalNotificationType === 5) {
            history.push("/agencies");
        }
        if (item.generalNotificationType === 6) {
            history.push("/agencies");
        } 
    }
    const ChangeStateNot = (item) => {
        let itemsx = [...items];
        let index = itemsx.findIndex(obj => {
            return obj.id === item.id;
        });
        let itemx = { ...itemsx[index] };

        itemx.notificationState = 1;

        itemsx[index] = itemx;
        itemsx.splice(index, 1); // remove read notif

        setItems(itemsx);
    }
    const ReadNotification = (item) => {
        const formData = new FormData();

        formData.append("id", item.id);
        if (item.notificationState === 0) {

            api.post("/notification/read", formData, AuthService.getToken())
                .then(response => {

                    ChangeStateNot(item);

            RedirectNot(item);
                    getCountNotif();
                });
        } else {
            RedirectNot(item);
            getCountNotif();
        }
    }
    const fetchMoreData = () => {
        if (items.length >= totalN) {
            setHasMore(false);
            return;
        }
        let skipl = items.length;

        api.get("notification/notifications?take=5&skip=" + skipl).then((response) => {

            var newitems = items.concat(response.data);
            console.log(newitems);
            setItems(
                newitems
            );
        });
    };

    useEffect(() => {
        getCountNotif();
        getAllCountNotif();
      
    }, [countnot, totalN]);
    useEffect(() => {
        api.get("notification/notifications?take=5&skip=" + 0).then((response) => {

            var newitems = items.concat(response.data);
            setItems(
                newitems
            );
        });

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
   

    let isLoggedIn = AuthService.islogged();

    //UseEffect to listen general notifications
    useEffect(() => {
        let connection = signalRserive.getHubConnection();
        if (connection !== undefined && connection !== null) {

            connection.off('GeneralNotification');
            connection.on('GeneralNotification', () => {
                getCountNotif();
               
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [signalRserive.getHubConnection()]);

    return (
        <div>
            {isLoggedIn ? (
                <div className="row">
                    <div className="col-5"></div>
                   
                    <div className="col-2">
                    <Dropdown isOpen={dropdownOpenNotif} toggle={toggleNotif} className="dropdownprofile">
                            <DropdownToggle caret className="droplog">
                                {countnot !== 0 &&
                                    <label className="lblnumber">{countnot}</label>
                                }
                            <img alt="" className="bellnot" src={belllogo} />
                        </DropdownToggle>
                            <DropdownMenu className="menudropdownnotif" >
                                <div style={scrollStyle}>
                                    <InfiniteScroll
                                        pageStart={0}
                                        loadMore={fetchMoreData}
                                        hasMore={hasMore}
                                        loader={<div className="loader" key={0}>Loading ...</div>}
                                        useWindow={false}
                                    >
                                        {(items.length === 0 && hasMore === false) &&
                                            <label className="no-notify">You have not received any notification yet</label>
                                        }
                                        {items.map((i, index) => {
                                            if (i.generalNotificationType === 0) {
                                                return <DropdownItem onClick={()=>ReadNotification(i)} key={index}  className={`dropdownmenunot ${i.notificationState === 1 ? "readed" : ""}`}><label style={{cursor: "pointer"}}>New Incident</label></DropdownItem>;
                                            }
                                            if (i.generalNotificationType === 1) {
                                                return <DropdownItem onClick={() => ReadNotification(i)} key={index}  className={`dropdownmenunot ${i.notificationState === 1 ? "readed" : ""}`} ><label style={{cursor: "pointer"}}>New Request</label></DropdownItem>;
                                            }
                                            if (i.generalNotificationType === 2) {
                                                return <DropdownItem onClick={() => ReadNotification(i)} key={index}  className={`dropdownmenunot ${i.notificationState === 1 ? "readed" : ""}`} ><label style={{cursor: "pointer"}}>New Message</label></DropdownItem>;
                                            }
                                            if (i.generalNotificationType === 3) {
                                                return <DropdownItem onClick={() => ReadNotification(i)} key={index}  className={`dropdownmenunot ${i.notificationState === 1 ? "readed" : ""}`} ><label style={{cursor: "pointer"}}>Incident Updated!</label></DropdownItem>;
                                            }
                                            if (i.generalNotificationType === 4) {
                                                return <DropdownItem onClick={() => ReadNotification(i)} key={index}  className={`dropdownmenunot ${i.notificationState === 1 ? "readed" : ""}`}><label style={{cursor: "pointer"}}>New Incident Reported!</label></DropdownItem>;
                                            }
                                            if (i.generalNotificationType === 5) {
                                                return <DropdownItem onClick={() => ReadNotification(i)} key={index} className={`dropdownmenunot ${i.notificationState === 1 ? "readed" : ""}`}><label style={{cursor: "pointer"}}>Your Request Agency was Accepted!</label></DropdownItem>;
                                            }
                                            if (i.generalNotificationType === 6) {
                                                return <DropdownItem onClick={() => ReadNotification(i)} key={index} className={`dropdownmenunot ${i.notificationState === 1 ? "readed" : ""}`}><label style={{cursor: "pointer"}}>Your Request Agency was Denied!</label></DropdownItem>;
                                            }
                                            
                                        })}
                          
                                    </InfiniteScroll>
                                </div>

                        </DropdownMenu>
                        </Dropdown>
                    </div>
                    <div className="col-3 namenavbar nomgbottom"> <label className="namelbl"> {user.name}</label></div>
                    <div className="col-2 nomgbottom">
                <ul className="nomgbottom">

                    <NavItem className='logoutli spanhand col-6'>
                                <Dropdown isOpen={dropdownOpen} toggle={toggle} className="dropdownprofile">
                                    <DropdownToggle caret className="droplog">
                                
                               
                                    {user.picture === "" &&
                                    <StyledBadge
                                    overlap="circular"
                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                    variant="dot"
                                    >
                                    <Avatar name={user.name}   size="50" round  />
                                    </StyledBadge>
                                  
                                }
                                {user.picture !== "" &&
                                    <StyledBadge
                                    overlap="circular"
                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                    variant="dot"
                                    >
                                    <Avatar src={user.picture} size="50" round  />
                                    </StyledBadge>
                                    
                                }
                               
                                    </DropdownToggle>
                                    <DropdownMenu className="menudropdown">
                           
                                <DropdownItem tag={Link} className="text-dark" to="/Account">My Profile</DropdownItem>
                                <DropdownItem onClick={logout}>Log Out</DropdownItem>
                               
                            </DropdownMenu>
                        </Dropdown>
                    </NavItem>

                </ul>
                    </div>
                </div>
            ) : (
                <ul className="navbar-nav flex-grow">
                    <NavItem className='noborder'>
                        <NavLink tag={Link} className="text-dark" to="/login">Login</NavLink>
                    </NavItem>
                </ul>
            )}


        </div>
    );
}
export default UserBar;