import React,{ useState} from "react";
import { Link } from 'react-router-dom';
import authService from "../../services/auth.service";

const CardPostAgenciesList = (props) => {

    // eslint-disable-next-line no-unused-vars
    const [isSuper, setIsSuper] = useState(authService.isSuperAdmin() || authService.isAdmin());
    // eslint-disable-next-line no-unused-vars
    const [isAdminOnly, setIsAdminOnly] = useState(authService.isAdmin() && !authService.isSuperAdmin())
    // eslint-disable-next-line no-unused-vars
    const [isOfficer, setIsOfficer] = useState(!authService.isAdmin() && !authService.isSuperAdmin())

    return (
    <>
        <h2>Agencies</h2><hr></hr>
        {props.listagencies.map((agency) => (
            <div className="row cardagency">
                <div className="col-md-6">
                    {(agency !== undefined && agency !== null ? agency.name : "")}
                </div>
                <div className="col-md-6">
                    {
                        (isOfficer ? <Link to={"/agency/view?id=" + agency.id +"&modeid=1"} className="">Go to Agency Profile </Link>: <Link to={"/agency/create?agency=" + agency.id} className="">Go to Agency Profile </Link>)
                    }
                    
                </div>
            </div>
        ))}
    </>);

}


export default CardPostAgenciesList;