/*eslint unicode-bom: ["error", "always"]*/
import React, { useCallback, useState, useEffect } from "react";
import Table from "../../components/Common/table";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShareSquare } from '@fortawesome/free-solid-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core';
import Pagination from "../../components/Common/pagination";
import { faEdit, faTrash, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import './GridOfficer.css';
import AuthService from "../../services/auth.service";
import { useHistory } from 'react-router-dom';
import authService from "../../services/auth.service";
import api from "../../services/api";
import ModalProfileCard from '../Profile/ModalProfileCard';
import { ModalBody, ModalFooter, ModalHeader } from "react-bootstrap";
import { Button, Modal, Row, Col } from 'reactstrap';
import Avatar from 'react-avatar';


library.add(faEdit, faTrash, faMagnifyingGlass, faShareSquare);
const GridOfficerSharePost = (props) => {
    const { SelectUser } = props;
    const [division, setDivision] = useState("");
    const [agencyId, setAgencyId] = useState(props.AgencyId !== undefined ? props.AgencyId : '');
    const [modalInformation, setModalInformation] = useState(false);
    const [selecteduser, setSelectedUser] = useState({});
    const [search, setSearch] = useState("");
    const [list, setList] = useState([]);
    const [listdepartaments, setListDepartaments] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);

    const [isExecuteSearchPost, setIsExecuteSearchPost] = useState(false);
    const [isTextSearchExecuted, setIsTextSearchExecuted] = useState(false);


    const ChangeSearch = (e) => {
        setSearch(e.target.value);
    }
    const toggleModal = () => {
        setModalInformation(!modalInformation);
    };

    const Search = (e) => {
        setSearch(e.target.value);
        setIsTextSearchExecuted(true);
        setIsExecuteSearchPost(false);

    }

    const SearchPost = useCallback(() => {
        getData(currentPage, search).then((info) => {
            const { totalPages, data, totalData } = info;
            setPageData({
                isLoading: false,
                rowData: data,
                totalPages,
                totalData,
            });
        });
    },[search,currentPage])

    const setUser = (x) => {
        setSelectedUser({
            ...selecteduser,
            division: x.division,
            name: x.name,
            status: x.status,
            email: x.email,
            phone: x.phone,
            id: x.id,
            showEmail: x.showEmail,
            showPhone: x.showPhone,
            urlPic: x.urlPic
        });
    };

    const getData = async (pageNo = 1, search = "") => {
        const response = await api.get(
            `/officer/account/get?page=${pageNo}&take=5&departament=${agencyId}&division=${division}&search=${search}`
            , AuthService.getToken());
        return response.data;

    };

    const columns = [
        {
            Header: "Recents",
            accessor: "name",
            Cell: cellInfo => <>
                <Row>
                    <Col md="2">   <Avatar className="avatarsharepost" src={cellInfo.data[cellInfo.row.index].urlPic} name={cellInfo.data[cellInfo.row.index].name} round size="40" /></Col>
                    <Col md="8"><label className="mgt3 lblnameofficer" > {cellInfo.data[cellInfo.row.index].name}</label> </Col>
                    <Col md="2" onClick={(event) => SelectUser(cellInfo.data[cellInfo.row.index])}>    <FontAwesomeIcon className="mgt25" icon={faShareSquare} size="xl" /> </Col>
                </Row>
                    </>
             
        }
    ];

    const [pageData, setPageData] = useState({
        rowData: [],
        isLoading: false,
        totalPages: 0,
        totalData: 0,
    });

    
    useEffect(() => {
        setPageData((prevState) => ({
            ...prevState,
            rowData: [],
            isLoading: true,
        }));
        getData(currentPage).then((info) => {
            const { totalPages, data, totalData } = info;
            setPageData({
                isLoading: false,
                rowData: data,
                totalPages,
                totalData,
            });
        });
    }, [currentPage]);

    //Set some delay when typing
    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            setIsExecuteSearchPost(false);
            setIsExecuteSearchPost(true);
        }, 1500)

        return () => clearTimeout(delayDebounceFn)
    }, [search])

    useEffect(() => {
        if(isExecuteSearchPost === true && isTextSearchExecuted === true) {
            SearchPost();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[isExecuteSearchPost,isTextSearchExecuted])

    return (
        <>
            <div>
                <br />
                <div className="row">

                    <div className="col-12">
                        <div className="input-group mb-12">
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="basic-addon1">
                                    <FontAwesomeIcon className="svgIconGrey" icon={faMagnifyingGlass} />
                                </span>
                            </div>
                            <input type="text" className="form-control" placeholder="Keywords search..." onChange={Search} value={search} />
                        </div>
                        
                       
                    </div>



                </div>
          

                <div>

                    <Table
                        columns={columns}
                        data={pageData.rowData}
                        isLoading={pageData.isLoading}
                    />
                    {pageData.totalData == 0 &&

                        <h2 className="noitems">
                            NO DATA.
                        </h2>
                    }
                </div>
                <Pagination
                    totalRows={pageData.totalData}
                    pageChangeHandler={setCurrentPage}
                    rowsPerPage={5}
                />
            </div>
 

        </>
    );
}
export default GridOfficerSharePost;