import React, { useState, useEffect, useRef, useMemo } from 'react';
import Chat from './Chat';
import { styled } from '@mui/material/styles';
import ChatWindow from './ChatWindow';
import { Row, Col, Form } from 'reactstrap';
import "./ChatPanel.css";
import Avatar from 'react-avatar';
import Badge from '@mui/material/Badge';
import MessageService from  "../../services/MessageService";
import ChatList from './ChatList';
import calendar from "../../assets/calendar_full.png";
import locationpng from "../../assets/location_full.png";
import nopreview from "../../assets/nopreviewv3.png";
import type from "../../assets/type_full.png";
import { LoaderUserChat, LoaderChatPanel, AnyMessageState } from "../Chat/StatePage";
import addresspng from "../../assets/office-on.png";
import { Link, useHistory } from 'react-router-dom';
import NewChatModal from './NewChatModal';
import signalRserive from '../../services/signalRserive';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faArrowLeft} from '@fortawesome/free-solid-svg-icons';
import StatusBadge from '../Officer/StatusBadge';
import phoneIcon from "../../assets/Phone.svg";
import emailIcon from "../../assets/Email.svg";

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        backgroundColor: '#44b700',
        color: '#44b700',
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        '&::after': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            animation: 'ripple 1.2s infinite ease-in-out',
            border: '1px solid currentColor',
            content: '""',
        },
    },
    '@keyframes ripple': {
        '0%': {
            transform: 'scale(.8)',
            opacity: 1,
        },
        '100%': {
            transform: 'scale(2.4)',
            opacity: 0,
        },
    },
}));
const ChatPanel = (props) => {
    let history = useHistory();
    //New Logic 
    const { ChatMessageItem } = props;
    const [ChatMessageItemId, setChatMessageItemId] = useState(0);
    const [userschat, setUsersChats] = useState([]);
    const [newchatselected, newsetChatSelected] = useState({});
    const [boardchatselected, setBoardChat] = useState({});
    const [userschatincident, setUsersChatIncident] = useState([]);
    const [newMessageKey, setNewMessageKey] = useState(0);
    if (ChatMessageItem !== undefined && ChatMessageItem.Chat_Id !== ChatMessageItemId) {
        setChatMessageItemId(0);
        ChatMessageItem.Chat_Id = 0;
    }
    //End logic
    const [isLoadingChatPanel, setIsLoadingChatPanel] = useState(true);
    // eslint-disable-next-line no-unused-vars
    const [currentChatId, setcurrentChatId] = useState();
    const chatSelected = useRef(() => undefined);
    const [chatList, setChatList] = useState({ chats: [] });
    const [filter, setFilter] = useState("");
    const [isloadinguserchat, setIsLoadingUserChat] = useState(true);

    const [currentChat, setCurrentChat] = useState(props.location === undefined ? {} : props.location.state);
    // eslint-disable-next-line no-unused-vars
    const [disableSend, setEnableSent] = useState(props.ChatMessageItem === undefined ? true : false);
    // eslint-disable-next-line no-unused-vars
    const [currentSend, setcurrentSend] = useState(props.location === undefined);
    const [showNewChat, setshowNewChat] = useState(false);
    const [isSelectNewChatPreview, setIsSelectNewChatPreview] = useState(false);

    const SelectNewChatPreview = (item) => {
        if (item && item.id >0) {
            setIsLoadingChatPanel(true);
            
            if (item.typechat === "user") {
                newsetChatSelected(item);
                selectChat(item);

            }
            setIsSelectNewChatPreview(true);
            setBoardChat(item);
            setCurrentChat(undefined);
            if (item.typechat === "incident") {
                setChatList({ chats: [] });
                GetUsersIncident(item.incident);

            }
        }
       
        setIsLoadingChatPanel(false);
    }

    const SelectNewChatPreviewIncident = (item) => {
        setChatList({ chats: [] });
        setIsLoadingChatPanel(true);
        if (item.typechat === "user") {
            var newitem = item;
            newsetChatSelected(newitem);
            selectChat(newitem);
            
        }
        setIsLoadingChatPanel(false);
       
    }

    const GetUsersIncident = (id) => {

        getChats(id);

        
    }

    chatSelected.current = currentChatId;
    useEffect(() => {
        getChats();
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setUsersChats])


    const sendMessage = async (user, message) => {

        var chatId = ChatMessageItemId;
        setcurrentSend(chatId === undefined);
        var ramdonVal = Math.trunc(Math.random() * (10000 - 1 + 1));
        
        const chatMessage = {
            Message: message,
            To: newchatselected.to === undefined ? [] : newchatselected.to,
            From_Id: "",
            Subject: "",
            IncidentNumber: "",
            Chat_Id: newchatselected.id,
            IncidentId: parseInt(newchatselected.incidentId),
            NewMessageKey: (newchatselected.id === undefined || newchatselected.id === null || newchatselected.id === 0 ? ramdonVal :0),
            isSharePost: newchatselected.isSharePost === undefined ? false : newchatselected.isSharePost,
        };

        setNewMessageKey(ramdonVal);

        try {

            MessageService.sendMessage(chatMessage).then((result) =>
            {
                getChats(); //DJTR :: temp, check effect
            }, error => {

            });
        }
        catch (e) {
            console.log('Sending message failed.', e);
        }
    }


    const getChats = async (incident, itemtoselect) => {
        let loc = history.location === undefined ? undefined : history.location.state;

        const queryParams = new URLSearchParams(history.location.search);
        const chatuserId = queryParams.get('userId');
        const incidentChat = queryParams.get('incident');
        const chatMode = queryParams.get('mode');
        const incidentNum = loc !== undefined ? loc.incidentNum : "";

        const userFullName = loc !== undefined ? loc.userFullName : "-" ;
        const userEmail = loc !== undefined ? loc.email : "-" ;
        const userPhone = loc !== undefined ? loc.phone: "-" ;
        const userAddress = loc !== undefined ? loc.userAddress: "-" ;
        const isSharePost = loc !== undefined ? loc.isSharePost : false ;

        if ((incident === undefined || incident === null || incident === 0) ) {
            MessageService.getChats(filter).then((data) => {
                let itemToSelect = {};

                if (itemtoselect !== undefined && itemtoselect.name !== null) {
                    itemToSelect = data.chats.filter((item) => {
                        return item.id === itemtoselect.id;
                    });

                    if (itemToSelect.length > 0) {
                        newsetChatSelected(itemToSelect[0]);
                    }

                }

                else if (incidentChat !== undefined && incidentChat !== null) {
                    if(chatMode !== undefined || chatMode !== null) {
                        if(chatMode === "share") {
                            itemToSelect = data.chats.filter((item) => {
                                return item.incident === parseInt(incidentChat) && item.chatToUserId === chatuserId;
                            });
                        }
                    } else {
                        itemToSelect = data.chats.filter((item) => {
                            return item.incident === parseInt(incidentChat);
                        });
                    }
                    if (itemToSelect.length > 0) {
                        if(isSharePost) {
                            itemToSelect[0].userFullName =  userFullName;
                            itemToSelect[0].userEmail =  userEmail;
                            itemToSelect[0].userPhone =  userPhone;
                            itemToSelect[0].userAddress =  userAddress;
                            itemToSelect[0].isSharePost =  isSharePost;
                        }
                        
                        newsetChatSelected(itemToSelect[0]);
                    }
                    
                }
               
                if (data.chats !== undefined && data.chats.length >= 0 
                    && chatuserId !== undefined && chatuserId !== "" && chatuserId !== null) {
                    var anyUser = reviewAndSetUserChat(data.chats, chatuserId, incidentChat);
                    var anyIncident = reviewAndSetIncidentChat(data.chats, (incidentChat === undefined || incidentChat === null) ? "0" : incidentChat);
                    var typeChat = queryParams.get('typechat');
                    var item = {
                        to: [chatuserId],
                        id: 0,
                        name: typeChat === "user" ? queryParams.get('name') : incidentChat,
                        img: loc.urlPic && loc.urlPic !== null ? loc.urlPic : "",
                        typechat: typeChat,
                        type: queryParams.get('type'),
                        address: queryParams.get('address'),
                        incidentNum: incidentNum,
                        incidentId: (incidentChat === undefined || incidentChat === null) ? 0 : incidentChat,
                        userFullName: userFullName,
                        userEmail: userEmail,
                        userPhone: userPhone,
                        userAddress: userAddress,
                        isSharePost: isSharePost
                    };

                    if ((anyUser === undefined || anyUser === null || anyUser.length === 0)
                        || ((incidentChat !== "" && incidentChat !== null && incidentChat !== undefined) &&
                            (anyIncident === undefined || anyIncident === null || anyIncident.length === 0))) {
                        newsetChatSelected(item);
                    }

                    if (
                        (itemToSelect === undefined || itemToSelect === null || Object.keys(itemToSelect).length === 0)
                        && (anyUser === undefined || anyUser === null || anyUser.length === 0)
                    ) {
                        data.chats.unshift(item);

                    }
                }
               
                if (itemToSelect && Object.keys(itemToSelect).length > 0) {
                    newsetChatSelected(itemToSelect[0]);
                }

                if (queryParams.has("userId")) {
                    queryParams.delete('userId');
                    queryParams.delete('name');
                    queryParams.delete('urlPic');
                    queryParams.delete('typechat');
                    queryParams.delete('type');
                    queryParams.delete('incident');
                    queryParams.delete('address');
                    history.replace({
                        search: queryParams.toString(),
                    })
                }

                if(data.chats.length > 0) {
                    // var sortedChats = data.chats.map(obj => { return { ...obj, chatDate: formatDate(obj.dateincident) } })
                    // .sort((a, b) => (Number(b.chatDate) - Number(a.chatDate)));

                    var sortedChats = data.chats.map(obj => { return { ...obj, chatDate: obj.dateincident } })
                    .reverse((a, b) => (Number(a.chatDate) - Number(b.chatDate)));

                    let tempChats = sortedChats;
                    let distinctChats = [];

                    let entityPrevious;
                  
                    for (var index = 0; index < tempChats.length; index++) {
                        let entityItem = tempChats[index];
                        if(index === 0 ) {
                            entityPrevious = entityItem;
                            distinctChats.push(entityItem);
                        } else {
                        if(entityItem.address === entityPrevious.address
                           && entityItem.chatToUserId === entityPrevious.chatToUserId 
                           && entityItem.incident === entityPrevious.incident 
                           && entityItem.isSharePost === entityPrevious.isSharePost 
                           && entityItem.type === entityPrevious.type 
                           && entityItem.typechat === entityPrevious.typechat) {
                                // nothing happened here
                            } else {
                                distinctChats.push(entityItem);
                            }
                        }
                        entityPrevious = entityItem
                     }

                    //setUsersChats(sortedChats); //original
                    setUsersChats(distinctChats);
                } else {
                    setUsersChats(data.chats);
                }
                
                setIsLoadingUserChat(false);
                setIsLoadingChatPanel(false);

               

                if (Object.keys(itemToSelect).length > 0) {
                    selectChat(itemToSelect[0]);

                }
                

            });
        } else {
            MessageService.getChatGroup((incident !== undefined && incident !== null) ? incident : parseInt(incidentChat)).then((result) => {
                setUsersChatIncident(result.chats);

                if (itemtoselect !== undefined && itemtoselect.name !== null) {

                    let itemToSelect = result.chats.filter((item) => {
                        return item.id === itemtoselect.id;
                    });

                    if (itemToSelect.length > 0) {
                        var _newItemToSelect = {...itemToSelect[0]
                            , img: itemToSelect[0].img === null ? currentChat.urlPic !== undefined  ? currentChat.urlPic : "" : "" 
                            , type: itemToSelect[0].type === null ? currentChat.type !== undefined  ? currentChat.type : "" : "" 
                        };
                        newsetChatSelected(_newItemToSelect);
                        selectChat(_newItemToSelect);
                    }



                }
                
            });
        }
        
    }

    const formatDate = (dateString) => {
        var dateObject;
        if(dateString !== undefined) {
            var dateParts = dateString.split("/");
            dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
        }
        

        return dateObject
    }

    const reviewAndSetUserChat = (data, chatuserId, incidentId) => {
        setIsLoadingChatPanel(true);
        var selectedchat = data.filter(({ userId, incident }) => userId === chatuserId && (incident === undefined || incident === null || incident ===0 ) );

        if (selectedchat !== undefined && selectedchat.length > 0 && (incidentId === null || incidentId === undefined || incidentId === "")) {

            newsetChatSelected(selectedchat[0]);
            selectChat(selectedchat[0]);

        }
        setIsLoadingChatPanel(false);
        return selectedchat;
    }

    const reviewAndSetIncidentChat = (data, incidentId) => {
        setIsLoadingChatPanel(true);
        var selectedIncident = data.filter(({ incident }) => incident === incidentId);

        if (selectedIncident !== undefined && selectedIncident.length > 0) {

            newsetChatSelected(selectedIncident[0]);
            selectChat(selectedIncident[0]);

        }
        setIsLoadingChatPanel(false);
        return selectedIncident;
    }
    

    const selectChat = async (chatItem) => {

        if (newchatselected === undefined || newchatselected === null  ) {
            newsetChatSelected(chatItem);
        }
        
        if (chatItem && chatItem.id) {
            MessageService.getMessages(chatItem.id).then((data) => {
                setChatList({ chats: data });
            });
        }
        
    }

    const InitChatList = () => {
        setBoardChat({});
        newsetChatSelected({});
        setChatList({chats:[]});
    }
    
    useEffect(() => {

        let connection = signalRserive.getHubConnection();
        if (connection !== undefined && connection !== null && connection._connectionStarted === true) {

            connection.off('ReceiveMessage');
            connection.on('ReceiveMessage', message => {

                getChats(message.incidentId, message);
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newchatselected, newMessageKey]);

    const renderTitle = useMemo(() => {
        if (currentChat === undefined) {
            return (<></>);
        }

        if (currentChat === {}) {
            return (<></>);
        }

        if (currentChat !== undefined && currentChat.incident === null) {
            return (<><strong> User:   </strong><Link to="">{currentChat.title}</Link></>);
        }


        if (currentChat !== undefined && currentChat.incident === "") {
            return (<></>);
        }

        var _sLinkto = "/post/view/" + currentChat.incident;


        return (<><strong> Incident #:   </strong><Link to={_sLinkto}>{currentChat.incidentNum}</Link></>);

    }, [currentChat]);

    //New chat

    const CloseNewChatModal = () => {
        setshowNewChat(false);
    }

    // eslint-disable-next-line no-unused-vars
    const [currentPage, setCurrentPage] = useState(1);

    // eslint-disable-next-line no-unused-vars
    const [pageModalUsersData, setModalUsersPageData] = useState({
        rowData: [],
        isLoading: false,
        totalPages: 0,
        totalData: 0,
    });


    const CreateNewChat = (item) =>
    {
        newsetChatSelected({});
        setChatList({ chats: [] });
        if (item) {
           
            const queryParams = new URLSearchParams(history.location.search);

            queryParams.append('userId', item.id);
            queryParams.append('name', item.fullName);
            //    queryParams.delete('urlPic');
            queryParams.append('typechat', "user");
            queryParams.append('type', (item.division ? item.division.name : ""));
            queryParams.append('address', (item.address ? item.address.address1 : ""));
            history.replace({
                pathname: `/messages`,
                search: queryParams.toString(),
                state: { urlPic: item.picture && item.picture !== null ? item.picture.path : "" }
            });
            CloseNewChatModal();
            getChats();
        }
    }

    return (
        <>
            <Row>
                <Col md="3" class="ChatListPanel">
                    {(boardchatselected.typechat === "user" || boardchatselected.typechat == null) &&
                        <>
                            <Row className="rowchat">
                                <label className="Chatlbl col-9">
                                    Chats
                            </label>
                                {/** Commented this temporarily since there's an error occur **/}
                                {/* <button onClick={newchatClick} className="btn btn-plus col-3">+</button> */}
                            </Row>
                            {/* <Row>
                                <input type="text" className=" ChatInputSearch" placeholder="Search chat..." />
                            </Row> */}

                            <Row class="ChatList">
                                {isloadinguserchat &&
                                    <>
                                        <LoaderUserChat />
                                    </>
                                }
                                {!isloadinguserchat &&
                                    <>
                                        {userschat.map((item) => (
                                            <Row key={item.id} className={`chatitemlist ${newchatselected.id === item.id ? "chatselected" : ""} `} onClick={() => SelectNewChatPreview(item)}>
                                                <Col md="4">
                                                    {item.img === "" && item.typechat === "incident" &&

                                                    <div class="image-cropper">
                                                        <img src={nopreview} aria-hidden alt="Card image cap" class="rounded" />
                                                    </div>

                                                    }

                                                    {item.img === "" && item.typechat === "user" &&
                                                        <StyledBadge
                                                            className="badgeuser"
                                                            overlap="circular"
                                                            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                                            variant="dot"
                                                        >
                                                            <Avatar name={item.name} size="50" round />
                                                        </StyledBadge>
                                                    }

                                                    {
                                                        item.img !== "" && item.typechat === "incident" &&



                                                        <Avatar src={item.img} size="50" round />


                                                    }
                                                    {item.img !== "" && item.typechat === "user" &&


                                                        <StyledBadge
                                                            overlap="circular"
                                                            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                                            variant="dot"
                                                        >
                                                            <Avatar src={item.img} size="50" round />
                                                        </StyledBadge>

                                                    }
                                                </Col>
                                                <Col md="8">
                                                    {item.typechat === "user" &&
                                                        <>
                                                            <Row>
                                                                <Col>
                                                                    <label className="lblitemname">
                                                                        {item.incident && item.incident > 0 ? "Incident: " : ""}
                                                                        {item.name}
                                                                    </label>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <label className="lblitemtype">
                                                                    {item.incident && item.incident > 0 ? "Type: " : ""}
                                                                    {item.type}
                                                                </label>
                                                            </Row>
                                                            <Row >
                                                                <Col md="12">
                                                                    <label className="lblitemtype txtblack">Location:</label><label className="lblitemtype"> {item.userAddress}</label>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <label className="lblitemmsg">{item.lastmessage}</label>
                                                            </Row>
                                                            {
                                                              item.isSharePost && (
                                                                <>
                                                                    <Row >
                                                                       
                                                                            <label className="lblitemtype txtblack">Shared To : </label>
                                                                       
                                                                    </Row>
                                                                    <Row >
                                                                     
                                                                            <label className="lblitemtype txtblack">{item.userFullName}</label>
                                                                       
                                                                    </Row>
                                                                    <Row >
                                                                        <Col md="12">
                                                                            <img alt="" src={phoneIcon} className="iconpngchat"></img> {item.userPhone && <label className="lblitemtype txtblack">{item.userPhone}</label>}{!item.userPhone && <label>-</label>}
                                                                        </Col>
                                                                    </Row>
                                                                    <Row >
                                                                        <Col md="12">
                                                                            <img alt="" src={emailIcon} className="iconpngchat"></img> {item.userEmail && <label className="lblitemtype txtblack">{item.userEmail}</label>}{!item.userEmail && <label>-</label>}
                                                                        </Col>
                                                                    </Row>
                                                                </>
                                                              )
                                                            }
                                                        </>
                                                    }
                                                    {item.typechat === "incident" &&

                                                        <>
                                                            <Row>
                                                                <label className="lblitemname"> Incident : {item.incidentNum}</label>
                                                            </Row>
                                                            <Row>
                                                                <Col md="12">
                                                                    <label className="lblitemtype txtblack">Type : </label><label className="lblitemtype">{item.type}</label>
                                                                </Col>
                                                            </Row>
                                                            <Row >
                                                                <Col md="12">
                                                                    <label className="lblitemtype txtblack">Location : </label><label className="lblitemtype"> {item.address}</label>
                                                                </Col>
                                                            </Row>

                                                            {
                                                              item.isSharePost && (
                                                                <>
                                                                    <Row >
                                                                        <Col md="12">
                                                                            <label className="lblitemtype txtblack">Shared To : </label>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row >
                                                                        <Col md="12">
                                                                            <label className="lblitemtype txtblack">{item.userFullName}</label>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row >
                                                                        <Col md="12">
                                                                            <img alt="" src={phoneIcon} className="iconpngchat"></img> {item.userPhone && <label className="lblitemtype txtblack">{item.userPhone}</label>}{!item.userPhone && <label>-</label>}
                                                                        </Col>
                                                                    </Row>
                                                                    <Row >
                                                                        <Col md="12">
                                                                            <img alt="" src={emailIcon} className="iconpngchat"></img> {item.userEmail && <label className="lblitemtype txtblack">{item.userEmail}</label>}{!item.userEmail && <label>-</label>}
                                                                        </Col>
                                                                    </Row>
                                                                </>
                                                              )
                                                            }


                                                        </>

                                                    }

                                                </Col>
                                            </Row>
                                        ))}
                                    </>
                                }
                            </Row>

                        </>
                    }
                    {boardchatselected.typechat === "incident" &&
                        <Row className="rowchat">
                            <Col md="12">
                            <label className="lblboardincident" onClick={() => InitChatList()}><FontAwesomeIcon icon={faArrowLeft} size="l" />&nbsp;</label>
                            <label onClick={() => InitChatList()} className="lblboardincident"> Incident: &nbsp; {boardchatselected.name}</label>
                                {boardchatselected.img !== "" &&



                                <Avatar src={boardchatselected.img} size="50" round />


                                }
                                {boardchatselected.img === "" &&

                                <div class="image-cropper">
                                <   img src={nopreview} aria-hidden alt="Card image cap" class="rounded" />
                                </div>

                                }
                            </Col>
                            <Col md="12">
                                <img alt="" className="pngic" src={type}></img>  <label className="lblboardinc"> {boardchatselected.type}</label>
                            </Col>
                            <Col md="12">
                                <img alt="" className="pngic" src={locationpng}></img>   <label className="lblboardinc">  {boardchatselected.address}</label>
                            </Col>
                            <Col md="12">
                                <img alt="" className="pngic" src={calendar}></img> <label className="lblboardinc">{boardchatselected.dateincident}</label>
                            </Col>
                            {/* <Row className="rowchat" md="12">
                                <input type="text" className=" ChatInputSearch" placeholder="Search chat..." />
                            </Row> */}
                            <Row>
                                <Col md="12">
                                <br />
                                    <label>Related Chats</label>
                                    <br />
                                </Col>
                            </Row>
                            <Col md="12">
                                {userschatincident.map((item) => (
                                    <Row className={`chatitemlist ${newchatselected.id === item.id ? "chatselected" : ""} `} onClick={() => SelectNewChatPreviewIncident(item)}>
                                        <Col md="4">
                                            {item.img === "" && item.typechat === "incident" &&

                                                <div class="image-cropper">
                                                    <img src={nopreview} aria-hidden alt="Card image cap" class="rounded" />
                                                </div>

                                            }
                                            {item.img === "" && item.typechat === "user" &&
                                                <StyledBadge
                                                    className="badgeuser"
                                                    overlap="circular"
                                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                                    variant="dot"
                                                >
                                                    <Avatar name={item.name} size="50" round />
                                                </StyledBadge>
                                            }
                                            {item.img !== "" && item.typechat === "incident" &&



                                                <Avatar src={item.img} size="50" round />


                                            }
                                            {item.img !== "" && item.typechat === "user" &&


                                                <StyledBadge
                                                    overlap="circular"
                                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                                    variant="dot"
                                                >
                                                    <Avatar src={item.img} size="50" round />
                                                </StyledBadge>

                                            }
                                        </Col>
                                        <Col md="8">
                                            {item.typechat === "user" &&
                                                <>
                                                    <Row>
                                                        <label className="lblitemname">{item.officername}</label>
                                                    </Row>
                                                    <Row>
                                                        <label className="lblitemtype">{item.type}</label>
                                                    </Row>
                                                    <Row>
                                                        <label className="lblitemmsg">{item.lastmessage}</label>
                                                    </Row>
                                                </>
                                            }
                                            {item.typechat === "incident" &&

                                                <>
                                                    <Row>
                                                        <label className="lblitemname"> Incident :{item.name}</label>
                                                    </Row>
                                                    <Row>
                                                        <Col md="12">
                                                            <label className="lblitemtype txtblack">Type:</label><label className="lblitemtype">{item.type}</label>
                                                        </Col>
                                                    </Row>
                                                    <Row >
                                                        <Col md="12">
                                                            <label className="lblitemtype txtblack">Location:</label><label className="lblitemtype"> {item.address}</label>
                                                        </Col>
                                                    </Row>
                                                    {
                                                              item.isSharePost && (
                                                                <>
                                                                    <Row >
                                                                        <Col md="12">
                                                                            <label className="lblitemtype txtblack">Shared To : </label>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row >
                                                                        <Col md="12">
                                                                            <label className="lblitemtype txtblack">{item.userFullName}</label>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row >
                                                                        <Col md="12">
                                                                            <img alt="" src={phoneIcon} className="iconpngchat"></img> {item.userPhone && <label className="lblitemtype txtblack">{item.userPhone}</label>}{!item.userPhone && <label>-</label>}
                                                                        </Col>
                                                                    </Row>
                                                                    <Row >
                                                                        <Col md="12">
                                                                            <img alt="" src={emailIcon} className="iconpngchat"></img> {item.userEmail && <label className="lblitemtype txtblack">{item.userEmail}</label>}{!item.userEmail && <label>-</label>}
                                                                        </Col>
                                                                    </Row>
                                                                </>
                                                              )
                                                            }
                                                </>

                                            }

                                        </Col>
                                    </Row>
                                ))}
                            </Col>
                        </Row>
                    }
                </Col>

                <Col md="9" className="chatboard">
                    <Row className="chatrowpreview">
                        <Col md="1">
                            {newchatselected.img === "" && newchatselected.typechat === "incident" &&

                            <div class="image-cropper">
                                <img src={nopreview} aria-hidden alt="Card image cap" class="rounded" />
                            </div>

                            }
                            {newchatselected.img === "" && newchatselected.typechat === "user" &&
                                <StyledBadge
                                    className="badgeuser"
                                    overlap="circular"
                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                    variant="dot"
                                >
                                    <Avatar name={newchatselected.name} size="50" round />
                                </StyledBadge>
                            }
                            {newchatselected.img !== "" && newchatselected.typechat === "incident" &&



                                <Avatar src={newchatselected.img} size="50" round />


                            }
                            {newchatselected.img !== "" && newchatselected.typechat === "user" &&


                                <StyledBadge
                                    overlap="circular"
                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                    variant="dot"
                                >
                                    <Avatar src={newchatselected.img} size="50" round />
                                </StyledBadge>

                            }
                        </Col>
                        <Col md="8">
                            <Row>
                                <Col md="8">
                                    {(newchatselected.typechat === "user" && boardchatselected.typechat === "incident") &&
                                        <label className="titlechatprev">{newchatselected.officername}</label>
                                    }

                                    {(newchatselected.typechat === "user" && boardchatselected.typechat === undefined) &&
                                        <label className="titlechatprev">{newchatselected.name}</label>
                                    }
                                   
                                    {(newchatselected.typechat === "user" && boardchatselected.typechat === "user") &&
                                      <>
                                        <label className="titlechatprev">{newchatselected.name}&nbsp;</label>
                                        { newchatselected.incident === null && StatusBadge(newchatselected.appStatus === 1 ? 2: newchatselected.deleted ? 3 : 1)}

                                      </>
                                        
                                        
                                    }
                                    <label className="datachatpreview">&nbsp;{newchatselected.type}</label>
                                </Col>


                            </Row>
                            <Row>
                                <Col md="100">
                                    {(newchatselected.typechat === "user" && boardchatselected.typechat=== "incident" && !isSelectNewChatPreview) && 
                                        <div className="row">
                                            <Col md="2">
                                                    {
                                                        boardchatselected.img === "" ? 
                                                        <div class="image-cropper">
                                                             <img src={nopreview} aria-hidden alt="Card image cap" class="rounded" />
                                                         </div> 
                                                        :  
                                                        <Avatar src={boardchatselected.img} size="40" round />
                                                    }
                                            </Col>
                                            <Col md="6">
                                                <label className="titlechatprevx">Incident:  {newchatselected.name}</label>
                                            </Col>
                                        
                                        </div>
                                    }

                                    {
                                        //[Start] - Address
                                    }
                                    {(newchatselected.typechat === "user" && boardchatselected.typechat === "user" && newchatselected.incident === 0) && 
                                        <div className="row"> 
                                            <Col md="8">
                                                <img alt="" src={addresspng} className="iconpngchat"></img> {newchatselected.userAddress && <label className="datachatpreview">{newchatselected.userAddress}</label>}{!newchatselected.userAddress && <label>-</label>}
                                            </Col>
                                        </div>
                                    }
                                    {(newchatselected.typechat === "user" && boardchatselected.typechat === "user" && newchatselected.incident  > 0) && 
                                        <div className="row"> 
                                            <Col md="100">
                                                <img alt="" src={addresspng} className="iconpngchat"></img> {newchatselected.address && <label className="datachatpreview">{newchatselected.address}</label>}{!newchatselected.address && <label>-</label>}
                                            </Col>
                                        </div>
                                    }
                                    {(newchatselected.typechat === "user" && boardchatselected.typechat === undefined) && 
                                        <div className="row"> 
                                            <Col md="100">
                                                <img alt="" src={addresspng} className="iconpngchat"></img> {newchatselected.userAddress && <label className="datachatpreview">{newchatselected.userAddress}</label>}{!newchatselected.userAddress && <label>-</label>}
                                            </Col>
                                        </div>
                                    }
                                    {(newchatselected.typechat === "incident" && newchatselected.incident > 0 && boardchatselected.typechat === undefined) && 
                                        <div className="row"> 
                                            <Col md="100">
                                                <img alt="" src={addresspng} className="iconpngchat"></img> {newchatselected.address && <label className="datachatpreview">{newchatselected.address}</label>}{!newchatselected.address && <label>-</label>}
                                            </Col>
                                        </div>
                                    }
                                    {
                                        //[End] - Address
                                    }
                                    {
                                        //[Start] - Address of a specific user in a incident group chat
                                    }
                                    {(newchatselected.typechat === "user" && newchatselected.incident > 0 && boardchatselected.typechat === "incident" && boardchatselected.incident > 0) && 
                                        <div className="row"> 
                                            <Col md="100">
                                                <img alt="" src={addresspng} className="iconpngchat"></img> {newchatselected.userAddress && <label className="datachatpreview">{newchatselected.userAddress}</label>}{!newchatselected.userAddress && <label>-</label>}
                                            </Col>
                                        </div>
                                    }
                                    {
                                        //[End] - Address of a specific user in a incident group chat
                                    }

                                    {
                                        //[Start] - Phone
                                    }
                                    {(newchatselected.typechat === "user" && boardchatselected.typechat === "user" && newchatselected.incident === 0) && 
                                         <div className="row"> 
                                            <Col md="8">
                                                <img alt="" src={phoneIcon} className="iconpngchat"></img> {newchatselected.userPhone && <label className="datachatpreview">{newchatselected.userPhone}</label>}{!newchatselected.userPhone && <label>-</label>}
                                            </Col>
                                        </div>
                                    }
                                      {(newchatselected.typechat === "user" && boardchatselected.typechat === undefined) && 
                                         <div className="row"> 
                                            <Col md="8">
                                                <img alt="" src={phoneIcon} className="iconpngchat"></img> {newchatselected.userPhone && <label className="datachatpreview">{newchatselected.userPhone}</label>}{!newchatselected.userPhone && <label>-</label>}
                                            </Col>
                                        </div>
                                    }
                                    {
                                        //[End] - Phone
                                    }
                                    {
                                        //[Start] - Phone of a specific user in a incident group chat
                                    }
                                     {(newchatselected.typechat === "user" && newchatselected.incident > 0 && boardchatselected.typechat === "incident" && boardchatselected.incident > 0) && 
                                        <div className="row"> 
                                            <Col md="100">
                                            <img alt="" src={phoneIcon} className="iconpngchat"></img> {newchatselected.userPhone && <label className="datachatpreview">{newchatselected.userPhone}</label>}{!newchatselected.userPhone && <label>-</label>}
                                            </Col>
                                        </div>
                                    }
                                    {
                                        //[End] - Phone of a specific user in a incident group chat
                                    }

                                    {
                                        //[Start] - Email
                                    }
                                    {(newchatselected.typechat === "user" && boardchatselected.typechat === "user" && newchatselected.incident === 0) && 
                                        <div className="row"> 
                                            <Col md="8">
                                                <img alt="" src={emailIcon} className="iconpngchat"></img> {newchatselected.userEmail && <label className="datachatpreview">{newchatselected.userEmail}</label>}{!newchatselected.userEmail && <label>-</label>}
                                            </Col>
                                        </div>
                                    }
                                    {(newchatselected.typechat === "user" && boardchatselected.typechat === undefined) && 
                                        <div className="row"> 
                                            <Col md="8">
                                                <img alt="" src={emailIcon} className="iconpngchat"></img> {newchatselected.userEmail && <label className="datachatpreview">{newchatselected.userEmail}</label>}{!newchatselected.userEmail && <label>-</label>}
                                            </Col>
                                        </div>
                                    }
                                    {
                                        //[End] - Email
                                    } 
                                    {
                                        //[Start] - Email of a specific user in a incident group chat
                                    }
                                     {(newchatselected.typechat === "user" && newchatselected.incident > 0 && boardchatselected.typechat === "incident" && boardchatselected.incident > 0) && 
                                        <div className="row"> 
                                            <Col md="100">
                                            <img alt="" src={emailIcon} className="iconpngchat"></img> {newchatselected.userEmail && <label className="datachatpreview">{newchatselected.userEmail}</label>}{!newchatselected.userEmail && <label>-</label>}
                                            </Col>
                                        </div>
                                    }
                                    {
                                        //[End] - Email of a specific user in a incident group chat
                                    }   
                                                                   
                                </Col>

                            </Row>

                        </Col>
                        <Col md="2">
                        </Col>
                    </Row>
                    {isLoadingChatPanel &&
                        <>
                            <LoaderChatPanel />
                        </>

                    }
                    {!isloadinguserchat && !isLoadingChatPanel && userschat.length === 0 &&
                        <>
                            <AnyMessageState />
                        </>
                    }
                    {!isLoadingChatPanel &&
                        <Row>
                            <Col md="12" className="ChatPanel">
                                <Form>


                                    <Row>
                                        {newchatselected.id != null &&
                                            <ChatList getMessages={selectChat} Chats={chatList} />
                                        }
                                    </Row>



                                </Form>
                            </Col>
                            <Col md="12" className="divSend">
                                <Row>
                                    <span className="LabelRigth">
                                        {
                                            renderTitle
                                        }
                                    </span>
                                </Row>

                                <Row>
                                    {newchatselected.id != null && ChatMessageItem &&
                                        <ChatWindow className="viewMessages" chat={ChatMessageItem.chat} />
                                    }
                                </Row>
                                <Row>
                                    {newchatselected.id != null &&
                                        <Chat DisableSend={disableSend} sendMessage={sendMessage} />
                                    }
                                </Row>

                            </Col>
                        </Row>
                    }
                </Col>
            </Row>
            <NewChatModal CreateNewChat={CreateNewChat} setCurrentPage={setCurrentPage}  data={pageModalUsersData} show={showNewChat} CloseNewChatModal={CloseNewChatModal}/>
        </>
       
    );
};

export default ChatPanel;