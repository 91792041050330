import { faBuilding, faCircle, faMessage, faPhone, faUserGroup, faAt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row, Col } from 'reactstrap';
import { library } from '@fortawesome/fontawesome-svg-core';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { NotificationManager } from 'react-notifications';
import { useHistory } from 'react-router-dom';
import Avatar from 'react-avatar';
import api from "../../services/api";
import "./ProfileCard.css";
import userCard from "../../assets/CardUser.svg";
import phone from "../../assets/Phone.svg";
import email from "../../assets/Email.svg";
import StatusBadge from '../Officer/StatusBadge';
library.add(faUserGroup, faCircle, faBuilding, faMessage, faPhone, faAt)

const UserCard = (props) => {
    let history = useHistory();
    const { id } = props;
    const [profile, SetProfile] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    const RedirectMessage = (state,userId, name, type, urlPic, typechat) => {
        if (state != null && (state === "Suspended" || state === "Removed")){
            NotificationManager.info('Information', 'The account cannot be contacted because it was deleted or suspended.', 5000);
            return;
        }

        let img = urlPic;
        if (props.Incident !== undefined && (props.Incident.filesIncidents != null && props.Incident.filesIncidents !== undefined && props.Incident.filesIncidents.length >0)) {
            img = props.Incident.filesIncidents[0].file.path;
        }

        history.push({
            pathname: `/messages`,
            search: `?userId=${userId}&name=${name}&type=${props.Incident !== undefined ? props.Incident.type : type}&typechat=${typechat}&incident=${props.Incident !== undefined ? props.Incident.id : ""}&address=${props.Incident !== undefined ? props.Incident.address : ""}`,
            state: {
                urlPic: img,
                userId: userId,
                name: name,
                type: (props.Incident !== undefined ? props.Incident.type : type),
                typechat: typechat,
                incident:props.Incident !== undefined ? props.Incident.id : "",
                incidentNum:props.Incident !== undefined ? props.Incident.incidentNum : "",
                address: props.Incident !== undefined ? props.Incident.address : ""
            }

        });

    }

    useEffect(() => {
        setIsLoading(true);
        api.get(
            `/officer/getofficer?userId=${id}`
        ).then((response) => {
            SetProfile(response.data);
            setIsLoading(false);

        })
            .catch((error) => {
                setIsLoading(false);
                console.log(error);
            });
    }, [setIsLoading]);


    return (
        <>

            {isLoading && isLoading === true &&
                <div className="popupProfileContent">
                    <div className="bodyProfilePopup">
                        <div className="spinner-border text-primary" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                </div>
            }
            {!isLoading && isLoading === false &&

                <>
                    <div className="col-6" key={['idoffice', profile.id]}>
                        <div className="row  card-officer">

                            <div className="col-3 card no-mgr">
                                <div className="row">
                                    {profile.urlPic &&
                                        <Avatar src={profile.urlPic} size="80" round />

                                    }
                                    {!profile.urlPic &&
                                        <Avatar name={profile.name} size="80" round />

                                    }
                                </div>
                                <div className="row" style={{flex: "1", alignItems:"center", justifyContent:"center", fontFamily: "Work Sans"}}>
                                    <button type="button" 
                                            disabled={profile.deleted ? true : profile.statusApp === "Suspended" ? true : false}
                                            onClick={() => RedirectMessage(profile.statusApp, profile.id, profile.name, profile.division, profile.urlPic, "user")}
                                            className="btn btn-outline-primary btn-lg btn-bloc col-lg-12"
                                             >
                                         <div style={{fontSize: "14px"}}>Message</div>
                                    </button>
                                </div>
                            </div>

                            <div className="col-8 card bg-white no-mgl">
                            <div className="row" style={{textAlign: "right", paddingRight: "15px"}}><span>{StatusBadge(profile.deleted ? 3 : profile.statusApp === "Suspended" ? 2 : 1)}</span></div>
                                <div className="row">   <label className="titlename">{profile.name}</label></div>
                                <div className="row">  <label className="lblinfo">Division:  <span className="txtinfo">{profile.division}</span></label></div>
                                <div className="row">   <label className="lblinfo">Phone:   <span className="txtinfo"><a href={`tel:${profile.phone ?? ""}`}>{profile.phone}</a></span></label></div>
                                <div className="row">  <label className="lblinfo">Email: <span className="txtinfo"><a href={`mailto:${profile.email}`}>{profile.email}</a></span></label> </div>
                                <div className="row">  <label className="lblinfo">Address: <span className="txtinfo">{profile.address}</span></label> </div>




                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    );
}

export default UserCard;