/*eslint unicode-bom: ["error", "always"]*/
import React, { useEffect, useState, useCallback } from "react";
import { useForm } from 'react-hook-form';
import Autocomplete from "react-google-autocomplete";
import './CreateAgency.css';
import agencyimg from "../../assets/agency.png";
import { useHistory } from 'react-router-dom';
import { faInfo, faLocationArrow, faSitemap, faEnvelope, faMobile,faGlobe} from '@fortawesome/free-solid-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AuthService from "../../services/auth.service";
import InputMask from "react-input-mask";
import api from "../../services/api";
library.add(faInfo, faLocationArrow, faSitemap, faEnvelope, faMobile, faGlobe);
const CreateAgency = () => {
    const { register, handleSubmit, formState: { errors }, setError, clearErrors } = useForm({
        mode: 'onChange'
    });
    let history = useHistory();
    const GOOGLE_API = "AIzaSyA3KsiVMTTNXNNWrViDI8qwSYFfbRkaQI4";
    const SaveAgency = (event) => {


        if(agency.phone !== undefined) {
            let _tempPhone = agency.phone.replace(/\D/g,'');
            if(_tempPhone.trimEnd() !== "") {
                if (  _tempPhone.length < 11) {
                    setError('phone', { type: 'invalid-format' });
                    return ;
                } else {
                    clearErrors("phone");
                }
            } else {
                clearErrors("phone");
            }
        }
        

        const formData = new FormData();

        formData.append("Name", agency.name);
        formData.append("Agency", agency.agencyId);
        formData.append("UserId", "-");
        formData.append("State", location.e ? location.e.address_components[2].long_name : agency.locationinput);
        formData.append("AddressId", location.e ? location.e.place_id : agency.addressId);
        formData.append("NameAddress", location.e ? location.e.formatted_address : agency.locationinput);
        formData.append("Latitude", location.e ? location.e.geometry.location.lat() : agency.latitude);
        formData.append("Longitude", location.e ? location.e.geometry.location.lng() : agency.longitude);
        formData.append("FormFile", agency.selectedImage);
        formData.append("Phone", agency.phone);
        formData.append("Email", agency.email);
        formData.append("Url", agency.url);
        formData.append("ManualRequest", agency.manualrequest ? agency.manualrequest:false);
        formData.append("BulkRequest", agency.bulkrequest ? agency.bulkrequest : false);
     


        api.post("/agency/save", formData, AuthService.getToken())
                .then(response => {
                    if (response.data.id && response.data.id !== 0) {
                        history.push("/manageagencies");
                    }


                });
    }

    const [agencyExist, setAgencyExist] = useState(undefined);
    const [originalAgencyName, setOriginalAgencyName] = useState("");
    const [location, setLocation] = useState({
    });

    const [agency, setAgency] = useState({
        name:"",
        locationinput: "",
        selectedImage: null,
        agencyId: 0,
        addressId: 0,
        latitude: 0.0,
        longitude: 0.0
    });

    const onChangeLocation = e => {
        setLocation({
            ...location, e
        });

        
    }

    const onChangeInputMap = e => {
        setAgency({
            ...agency,
            locationinput: e.target.value
        });

    }
   
    const onChangeName = useCallback(e => {
        if (e.target.value !== "" || e.target.value !==null) {
            if(agencyExist !== undefined) {

                let _agencyExist;

                _agencyExist = (agencyId !== undefined && agencyId > 0) 
                ? 
                _agencyExist = agencyExist.filter(function (x) { return x !== originalAgencyName; })
                :
                _agencyExist = agencyExist

                if(_agencyExist.includes(e.target.value.toLowerCase())) {
                    setError('agencyinput', { type: 'exist' });
                } else {
                    clearErrors("agencyinput");
                }
            } else {
                clearErrors("agencyinput");
            }
        } else {
            clearErrors("agencyinput");
        }

        setAgency({
            ...agency,
            name: e.target.value
        });

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[setError,agencyExist,originalAgencyName])

    const CancelFnc = () => {
        history.push("/manageagencies");
    }

    const onchangePhone = e => {

        let _tempPhone = e.target.value.replace(/\D/g,'');
        if(_tempPhone.trimEnd() !== "") {
            if (  _tempPhone.length < 11) {
                setError('phone', { type: 'invalid-format' });
            } else {
                clearErrors("phone");
            }
        } else {
            clearErrors("phone");
        }

        setAgency({
            ...agency,
            phone: e.target.value
        });
    }
    const onChangeManualRequests = e => {
        setAgency({
            ...agency,
            manualrequest: !agency.manualrequest

        });
    }

    const onChangeBulkRequest = e => {
        setAgency({
            ...agency,
            bulkrequest: !agency.bulkrequest

        });
    }
    const onchangeEmail = e => {
        setAgency({
            ...agency,
            email: e.target.value
        });
    }
    const onchangeUrl = e => {
        setAgency({
            ...agency,
            url: e.target.value
        });
    }

   

    const queryParams = new URLSearchParams(window.location.search);
    const agencyId = queryParams.get('agency');

    const getAgency = async () => {
        api.get("/agency/getAgency?agency=" + agencyId, AuthService.getToken())
            .then(response => {
                setAgency({
                    ...agency,
                    name: response.data.name,
                    locationinput: response.data.address.address1,
                    addressId: response.data.address.googlePlaceId,
                    latitude: response.data.address.latitude,
                    longitude: response.data.address.longitude,
                    agencyId: response.data.id,
                    phone: response.data.phone ? response.data.phone:"",
                    email: response.data.email ? response.data.email : "",
                    url: response.data.url ? response.data.url : "",
                    manualrequest: response.data.manageRequest,
                    img:response.data.picture.path,
                    bulkrequest: response.data.bulkRequest

                });
                setOriginalAgencyName(response.data.name.toLowerCase());
            });
            
    }

    const getAgencyExist = async () => {
        api.get("/agency/get/exist", AuthService.getToken())
            .then(response => {
                setAgencyExist(response.data.data);
            });
    }

    if (agencyId !== undefined && agencyId > 0 && agency.agencyId ===0)
    {
        getAgency();
       
    }

    useEffect(() => {getAgencyExist();},[]);

    return (
        <>
            <div className="row">
                <div className="col-3">
                </div>
                <div className="col-6">
            <div className="card-container">
                <div className="card">
                            <form onSubmit={handleSubmit(SaveAgency)}>
                        <div className="card-body">
                            {agencyId !== undefined && agencyId > 0 &&
                                <h5 className="card-titleagency">Edit Agency</h5>
                            }
                            {agencyId === undefined && agencyId === 0 &&
                                <h5 className="card-titleagency">Add new Agency</h5>
                            }
                            
                                    <div className="row centerimg">
                                        {agency.img && !agency.selectedImage &&
                                            <img alt="" src={agency.img} className="iconagency" />
                                        }
                                        {!agency.selectedImage && !agency.img &&
                                            <img alt="" src={agencyimg} className="iconagency" />
                                        }
                                        {agency.selectedImage && (
                                            <div>
                                                <img alt="Not Found" className="iconagencysaved" src={URL.createObjectURL(agency.selectedImage)} />
                                                <br />
                                                <button className="btn btn-danger" onClick={() => setAgency({
                                                    ...agency,
                                                    selectedImage: null
                                                })}>Remove</button>
                                            </div>
                                        )}
                                        <br />

                                        <br />
                                        <input
                                            className={errors.image ? 'form-control is-invalid' : 'form-control'}
                                            type="file"
                                            name="myImage"

                                            {...register("image", { required: (agency.id === 0 || (agency.id !== 0 && agency.img == null)) })}
                                            onChange={(event) => {
                                                setAgency({
                                                    ...agency,
                                                    selectedImage: event.target.files[0]
                                                });
                                                clearErrors("image");
                                            }}
                                        />
                            </div>
                            <div className="row">

                                        <div className="col-md-12 col-sm-12">
                                            <label className="lb">Name</label>
                                        </div>
                                <div className="col-md-12 col-sm-12">
                                    <div className="input-group mb-3">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text" id="basic-addon1">  <FontAwesomeIcon icon={faInfo} /></span>
                                        </div>
                                        <input required type="text" className="form-control" onChange={onChangeName} value={agency.name} placeholder="Enter Agency's name" aria-label="Username" aria-describedby="basic-addon1" />
                                    </div>
                                    {errors.agencyinput && errors.agencyinput.type === "exist" && 
                                    <div className="input-group mb-3">
                                        <span className="text-danger">Agency name is already exist</span>
                                    </div>
                                    
                                    }
                                </div>
                              
                            </div>
                                    <div className="row">
                                        <div className="col-md-12 col-sm-12">
                                            <label className="lb">Location</label>
                                        </div>
                                <div className="col-md-12 col-sm-12">
                                    <div className="input-group mb-3">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text" id="basic-addon1">  <FontAwesomeIcon icon={faLocationArrow} /></span>
                                        </div>

                                        <Autocomplete
                                            apiKey={GOOGLE_API}
                                            className="form-control"
                                            placeholder="Enter Agency's location"
                                            defaultValue={agency.locationinput}
                                            required
                                            options={{

                                                componentRestrictions: { country: "us" },
                                            }}

                                            onChange={onChangeInputMap}
                                            onPlaceSelected={(place) => {
                                                onChangeLocation(place);
                                            }}
                                        />

                                    </div>
                                </div>
                                    </div>

                                    <div className="row">

                                        <div className="col-md-12 col-sm-12">
                                            <label className="lb">Phone</label>
                                        </div>
                                        <div className="col-md-12 col-sm-12">
                                            <div className="input-group mb-3">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text" id="basic-addon1">  <FontAwesomeIcon icon={faMobile} /></span>
                                                </div>
                                                <InputMask placeholder="Enter Agency's Phone" onChange={onchangePhone} value={agency.phone} className="form-control" mask="+1\ 999 999 9999" />
                                               
                                            </div>
                                            {errors.phone && errors.phone.type === "invalid-format" && <span className="text-danger">Invalid format</span>}
                                        </div>

                                    </div>

                                    <div className="row">

                                        <div className="col-md-12 col-sm-12">
                                            <label className="lb">Email</label>
                                        </div>
                                        <div className="col-md-12 col-sm-12">
                                            <div className="input-group mb-3">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text" id="basic-addon1">  <FontAwesomeIcon icon={faEnvelope} /></span>
                                                </div>
                                                <input id="email" name="email" onChange={onchangeEmail} value={agency.email} required type="email" className="form-control" placeholder="Enter Agency's E-mail" aria-label="Username" aria-describedby="basic-addon1" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" />
                                            </div>
                                        </div>

                                    </div>
                                    <div className="row">

                                        <div className="col-md-12 col-sm-12">
                                            <label className="lb">Url</label>
                                        </div>
                                        <div className="col-md-12 col-sm-12">
                                            <div className="input-group mb-3">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text" id="basic-addon1">  <FontAwesomeIcon icon={faGlobe} /></span>
                                                </div>
                                                <input onChange={onchangeUrl} value={agency.url} type="url" className="form-control" placeholder="Enter Agency's url" aria-label="Username" aria-describedby="basic-addon1" />
                                            </div>
                                        </div>

                                    </div>
                                    <div className="row">
                                        <div className="col-md-12 col-sm-12">
                                            <label className="lb"> Admin settings</label>
                                        </div>
                                       
                                    </div>
                                    <div className="row">

                                        <div className="col-9"> <label className="lb"> Manual join requests</label> </div><div className="col-3 toggle-switch"> <input type="checkbox" checked={agency.manualrequest} className="toggle-switch-1" /> <label onClick={onChangeManualRequests}></label></div>
                                        
                                    </div>
                                    <div className="row">

                                        <div className="col-9"> <label className="lb"> Bulk processing of requests</label> </div><div className="col-3 toggle-switch"> <input type="checkbox" id="topping"
                                            name="topping"
                                            value="Paneer" checked={agency.bulkrequest} className="toggle-switch-1" /> <label onClick={onChangeBulkRequest}></label></div>
                                       
                                    </div>
                         
                            <div className="row">
                               
                              
                                        <div className="col-md-12 col-sm-12">
                                            {agencyId !== undefined && agencyId > 0 &&
                                                <button type="submit" className="btn btn-primary btn-full" >Save Changes</button>
                                            }
                                            
                                            {(agencyId === undefined || agencyId == null || agencyId === 0 )  &&
                                                <button type="submit" className="btn btn-primary btn-full" >+ Add Agency</button>
                                            }
                                            <button type="button" className="btn btn-secondary btn-fullx" onClick={CancelFnc} >Cancel</button>
                                </div>
                                    </div>

                                    

                        </div>
                    </form>
                </div>
            </div>
                </div>
            </div>
        </>
    );
}
export default CreateAgency;