/*eslint unicode-bom: ["error", "always"]*/
import { useRef, useEffect, useState, createRef } from "react";
import { Link } from "react-router-dom";
import { Label, Row,Col } from "reactstrap";
import Avatar from 'react-avatar';
const ChatList = props => {
    const scrollRefs = useRef([]);
    const [loaded, setLoaded] = useState(false);
   const handleClick=(e,incident)=> {
       props.getMessages(e, incident);
    }

    function renderChatTitle(currentChat) {
        if (currentChat !== undefined && currentChat.incident === null) {
            return (<><Label><strong>User: </strong> #{currentChat.title}</Label></>);
        }
        return (<><Label><strong>Incident #:</strong> <a className="linkIncident" href="/#">#{currentChat.title}</a></Label></>);
    }

    const scrollSmoothHandler = (index) => {
        scrollRefs.current[index].scrollIntoView({ behavior: "smooth" });
    };

    function convertUTCTimeToLocalTime(utcDateString) {
        const date = new Date(utcDateString);
        const localTime = date.toLocaleString();
        return localTime;
      }

    useEffect(() => {
        const chipsArray = document.querySelectorAll("#messagesList > li");

        if (chipsArray && chipsArray.length > 0 ) {
            scrollRefs.current = [...Array(chipsArray.length).keys()].map(
                (_, i) => scrollRefs.current[i] ?? createRef()
            );

            
            
            scrollSmoothHandler(chipsArray.length - 1);
        }
    });

    return (
        <ul id="messagesList">
            {props.Chats !== undefined && props.Chats.chats.map((chat, i) => (
                <li key={chat.chatId} onClick={() => handleClick(chat)} ref={ref => { scrollRefs.current[i] = ref }}>
                    {chat.userid !== "0" &&
                        <Row>
                            <Col md="7">
                                <Row>
                                    <Col md="2">

                                        <Avatar name={chat.lastMessagowner} size="50" round />


                                    </Col>
                                    <Col md="10">
                                        <Row className="cardmessage">
                                            <Label className="lastMessagowner">{chat.lastMessagowner}</Label>
                                        <p className="msgfrom">{chat.lastMessage}</p>
                                        </Row>
                                        <Row>
                                            <span>{convertUTCTimeToLocalTime(chat.dateTime)}</span>
                                        </Row>

                                    </Col>


                                </Row>
                            </Col>
                            <Col md="5">
                            </Col>
                        </Row>
                    }
                    {/*CUANDO EL USERID SEA IGUAL AL USUARIO LOGUEADO POR EL MOMENTO ESTA EN 0*/}
                    {chat.userid === "0" &&
                        <Row>
                            <Col md="5">
                            </Col>
                            <Col md="7">
                                <Row>

                                    <Col md="10">
                                        <Row className="cardmessageme">

                                            <p className="msgto">{chat.lastMessage}</p>
                                        </Row>
                                        <Row>
                                            <Col md="7"></Col>
                                            <Col md="5"><span>{convertUTCTimeToLocalTime(chat.dateTime)}</span></Col>
                                        </Row>

                                    </Col>

                                    <Col md="2">

                                        <Avatar name={chat.lastMessagowner} size="50" round />


                                    </Col>
                                </Row>

                            </Col>

                        </Row>
                    }
                </li>  
            ))}
        </ul>
        );
}
export default ChatList;