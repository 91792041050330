import React from "react";
import loader from "../../../assets/spinner.gif";
import './Loader.css';

const Loader = () => {

    return(
        <>
            <div className='imgloader'>
                <img src={loader} alt="Loader" />
            </div>
        </>
    );
};

export default Loader;
