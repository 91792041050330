import React, { useContext, useState } from "react";

export const DepartmentContext = React.createContext();

export const UsedDepartmentContext = () => {
    return useContext(DepartmentContext);
  };

const DepartmentProvider = ({ children }) => {

    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const onShowDeleteDialog = (val) => {
        setIsDeleteDialogOpen(val);
      };

    const [incidentId, setIncidentId] = useState(0);
    const onSetIncidentId = (val) => {
        setIncidentId(val);
      };

    const [isSuspendAccountDialogOpen, setIsSuspendAccountDialogOpen] = useState(false);
    const onShowSuspendAccountDialog = (val) => {
      setIsSuspendAccountDialogOpen(val);
      };

    const [userId, setUserId] = useState("");
    const onSetUserId = (val) => {
      setUserId(val);
    }
    
    const props = {
        isDeleteDialogOpen,
        onShowDeleteDialog,
        onSetIncidentId,
        incidentId,
        isSuspendAccountDialogOpen,
        onShowSuspendAccountDialog,
        userId,
        onSetUserId
    };
  
    return (
      <DepartmentContext.Provider value={props}>{children}</DepartmentContext.Provider>
    );
  };
  
  export default DepartmentProvider;
  

