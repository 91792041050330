import React, { useContext, useState } from "react";

export const ReportedPostContext = React.createContext();

export const UsedReportedPostContext = () => {
    return useContext(ReportedPostContext);
};


const ReportedPostProvider = ({children}) => {

    const [isStatusReportDialogOpen, setIsStatusReportDialogOpen] = useState(false);
    const onShowStatusReportDialog = (val) => {
        setIsStatusReportDialogOpen(val);
    };

    const [statusReportId, setStatusReportId] = useState(0);
    const onSetStatusReportId = (val) => {
        setStatusReportId(val);
    };

    const [reportedPostId, setReportedPostId] = useState(0);
    const onSetReportedPostId = (val) => {
        setReportedPostId(val);
    };

    const props = {
        isStatusReportDialogOpen,
        onShowStatusReportDialog,
        statusReportId,
        onSetStatusReportId,
        reportedPostId,
        onSetReportedPostId
    };

    return (
        <ReportedPostContext.Provider value={props}>{children}</ReportedPostContext.Provider>
      );
};

export default ReportedPostProvider;