import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Card, CardBody, CardTitle, Form, FormGroup, FormText, Input } from "reactstrap";
import authService from "../../services/auth.service";


const UserInvitationResponse = () => {
    let history = useHistory();
    const [password, setPassword] = useState();
    const [newPassword, setnewPassword] = useState();
    const [isvalid, setIsvalid] = useState();
    const [errorsResult, seterrorsResult] = useState();
    const [successResult, setsuccessResult] = useState();
    const [validation, setValidation] = useState({
        passwordValidation: "",
        newPasswordVaidation: ""
    });

    const queryParams = new URLSearchParams(window.location.search);
    const userid = queryParams.get('userid');
    const code = queryParams.get('code');

    const onChangePassword = e => {
        setPassword(e.target.value);
    };
    const onChangeNewPassword = e => {
        setnewPassword(e.target.value);
    };

    const handleResetPassword = (e) => {
        e.preventDefault();
        let errors = validation;
        setIsvalid(true);
        // eslint-disable-next-line
        const regex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
        if (!password || regex.test(password) === false) {
            errors.passwordValidation = "This field need format password(miminum 8 letter ( 1 lowercase,uppercase,numeric,special character)!";
            errors.newPasswordVaidation = "";
            setIsvalid(false);
        } else if (password !== newPassword) {
            errors.passwordValidation = "The password does not match";
            errors.newPasswordVaidation = "";
            setIsvalid(false);
        } else {
            errors.passwordValidation = "";
            setIsvalid(true);
           authService.resetPassword({
                UserId: userid,
                Password: password,
                Token: code
            }).then((data) => {
                if (data && data.errors && data.errors !== "") {
                    seterrorsResult(data.errors);
                } else {
                    setPassword("");
                    setnewPassword("");
                    setsuccessResult(true);
                    setTimeout(function () { history.push("/login"); }, 2000);
                }
            });
        }

        setValidation(errors);


    };

    return <Form onSubmit={handleResetPassword}>
        <Card>
            <CardBody>
                <CardTitle>
                    <h3>Add your password.</h3>
                </CardTitle>

                <div>
                    {errorsResult !== undefined && <p className="alert alert-danger">{errorsResult}</p>}
                    {successResult !== undefined && successResult && <p className="alert alert-success">The password was changed successfully!</p>}
                    <FormGroup>
                        <FormText>Password</FormText>
                        <Input value={password} name="password" onChange={onChangePassword ?? ""} placeholder="Password" type="Password"></Input>
                        <br></br>
                        {!isvalid && validation.passwordValidation !== "" && <p className="alert alert-danger">{validation.passwordValidation}</p>}
                    </FormGroup>
                    <FormGroup>
                        <FormText>Confirm password</FormText>
                        <Input onChange={onChangeNewPassword} name="newPassword" value={newPassword ?? ""} placeholder="Confirm password" type="Password"></Input>
                        <br></br>
                        {!isvalid && validation.newPasswordVaidation !== "" && <p className="alert alert-danger">{validation.newPasswordVaidation}</p>}

                    </FormGroup>
                    <br></br>
                    <FormGroup>
                        <Button className="btn btn-primary btn-block">Save Password</Button>
                    </FormGroup>
                </div>

            </CardBody>

        </Card>
    </Form>
}

export default UserInvitationResponse;