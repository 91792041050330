import React, { useState } from "react";
import Form from "react-validation/build/form";
import authService from "../../../../services/auth.service";
import '../Login.css';



const LoginOptions = (props) => {
    const [codeOption, setCodeOption] = useState(0);
    const [loading, setLoading] = useState(false);

    const changeCodeOption = e => {
        setCodeOption(e.target.value === "0" ? 0 : 1);
        props.CodeOptionChanged(e.target.value);
    }

    const handleOptionCode = (e) => {
        setLoading(true);
        var data = {
            Option2FA: codeOption,
            UserName: props.username
        }
        authService.sendCode2FA(data).then(() => {
            props.EnableReqCode();
            setLoading(false);
        });
    }

    return (
        <Form >
            <label htmlFor="username" className="labellogin">Please select and option to get code</label>
            <div className="form-group login">
                <label htmlFor="username" className="labellogin">Code</label>
                <select onChange={changeCodeOption} id="codeOption" value={codeOption} className="form-control">
                    <option value={0}>Email</option>
                    <option value={1}>Phone</option>
                </select>
            </div>
            <div className="form-group login">
                <button type="button"
                    onClick={handleOptionCode}
                    className="btn btn-primary btn-block login-button"
                    disabled={loading}
                >
                    {loading && (
                        <span className="spinner-border spinner-border-sm"></span>
                    )}
                    <span>Send code</span>
                </button>
            </div>
        </Form>
    );
};

export default LoginOptions;