import React from "react";
import { Row, Col, Button } from "reactstrap";
import anychat from "../../../assets/chat_any.png";
import "./Loader.css";
const AnyMessageState = () => {
    return (
        <>
            <Row>
                <img src={anychat} className="anychatimg" />
            </Row>
            <Row>
                <label className="lblnochats">There are no chats here</label>
            </Row>
            <Row>
                <label className="lblsecnochats"> Create your first chat with officer</label>
            </Row>
            {/** Commented this since it has no use**/}
            {/* <Row>
                <Col md="5">
                 </Col>
                <button className="btn btn-primary btncreatechat">+ Create chat</button>
            </Row> */}
        </>
    );
};
export default AnyMessageState;
