import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { NavMenu } from './NavMenu';
import { UserBar } from './UserBar';
import nbcicon from "../assets/nbcicon.png";

export class Layout extends Component {
  static displayName = Layout.name;

  render () {
      return (
        <html lang="en">
    <head>
        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        <meta name="description" content="" />
        <meta name="author" content="" />
        <title>Simple Sidebar - Start Bootstrap Template</title>
        
    </head>
    <body>
                  <div className="d-flex" id="wrapper">
                     
                      <div className="border-end" id="sidebar-wrapper">
                          <div className="logoContainer" >
                            <img  className="logoStyle" src={nbcicon} aria-hidden alt="logo" />
                          </div>
                          <div className="list-group list-group-flush">
                             <NavMenu/>
                          </div>
                      </div>
                     
                      <div id="page-content-wrapper">
                      
                          <nav className="navbar navbar-expand-lg navbar-light bg-light border-bottom">
                              <div className="row rownav">
                                  <div className="col-7">
                                  </div>
                                  <div className="col-5">
                                      <UserBar />
                                  </div>

                              </div>
                          </nav>
                          
                          <div className="container-fluid divprincipal">
                              <Container className="customContainer">
                                  {this.props.children}
                              </Container>
                          </div>
                      </div>
                  </div>
    </body>
</html>
    );
  }
}
