/*eslint unicode-bom: ["error", "always"]*/
import React from "react";
import { format } from 'date-fns'
import './MyPost.css';
import { ModalBody, ModalFooter, ModalHeader } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarDays, faShield } from '@fortawesome/free-solid-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core';
import { Link } from 'react-router-dom';
import AuthService from "../../services/auth.service";
import { Button, Modal, Row } from 'reactstrap';
import api from "../../services/api";
import { NotificationContainer, NotificationManager } from 'react-notifications';

library.add(faCalendarDays, faShield);
const MyCardPost = (props) => {
    const { reloadData } = props;
    const [modalDelete, setModalDelete] = React.useState({
        show: false,
        id: "",
        name: ""
    });
    const [modalPublish, setModalPublish] = React.useState({
        show: false,
        id: "",
        name: ""
    });
    const toggleDelete = (incident) => {
        setModalDelete({
            ...modalDelete,
            show: !modalDelete.show,
            id: incident.id,
            name: incident.incidentNum
        });

    }
    const togglePublish = (incident) => {
        setModalPublish({
            ...modalPublish,
            show: !modalPublish.show,
            id: incident.id,
            name: incident.incidentNum
        });

    }
    const DeletePost = (id) => {

        const formDatadelete = new FormData();

        formDatadelete.append("id", id);

        api.post("/incidents/delete", formDatadelete, AuthService.getToken())
            .then(response => {

                if (response.data) {
                    toggleDelete(false);
                  
                }


            });
    }
    const PublishPost = (id) => {

        const formDataPublish = new FormData();

        formDataPublish.append("id", id);

        api.post("/incidents/publish", formDataPublish, AuthService.getToken())
            .then(response => {

                if (response.data) {
                    reloadData();
                    NotificationManager.success('The post was published', 'Success', 5000);
                    togglePublish(false);

                }


            });
    }
    return (
        <>
           
            <div className="items">
                {props.listpost.length === 0 &&
                    <h2 className="noitems">
                        NO INCIDENTS.
                    </h2>
                }
                {props.listpost.map((item) => (

                    <div key={item.id} className="row">
                        <div className="col-12">
                            <div key={item.id} className="card-post">
                                <div className="row rowimage">
                                    {item.filesIncidents.map((image) => (
                                        <div key={image.id} className="col-md-4">
                                            <img className="card-img-top" src={image.file.path} aria-hidden alt="Card image cap" />

                                        </div>
                                    ))}
                                </div>
                                <div className="card-body">




                                    <div className="row">
                                        <div className="col-4"> <h5 className="card-title">Incident #: {item.incidentNum}</h5>

                                        </div>
                                        <div className="col-4"> <h5 className="card-title">Incident Date: {format(new Date(item.date), 'dd/MM/yyyy HH:mm')}</h5>
                                        </div>
                                        <div className="col-4"> <h5 className="card-title">Incident Type: <FontAwesomeIcon icon={faShield} /> {item.incidentType.name}</h5>

                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-4">

                                        </div>
                                        <div className="col-4"> <h5 className="card-title">Location: {item.address.address1}</h5>
                                        </div>
                                        <div className="col-4">

                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <p className="card-text">{item.description}</p>
                                        </div>
                                    </div>

                                </div>
                               
                               
                                <div className="row">
                                    <div className="col-4">
                                        <Link to={"/post/edit/" + item.id} className="btn btn-default">Edit</Link>
                                    </div>
                                    <div className="col-4">
                                        <a onClick={() => toggleDelete(item)} className="btn btn-default">Delete</a>
                                    </div>
                                  
                                        {item.incidentStatusName == "DRAFT" && (
                                         
                                        
                                        <div className="col-4">
                                            <a onClick={() => togglePublish(item)} className="btn btn-default">Publish</a>
                                        </div>
                                           
                                    )}
                                    {item.incidentStatusName == "POSTED" && (

                                        <div className="col-4">
                                            <Link to={{
                                                pathname: '/',
                                                state: { to: [item.userId], Chat_Id: 0, id: item.id }
                                            }}
                                               className="btn btn-default">Message</Link>
                                        </div>

                                    )}
                                </div>
                                
                            </div>
                        </div>
                    </div>

                ))}

            </div>
            <Modal size="lg" isOpen={modalDelete.show}>
                <ModalHeader>
                    Delete Post
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <label >Are you sure you want to delete this post?</label>
                    </Row>

                </ModalBody>
                <ModalFooter>
                    <Button
                        color="primary"
                        onClick={() => DeletePost(modalDelete.id)}
                    >
                        Delete
                    </Button>
                    {' '}
                    <Button onClick={toggleDelete}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>

            <Modal size="lg" isOpen={modalPublish.show}>
                <ModalHeader>
                    Publish Post
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <label >Are you sure you want to publish this post?</label>
                    </Row>

                </ModalBody>
                <ModalFooter>
                    <Button
                        color="primary"
                        onClick={() => PublishPost(modalPublish.id)}
                    >
                        Publish
                    </Button>
                    {' '}
                    <Button onClick={togglePublish}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
};
export default MyCardPost;