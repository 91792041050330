import React, { useState } from 'react';
import homelogo from "../../assets/home-w.png";
import msglogo from "../../assets/msg-w.png";
import dptlogo from "../../assets/department-w.png";
import accountlogo from "../../assets/account-w.png";
import reportedlogo from "../../assets/reports-w.png";
import officelogo from "../../assets/office-w.png";
import joinlogo from "../../assets/join-w.png";
import { NavItem, NavLink } from 'reactstrap';
import { Link, useHistory } from 'react-router-dom';
import './NavMenuAdmin.css';
import AuthService from "../../services/auth.service";


const NavMenuAdmin = () => {
    const [url,setUrl] = useState(window.location.pathname);
    const [collapsed, setCollapsed] = useState(true);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [dropdownOpenMenu, setDropdownOpenMenu] = useState(false);
    const [isSuper, setIsSuper] = useState(AuthService.isSuperAdmin());
    const  toggle=()=>{
      setDropdownOpen(!dropdownOpen);
    }
    const toggleMenu = () => {
        setDropdownOpenMenu(!dropdownOpenMenu);
    }
    let history = useHistory();
    const toggleNavbar = () => {
        setCollapsed(!collapsed);
    }
    const logout = () => {
        history.push("/");
        history.go(0);
        AuthService.logout();
    }
    const ChangeActive = (url) => {
        setUrl(url);
    }
    let isLoggedIn = AuthService.islogged();
    return (
    <div>
                
               
        
              
               
                        {isLoggedIn ? (
                <ul className="ulnav">
                    <NavItem  className={`navbar-tab ${url == "/home" || url == "/" ? "active" : ""}`} onClick={() => ChangeActive("/home")}>
                        <NavLink tag={Link} className="text-dark navlink" to="/home"> <img src={homelogo} className="iconsnav" />  <span className="navtext">Home</span></NavLink>

                    </NavItem>
                    <NavItem  className={`navbar-tab ${url == "/messages" ? "active" : ""}`} onClick={() => ChangeActive("/messages")}>
                        <NavLink tag={Link} className="text-dark navlink" to="/messages"> <img src={msglogo} className="iconsnav" />  <span className="navtext">Message</span></NavLink>

                    </NavItem>
                    <NavItem  className={`navbar-tab ${url == "/agencies" ? "active" : ""}`} onClick={() => ChangeActive("/agencies")}>
                        <NavLink tag={Link} className="text-dark navlink" to="/department"> <img src={dptlogo} className="iconsnav" />  <span className="navtext">My Departments</span></NavLink>

                    </NavItem>
                    <NavItem  className={`navbar-tab ${url == "/officers" ? "active" : ""}`} onClick={() => ChangeActive("/officers")}>
                        <NavLink tag={Link} className="text-dark navlink" to="/officers"> <img src={accountlogo} className="iconsnav" /><span className="navtext">Accounts</span></NavLink>
                    </NavItem>
                    <NavItem  className={`navbar-tab ${url == "/requests" ? "active" : ""}`} onClick={() => ChangeActive("/requests")}>
                        <NavLink tag={Link} className="text-dark navlink" to="/requests"> <img src={joinlogo} className="iconsnav" /><span className="navtext">Join requests</span></NavLink>
                    </NavItem>
                    <NavItem className={`navbar-tab ${url == "/reportedpost" ? "active" : ""}`} onClick={() => ChangeActive("/reportedpost")}>
                        <NavLink tag={Link} className="text-dark navlink" to="/reportedpost"> <img src={reportedlogo} className="iconsnav" /><span className="navtext">Reported Posts</span></NavLink>
                    </NavItem>
                    <NavItem className={`navbar-tab ${url == "/manageagencies" ? "active" : ""}`} onClick={() => ChangeActive("/manageagencies")}>
                        <NavLink tag={Link} className="text-dark navlink" to="/manageagencies"> <img src={officelogo} className="iconsnav" /><span className="navtext">Agencies</span></NavLink>
                    </NavItem>

                </ul>
                        ) : (
                            <ul className="navbar-nav flex-grow">
                                <NavItem className='noborder'>
                            <NavLink tag={Link} className="text-dark navlink" to="/login">Login</NavLink>
                                    </NavItem>
                                    </ul>
                            )}
                
             
         
        </div>
    );
}
export default NavMenuAdmin;
