import axios from "axios";
import api from "../../src/services/api";
import { withRouter } from "react-router";
const API_URL = "/authenticate/";
let currentAppIdKey = Math.trunc(Math.random() * (10000 - 1 + 1));
class AuthService {
  getKeyHub() {
    return currentAppIdKey;
  }

  islogged() {
    if (localStorage.getItem("user") == null) {
      return false;
    } else {
      return true;
    }
  }

  login(username, password) {
    return axios
      .post(API_URL + "login", {
        Username: username,
        Password: password,
      })
      .then((response) => {
        console.log("r=>" + response);
        if (response.data.token) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }

        return response.data;
      });
  }

  login2FA(data) {
    return axios.post(API_URL + `login2Fa`, data).then((response) => {
      console.log("r=>" + response);
      if (response.data.token) {
        localStorage.setItem("user", JSON.stringify(response.data));
      }

      return response.data;
    });
  }

  sendCode2FA(model) {
    return axios.post(API_URL + `sendCode2fa`, model).then((response) => {
      return response.data;
    });
  }

  resetPasswordExpiration(userId) {
    return axios
      .get(API_URL + `/generateResetPasswordUrl?&userId=${userId}`)
      .then((response) => {
        return response;
      });
  }

  logout() {
    localStorage.removeItem("user");
  }

  register(username, email, password) {
    return axios.post(API_URL + "signup", {
      username,
      email,
      password,
    });
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem("user"));
  }

  getToken() {
    var userData = JSON.parse(localStorage.getItem("user"));
    return {
      headers: {
        Authorization: `Bearer ${userData.token}`,
        "Content-Type": "multipart/form-data",
      },
    };
  }
  getTokenPost() {
    var userData = JSON.parse(localStorage.getItem("user"));
    return {
      headers: {
        Authorization: `Bearer ${userData.token}`,
        "Content-Type": "application/json",
      },
    };
  }

  getTokenJson() {
    var userData = JSON.parse(localStorage.getItem("user"));
    return {
      headers: {
        Authorization: `Bearer ${userData.token}`,
        "Content-Type": "application/json",
      },
    };
  }

  forgotPassword(email) {
    return axios
      .post(
        API_URL + "forgotpassword",
        { Email: email },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        return response.data;
      });
  }

  resetPassword(resetInfo) {
    return axios.post(API_URL + "resetpassword", resetInfo).then((response) => {
      return response.data;
    });
  }

  getRoles() {
    var user = this.getCurrentUser();
    return user !== null && user.roles !== undefined && user.roles !== ""
      ? user.roles.split(",")
      : [];
  }

  isSuperAdmin() {
    var roles = this.getRoles();

    return roles.includes("SuperAdmin");
  }

  isAdmin() {
    var roles = this.getRoles();

    return roles.includes("Admin");
  }

  getId() {
    var user = this.getCurrentUser();
    return user !== null && user.roles !== undefined ? user.id : "";
  }
}

export default new AuthService();
