/*eslint unicode-bom: ["error", "always"]*/
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCalendarDays, faShield } from '@fortawesome/free-solid-svg-icons';
import React, { useEffect, useRef, useState } from "react";
import { Col, Nav, Row } from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import { NotificationContainer } from 'react-notifications';
import { useHistory } from 'react-router-dom';
import api from "../../services/api";
import AuthService from "../../services/auth.service";
import ChatPanel from "../Chat/ChatPanel";
import CardPost from "./CardPost";
import { useParams } from 'react-router-dom';
import { GridOfficerSharePost } from '../Officer';
import './GridPost.css';

library.add(faCalendarDays, faShield);
const PostInfo = (props) => {
    let history = useHistory();
    const { id } = useParams();
    const [userlogged, setUserLogged] = useState([]);
    const [agenciesuser, setAgenciesUser] = useState([]);
    const [listPost, setLispost] = useState([]);
    const [openCreatePost, setOpenCreatePost] = useState(false);
    const [listfolders, setListFolders] = useState([]);
    const [notficationPost, setNotficationPost] = useState({});
    const [smsNotification, setSmsNotification] = useState(false);
    const [emailNotification, setEmailNotification] = useState(false);
    const [loadingPosts, setLoadingPosts] = useState(true);

    const [modalDelete, setModalDelete] = useState({
        show: false,
        id: "",
        name: ""
    });
    const toggleDelete = (incident) => {
        setModalDelete({
            ...modalDelete,
            show: !modalDelete.show,
            id: incident.id,
            name: incident.incidentNum
        });

    }

    const DeletePost = (id) => {

        const formDatadelete = new FormData();

        formDatadelete.append("id", id);

        api.post("/incidents/delete", formDatadelete, AuthService.getToken())
            .then(response => {

                if (response.data) {
                    toggleDelete(false);

                }


            });
    }

    const [chatMessage, setChatMessage] = useState({});

    const messageNavItem = useRef(null);
    const [currentTab, setCurrentTab] = useState("first");


    const handleSelect = (eventKey) => {
        setChatMessage({
            From: "",
            To: [eventKey.userId],
            Message: "",
            Chat_Id: 0,
            IncidentNumber: eventKey.incidentNum,
            IncidentId: eventKey.id,
            chat: [],
            Chat_Id: eventKey.Chat_Id
        });
        setCurrentTab("five");
    };

    if (props !== undefined && props.location !== undefined && props.location.state !== undefined && currentTab !== "five") {
        handleSelect(props.location.state);
        setCurrentTab("five");

    }

    const RedirectPostCreate = (event) => {
        history.push("/post/create");
    };

    const openMessage = () => {
        messageNavItem.click();
    }
    const SearchPostRelated = (item) => {
        if (item == null) {
            item = { id: 0 }
        }
        api.get(
            "/releatedcrimes/IncidentsFolder?folderid=" + item.id
            , AuthService.getToken())
            .then((response) => {
                console.log(response);
             
            })
            .catch((error) => {
                console.log(error);
            })
    }
    const ReloadRelated = () => {
        ListFolder();
        GetIncidentsFolders();
    }
    const ListFolder = () => {
        api.get(
            "/releatedcrimes/folders"
            , AuthService.getToken())
            .then((response) => {
                console.log(response);
                setListFolders(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }
   
    useEffect(() => {
        api.get("/agency/myagencies", AuthService.getTokenJson())
            .then((response) => {
                console.log(response.data);
                setAgenciesUser(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }, [setAgenciesUser]);
   

  

    //Modals

    const [folderModal, setFolderModal] = useState(false);
    const [folderModalData, setFolderModalData] = useState();
    const [relateCrimeModal, setRelateCrimeModal] = useState(false);
    const [relateCrimeData, SetRelateCrimeData] = useState({});
    const [sendNotificationModal, setSendNotificationModal] = useState(false);
    const closeFolders = (cleanData) => {

        setFolderModal(false);

        if (cleanData === undefined)
            setFolderModalData(undefined);


    }
    const closeRelateCrime = () => {
        setRelateCrimeModal(false);
    }

    const saveRelateCrime = () => {
        setRelateCrimeModal(false);
    }
    const GetIncidentsFolders = () => {
        SearchPostRelated(null);
    }
    const OpenRelateCrime = (item) => {
        SetRelateCrimeData(item);
        setFolderModal(false);
        setRelateCrimeModal(true);
    }

    const OpenFolders = (e, item) => {
        e.preventDefault();
        setFolderModal(true);
        setFolderModalData(item);
    }

    const SetNotification = (incident) => {
        setNotficationPost(incident);
        setSendNotificationModal(true);
        getSettingsNotification(incident);
    }


    const closeNotificationModal = () => {
        setSendNotificationModal(false);

    }
    const SearchPost = () => {
        console.log("reload");
    }

    const getSettingsNotification = (incident) => {
        api.get("/incidents/getIncidentNotification?incident=" + incident.id, AuthService.getToken())
            .then((result) => {
                if (result && result.data !== "") {
                    setSmsNotification(result.data.sms);
                    setEmailNotification(result.data.email);
                } else {
                    setSmsNotification(false);
                    setEmailNotification(false);
                }

            });
    }

    const SendDataNotification = () => {
        api.post("/incidents/setNotification", { IncidentId: notficationPost.id, Sms: smsNotification, Email: emailNotification }, AuthService.getTokenPost())
            .then(response => {
            });
        setSendNotificationModal(false);

    }

    const OnsmsNotificationChange = (e) => {
        setSmsNotification(e.target.checked);
    }

    const OnemailNotificationChange = (e) => {
        setEmailNotification(e.target.checked);
    }

    //my post

    const [isLoading, setIsLoading] = useState(true);
    const [myPosts, setMyPost] = useState([]);
    const SearchMyPost = (x, y, z, draft, published, removed) => {
        if (x == undefined) {
            x = "";
        }
        if (y == undefined) {
            y = "";
        }
        if (z == undefined) {
            z = "";
        }
        if (draft == undefined) {
            draft = true;
        }
        if (published == undefined) {
            published = true;
        }
        if (removed == undefined) {
            removed = true;
        }
        setIsLoading(true);
        api.get(
            "/incidents/mypost?dstart=" + x + "&dend=" + y + "&search=" + z + "&draft=" + draft + "&published=" + published + "&removed=" + removed
        )
            .then((response) => {
                let listfeed = [];
                console.log(response);
                response.data.forEach((element) => {

                    listfeed.push(element);
                });
                setMyPost(listfeed);
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error);
            });
    }

    useEffect(() => {
        SearchMyPost();
        ListFolder();
    }, [setMyPost, setListFolders]);

    // my department posts 

    const [myDepartMentPosts, setMyDepartmentPost] = useState([]);

    const SearchMyDepartMenPost = (x, y, z, draft, published, removed) => {
        if (x == undefined) {
            x = "";
        }
        if (y == undefined) {
            y = "";
        }
        if (z == undefined) {
            z = "";
        }
        if (draft == undefined) {
            draft = true;
        }
        if (published == undefined) {
            published = true;
        }
        if (removed == undefined) {
            removed = true;
        }
        setIsLoading(true);
        api.get(
            "/incidents/incidentsdepartment?dstart=" + x + "&dend=" + y + "&search=" + z + "&draft=" + draft + "&published=" + published + "&removed=" + removed
        )
            .then((response) => {
                let listMyDepartment = [];
                console.log(response);
                response.data.forEach((element) => {

                    listMyDepartment.push(element);
                });
                setMyDepartmentPost(listMyDepartment);
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error);
            });
    }
    useEffect(() => {
        api.get("/agency/myagencies", AuthService.getTokenJson())
            .then((response) => {
                console.log(response.data);
                setAgenciesUser(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }, [setAgenciesUser]);
    useEffect(() => {
        let userauth = AuthService.getCurrentUser();
        setUserLogged(userauth);
        console.log(userauth)
    }, [setUserLogged]);
    useEffect(() => {
    
        api.get("/incidents/byid?id=" + id, AuthService.getTokenJson())
            .then((response) => {
                
                
                setLispost(response.data);
                console.log(response);
                setLoadingPosts(false);
            })
            .catch((error) => {
                console.log(error);
            });
        
    }, [setLispost]);
    return (
        <>
            <Row>
                <Col>
                                    
                    <CardPost isinfo={true} setLoadingPosts={setLoadingPosts} loadingPosts={loadingPosts} SetNotfication={SetNotification} Key={"All"} DeleteIncident={toggleDelete}  OpenFolders={OpenFolders} listagenciesid={agenciesuser} openMessage={handleSelect} reloadData={SearchPost} listpost={listPost}></CardPost>
                                
                          
                    </Col>
                    
                </Row>
       
            <NotificationContainer />

        </>
    );
};
export default PostInfo;
