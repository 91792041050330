/*eslint unicode-bom: ["error", "always"]*/
import React, { useState, useEffect } from "react";
import {  useHistory } from 'react-router-dom';
import Pagination from "../../components/Common/pagination";
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import './GridAccount.css';
import Avatar from 'react-avatar';
import AuthService from "../../services/auth.service";
import authService from "../../services/auth.service";
import api from "../../services/api";
import ModalProfileCard from '../Profile/ModalProfileCard';
import { ModalBody, ModalFooter, ModalHeader } from "react-bootstrap";
import { PopoverBody, UncontrolledPopover,Button, Modal, Row, Col } from 'reactstrap';
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dotsMore from "../../assets/dotsMore.png";
import iconexport from "../../assets/export.png";
import OptionAccount from './OptionAccount';
import { ModalAccount } from "../../components/Officer";
import {
    NoResultFoundContainer,
    PostShimmeringEffect,
} from "../../components/Department/Department.Style";
import exportFromJSON from 'export-from-json';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import StatusBadge from "./StatusBadge";

library.add(faEdit, faTrash, faSearch);

const GridAccount = (props) => {
    let history = useHistory();
    const fileName = 'download'
    const exportType = 'xls'

    // eslint-disable-next-line no-unused-vars
    const [currentUser, setCurrentUser] = useState(AuthService.getCurrentUser());
    let currentUserAgencyId = 0;
    currentUserAgencyId = currentUser.agencyId;

    let superAdminAllowedSuspend = false;
    let allowedSuspend = true;
    let isSuperAdmin = false;
    // eslint-disable-next-line no-unused-vars
    const [userRoles, setUserRoles] = useState(AuthService.getRoles());
    if (userRoles.length > 0 && userRoles.includes("SuperAdmin")) {
        superAdminAllowedSuspend = true;
        isSuperAdmin = true;
    }

    const [division, setDivision] = useState("");
    const [status, setStatus] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [isExportListLoading, setIsExportListLoading] = useState(false);
    const [modalInformation, setModalInformation] = useState(false);
    const [departament, setDepartament] = useState("");

    // eslint-disable-next-line no-unused-vars
    const [selecteduser, setSelectedUser] = useState({});
    const [search, setSearch] = useState("");
    const [list, setList] = useState([]);
    const [listdepartaments, setListDepartaments] = useState([]);
    const [liststatuses, setListStatuses] = useState([]);
    const [openAccountModal, setOpenAccountModal] = useState(false);
    const [dataAccount, setDataAccount] = useState({});
    const [isDeleteExecuted, setIsDeleteExecuted] = useState(false);
    const [isSuspendAccountExecuted, setIsSuspendAccountExecuted] = useState(false);
    const [isUnSuspendAccountExecuted, setIsUnSuspendAccountExecuted] = useState(false);
    const [isRestoreExecuted, setIsRestoreExecuted] = useState(false);

    const [isAfterCreateEditOfficer, setIsAfterCreateEditOfficer]= useState(false);
    const [modalDelete, setModalDelete] = useState({
        show: false,
        id: "",
        name: ""
    });
    const [modalSuspend, setModalSuspend] = useState({
        show: false,
        id: "",
        name: ""
    });
    const [modalUnSuspend, setModalUnSuspend] = useState({
        show: false,
        id: "",
        name: ""
    });
    const [modalRestoreAccount, setModalRestoreAccount] = useState({
        show: false,
        id: "",
        name: ""
    });
    const ChangeDivision = (e) => {
        setDivision(e.target.value);
        getData(currentPage, e.target.value, departament, status, search).then((info) => {
            const { totalPages, data, totalData } = info;
            setPageData({
                isLoading: false,
                rowData: data,
                totalPages,
                totalData,
            });
        });

    }
    const RedirectMessage = (state, userId, name, type, urlPic,phone, email, address, typechat) => {
        if (state != null && (state === "Suspended" || state === "Removed")) {
            NotificationManager.info('Information', 'The account cannot be contacted because it was deleted or suspended.', 5000);
            return;
        }

        let img = urlPic;
        if (props.Incident !== undefined && (props.Incident.filesIncidents != null && props.Incident.filesIncidents !== undefined && props.Incident.filesIncidents.length > 0)) {
            img = props.Incident.filesIncidents[0].file.path;
        }

        history.push({
            pathname: `/messages`,
            search: `?userId=${userId}&name=${name}&type=${props.Incident !== undefined ? props.Incident.type : type}&typechat=${typechat}&incident=${props.Incident !== undefined ? props.Incident.id : ""}&address=${props.Incident !== undefined ? props.Incident.address : ""}`,
            state: {
                urlPic: img,
                userId: userId,
                name: name,
                type: (props.Incident !== undefined ? props.Incident.type : type),
                typechat: typechat,
                incident: props.Incident !== undefined ? props.Incident.id : "",
                address: props.Incident !== undefined ? props.Incident.address : "",
                phone: phone,
                email: email,
                userAddress: address
            }

        });

    }
    const toggleDelete = (account) => {
        setModalDelete({
            ...modalDelete,
            show: !modalDelete.show,
            id: account.id,
            name: account.name
        });

    }

    const toggleSuspend = (account) => {
        setModalSuspend({
            ...modalSuspend,
            show: !modalSuspend.show,
            id: account.id,
            name: account.name
        });

    }

    const toggleUnSuspend = (account) => {
        setModalUnSuspend({
            ...modalUnSuspend,
            show: !modalUnSuspend.show,
            id: account.id,
            name: account.name
        });

    }

    const toggleRestoreAccount = (account) => {
        setModalRestoreAccount({
            ...modalRestoreAccount,
            show: !modalRestoreAccount.show,
            id: account.id,
            name: account.name
        });

    }

    const DeleteAccount = (id) => {

        const formDatadelete = new FormData();

        formDatadelete.append("id", id);

        api.post("/account/delete", formDatadelete, AuthService.getToken())
            .then(response => {
                setIsDeleteExecuted(true);
                if (response.data) {
                    toggleDelete(false);
                    NotificationManager.success('Success', 'THE ACCOUNT WAS DELETED', 5000);

                }


            });
       
    }
    const SuspendAccount = (id) => {

        const formDataSuspend = new FormData();

        formDataSuspend.append("id", id);

        api.post("/account/suspend", formDataSuspend, AuthService.getToken())
            .then(response => {
                setIsSuspendAccountExecuted(true);
                if (response.data) {
                    toggleSuspend(false);
                    NotificationManager.success('Success', 'THE ACCOUNT WAS SUSPENDED', 5000);

                }
            });
    }
    const UnSuspendAccount = (id) => {

        const formDataUnSuspend = new FormData();

        formDataUnSuspend.append("id", id);

        api.post("/account/unsuspend", formDataUnSuspend, AuthService.getToken())
            .then(response => {
                setIsUnSuspendAccountExecuted(true);
                if (response.data) {
                    toggleUnSuspend(false);
                    NotificationManager.success('Success', 'THE ACCOUNT WAS UNSUSPENDED', 5000);

                }
            });
    }

    const RestoreAccount = (id) => {

        const formDataRestore = new FormData();

        formDataRestore.append("id", id);

        api.post("/account/restore", formDataRestore, AuthService.getToken())
            .then(response => {
                setIsRestoreExecuted(true);
                if (response.data) {
                    toggleRestoreAccount(false);
                    NotificationManager.success('Success', 'THE ACCOUNT WAS RESTORED', 5000);
                }

            });
    }


    const EditFunction = data => {
        setOpenAccountModal(true);
        setDataAccount({...data, isView: false});
    }

    const ViewFunction = data => {
        setOpenAccountModal(true);
        setDataAccount({...data, isView: true});
    }

    const AddOfficer = data => {
        setDataAccount({});
        setOpenAccountModal(true);

    }
    const CloseModal = () => {
        setOpenAccountModal(false);
    }

    const [isExecuteSearchPost, setIsExecuteSearchPost] = useState(false);
    const [isTextSearchExecuted, setIsTextSearchExecuted] = useState(false);

    const ChangeSearch = (e) => {
        setSearch(e.target.value);
        setIsTextSearchExecuted(true);
        setIsExecuteSearchPost(false);
    }

      //Set some delay when typing
      useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            setIsExecuteSearchPost(false);
            setIsExecuteSearchPost(true);
        }, 1500)
    
        return () => clearTimeout(delayDebounceFn)
      }, [search])

      useEffect(() => {
        if(isExecuteSearchPost === true && isTextSearchExecuted === true) {
            reloadData();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[isExecuteSearchPost,isTextSearchExecuted,isDeleteExecuted])


    useEffect(() => {
        if(isDeleteExecuted) {
            setIsDeleteExecuted(false);
            reloadData();
        }
    },[isDeleteExecuted]);

    useEffect(() => {
        if(isSuspendAccountExecuted) {
            setIsSuspendAccountExecuted(false);
            reloadData();
        }
    },[isSuspendAccountExecuted]);

    useEffect(() => {
        if(isUnSuspendAccountExecuted) {
            setIsUnSuspendAccountExecuted(false);
            reloadData();
        }
    },[isUnSuspendAccountExecuted]);

    useEffect(() => {
        if(isRestoreExecuted) {
            setIsRestoreExecuted(false);
            reloadData();
        }
    },[isRestoreExecuted]);

    useEffect(() => {
        if(isAfterCreateEditOfficer) {
            setIsAfterCreateEditOfficer(false);
            reloadData();
            setDataAccount({});
        }
    },[isAfterCreateEditOfficer] );

    const reloadData = () => {
        setIsLoading(true);


        getData(currentPage, division, departament, status, search).then((info) => {
            const { totalPages, data, totalData } = info;
            setPageData({
                isLoading: false,
                rowData: data,
                totalPages,
                totalData,
            });
            setIsLoading(false);
        });
    }
    const toggleModal = () => {
        setModalInformation(!modalInformation);
    };
    const ChangeDepartament = (e) => {
        setDepartament(e.target.value);
        setIsLoading(true);
        getData(currentPage, division, e.target.value, status, search).then((info) => {
            const { totalPages, data, totalData } = info;
            setPageData({
                isLoading: false,
                rowData: data,
                totalPages,
                totalData,
            });
            setIsLoading(false);
        });

    }
    const ChangeStatus = (e) => {
        setStatus(e.target.value);
        setIsLoading(true);
        getData(currentPage, division, departament, e.target.value, search).then((info) => {
            const { totalPages, data, totalData } = info;
            setPageData({
                isLoading: false,
                rowData: data,
                totalPages,
                totalData,
            });
            setIsLoading(false);
        });

    }

    
    
    const getData = async(pageNo = 1, division = "", departament = "", status ="", search = "") => {
        const response = await api.get(
            `/officer/account/get?page=${pageNo}&take=10&division=${division}&departament=${departament}&status=${status}&search=${search}`
            , AuthService.getToken());
        return response.data;

    };

    const AfterCreateEditOfficer = (isSuccess, isEdit) => {
        let _msgInfoSuccess = isEdit ? 'OFFICER INFORMATION UPDATED' : 'NEW OFFICER CREATED';
        let _msgInfoError = isEdit ? 'ERROR OCCURS WHEN OFFICER INFORMATION UPDATED.' : 'ERROR OCCURS WHEN OFFICER INFORMATION CREATED';
        CloseModal();
        if(isSuccess) {
            NotificationManager.success('Success', _msgInfoSuccess, 5000);
        } else {
            NotificationManager.error(_msgInfoError, 5000);
        }
        setIsAfterCreateEditOfficer(true);
    }


    const ExportToExcel = async () => {

        setIsExportListLoading(true);
        await api.get(
            `/officer/getexportlist?division=${division}&department=${departament}&status=${status}&search=${search}`
            , AuthService.getToken())
            .then(response => {
                let data = response.data.data;
                exportFromJSON({ data, fileName, exportType })
            });
            ;
        setIsExportListLoading(false);
    }


    const [pageData, setPageData] = useState({
        rowData: [],
        isLoading: false,
        totalPages: 0,
        totalData: 0,
    });
    const [currentPage, setCurrentPage] = useState(1);
    useEffect(() => {
        setPageData((prevState) => ({
            ...prevState,
            rowData: [],
            isLoading: true,
        }));
        setIsLoading(true);
        getData(currentPage).then((info) => {
            const { totalPages, data, totalData } = info;
            
            setPageData({
                isLoading: false,
                rowData: data,
                totalPages,
                totalData,
            });
            setIsLoading(false);
        });
    }, [currentPage]);
    useEffect(() => {
        api.get(
            "/officer/divisions",
            authService.getToken())
            .then((response) => {
                setList(response.data);
            })
            .catch((error) => {
                console.log(error);
            });

          
            api.get(
                "/officer/departaments"
                , authService.getToken())
                .then((response) => {
                    setListDepartaments(response.data);
                })
                .catch((error) => {
                    console.log(error);
                });
           
      

        api.get(
            "/officer/statuses"
            , authService.getToken())
            .then((response) => {
                let _data = response.data
                _data.splice(1, 0, {id: -1, name: "Removed"});
                var _newData = _data.filter(item => item.id !== 1); //Remove suspended on the selection
                setListStatuses(_newData);
            })
            .catch((error) => {
                console.log(error);
            });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setList, setListDepartaments, setListStatuses]);
    return (
        <>
            <div>
                <div className="row">
                    <div className="col-10"> 
                        <h3 className="legendpost">Accounts</h3>
                    </div>
                    {isExportListLoading && (
                    <div id="exportButtonContainer" className="col-2"  >
                        <label className="lblexport" >Exporting list...&nbsp;</label> 
                        <span className="spinner-border spinner-border-sm"></span>
                    </div>)}
                    {!isExportListLoading && (
                    <div id="exportButtonContainer" className="col-2" onClick={ExportToExcel} >
                        <label className="lblexport" >Export list &nbsp;</label> 
                        <img alt="" src={iconexport} className="iconexport" />
                    </div>)}
                </div>
                <div className="row rowaccount">
                    {listdepartaments.length > 1 &&
                        <div className="col-3">

                            <div className="input-group mb-5">


                                <select className="form-control" onChange={ChangeDepartament} value={departament} >
                                    <option value="">All Departments</option>
                                    {listdepartaments.map((item) => (
                                        <option key={item.id} value={item.id}>{item.name}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    }
                    <div className="col-2">

                        <div className="input-group mb-5">


                            <select className="form-control" onChange={ChangeDivision} value={division}>
                                <option value="">All Divisions</option>
                                {list.map((item) => (
                                    <option key={item.id} value={item.id}>{item.name}</option>
                                ))}

                            </select>
                        </div>
                    </div>
                    <div className="col-2">

                        <div className="input-group mb-5">


                            <select className="form-control" onChange={ChangeStatus} value={status}>
                                <option value="">All Statuses</option>
                                {liststatuses.map((item) => (
                                    <option key={item.id} value={item.id}>{item.name}</option>
                                ))}

                            </select>
                        </div>
                    </div>

                    <div className="col-3">


                        <div className="input-group mb-12">
                            <span className="input-group-prepend">
                                <div className="input-group-text  border-right-0"> <FontAwesomeIcon icon={faSearch} /></div>
                            </span>
                            <input type="text" className="form-control" placeholder="Search officers..." onChange={ChangeSearch} value={search} />

                            <span className="input-group-append">

                            </span>
                        </div>
                    </div>
                    <div className="col-2">
                        <button className="btn btn-primary" onClick={AddOfficer}>+ Add Officer</button>
                    </div>


                </div>

                <div>
                    <div className="row">
                        {isLoading &&

                            <div>
                                <div style={{ marginBottom: "25px" }}><PostShimmeringEffect /></div>
                                <div style={{ marginBottom: "25px" }}><PostShimmeringEffect /></div>
                            </div>

                        }
                        
                        {!isLoading &&
                        <>
                        {pageData.rowData.map((item) => (
                            <div className="col-6" key={['idoffice', item.id]}>
                                <div className="row  card-account">

                                    <div className="col-3 card no-mgr">
                                        <div className="row">
                                            {item.urlPic &&
                                                <Avatar src={item.urlPic} size="80" round className="avataraccountimg" />

                                            }
                                            {!item.urlPic &&
                                                <Avatar name={item.name} size="80" round className="avataraccounttext" />

                                            }
                                        </div>
                                        <div className="row" style={{flex: "1", alignItems:"center", justifyContent:"center", fontFamily: "Work Sans"}}>
                                            <button type="button" 
                                                    disabled={item.deleted ? true : item.statusApp === "Suspended" ? true : false}
                                                    className="btn btn-outline-primary btn-lg btn-bloc col-lg-10" 
                                                    onClick={() => RedirectMessage(item.statusApp,item.id, item.name,item.division,item.urlPic,item.phone, item.email, item.address, "user")}>
                                                <div style={{fontSize: "14px"}}>Message</div>
                                            </button>
                                        </div>
                                    </div>

                                    <div className="col-8 card bg-white no-mgl">
                                        {/**
                                         * Just return the value to "2" when statusApp is Suspended
                                         * Currently, as instructed, even if the status is Suspended it should shows "Remove" status
                                         * **/}
                                        <div className="row" style={{textAlign: "right", paddingRight: "15px"}}><span>{StatusBadge(item.deleted ? 3 : item.statusApp === "Suspended" ? 3 : 1)}</span></div>
                                        <div className="row">
                                            <div className="col-6"> <label className="titlename">{item.name}</label></div>
                                            <div className="col-4"></div>
                                            <div className="col-2">
                                            <button id={`but${item.id}`} className="btn btn-default btnMore"><img alt="" src={dotsMore} /></button>
                                            <UncontrolledPopover
                                                trigger="legacy"
                                                placement="bottom"
                                                target={`but${item.id}`}
                                            >
                                                {
                                                    !superAdminAllowedSuspend ? allowedSuspend = false : allowedSuspend = true
                                                }
                                                <PopoverBody>
                                                    <OptionAccount 
                                                        data={item} 
                                                        EditFunction={EditFunction} 
                                                        ViewFunction={ViewFunction} 
                                                        DeleteFunction={toggleDelete}
                                                        SuspendFunction={toggleSuspend} 
                                                        UnSuspendFunction={toggleUnSuspend}
                                                        RestoreAccount={toggleRestoreAccount} 
                                                        AllowedSuspend={allowedSuspend} >
                                                    </OptionAccount>
                                                </PopoverBody>
                                            </UncontrolledPopover>
                                        </div></div>
                        
                                        <div className="row">  <label className="lblinfo">Division:  <span className="txtinfo">{item.division}</span></label></div>
                                        <div className="row">  <label className="lblinfo">Department:  <span className="txtinfo">{item.department}</span></label></div>
                                        <div className="row">   <label className="lblinfo">Phone:   <span className="txtinfo"><a href={`tel:${item.phone ?? ""}`}>{item.phone}</a></span></label></div>
                                        <div className="row">  <label className="lblinfo">Email: <span className="txtinfo"><a href={`mailto:${item.email}`}>{item.email}</a></span></label> </div>
                                        <div className="row">  <label className="lblinfo">Address: <span className="txtinfo">{item.address}</span></label> </div>




                                    </div>
                                </div>
                            </div>
                        ))}

                        {pageData.totalData === 0 &&

                                <NoResultFoundContainer message={"No results found. Try another keywords..."} />
                            }
                            </>
                        }
                    </div>
                </div>
                <Pagination
                    totalRows={pageData.totalData}
                    pageChangeHandler={setCurrentPage}
                    rowsPerPage={10}
                />
            </div>
            <Modal size="lg" isOpen={modalInformation} className="modalprofile">
                <ModalBody>
                    <Row>

                        <Col sm="12" className="containerprofile">
                            <ModalProfileCard user={selecteduser} ></ModalProfileCard>
                        </Col>

                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={toggleModal}>
                        Close
                    </Button>
                </ModalFooter>
            </Modal>
            <Modal size="md" isOpen={openAccountModal}>
                <ModalAccount reloadData={reloadData} account={dataAccount} departaments={listdepartaments} divisions={list} closeModal={CloseModal} AfterCreateEditOfficer={AfterCreateEditOfficer} />
            </Modal>
            <Modal size="md" isOpen={modalDelete.show}>
                <ModalHeader>
                    Delete Account
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <label >Are you sure you want to delete this account?</label>
                    </Row>

                </ModalBody>
                <ModalFooter>
                    <Button
                        color="primary"
                        onClick={() => DeleteAccount(modalDelete.id)}
                    >
                        Delete
                    </Button>
                    {' '}
                    <Button onClick={toggleDelete}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
            <Modal size="md" isOpen={modalSuspend.show}>
                <ModalHeader>
                    Suspend Account
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <label >Are you sure you want to suspend this account?</label>
                    </Row>

                </ModalBody>
                <ModalFooter>
                    <Button
                        color="primary"
                        onClick={() => SuspendAccount(modalSuspend.id)}
                    >
                        Suspend
                    </Button>
                    {' '}
                    <Button onClick={toggleSuspend}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
            <Modal size="md" isOpen={modalUnSuspend.show}>
                <ModalHeader>
                    Unsuspend Account
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <label >Are you sure you want to unsuspend this account?</label>
                    </Row>

                </ModalBody>
                <ModalFooter>
                    <Button
                        color="primary"
                        onClick={() => UnSuspendAccount(modalUnSuspend.id)}
                    >
                        Unsuspend
                    </Button>
                    {' '}
                    <Button onClick={toggleUnSuspend}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>

           

            <Modal size="md" isOpen={modalRestoreAccount.show}>
                <ModalHeader>
                    Restore User
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <label >Are you sure you want to restore this account?</label>
                    </Row>

                </ModalBody>
                <ModalFooter>
                    <Button
                        color="primary"
                        onClick={() => RestoreAccount(modalRestoreAccount.id)}
                    >
                        Restore
                    </Button>
                    {' '}
                    <Button onClick={toggleRestoreAccount}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>


           

            <NotificationContainer />
        </>
    );
}
export default GridAccount;