import React, {useEffect, useRef,useState} from "react";
import "./Home.css";
import "../Department/Department.css";
import "../ReportedPost/ReportedPost.css";

import { GridOfficerSharePost } from "../Officer";
import { 
    Button 
    , Modal
    , ModalBody
    , ModalFooter
    , ModalHeader
    , PopoverBody
    , UncontrolledPopover
} from "reactstrap";
import { ReactComponent as CardIcon } from './../../assets/CardUser.svg';
import { ReactComponent as ClockIcon } from './../../assets/clock.svg';
import { ReactComponent as EmailIcon } from './../../assets/Email.svg';
import { ReactComponent as FolderIcon } from './../../assets/folder.svg';  
import { ReactComponent as PhoneIcon } from './../../assets/Phone.svg';
import { ReactComponent as PinLocationIcon } from './../../assets/pin_location.svg'; 
import {
    CommonPaperStyle
    ,PostShimmeringEffect
    ,PrimaryFontFamily
    ,ReadMore
} from "../Department/Department.Style";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faBell
    ,faPencil
    ,faShareSquare
    ,faTrashCan
    ,faTriangleExclamation
} from '@fortawesome/free-solid-svg-icons';
import Avatar from 'react-avatar';
import Box from '@mui/material/Box';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import Col from 'react-bootstrap/Col';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import moment from 'moment';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Paper from '@mui/material/Paper';
import Row from 'react-bootstrap/Row';
import SearchIcon from '@mui/icons-material/Search';
import ViewportList from 'react-viewport-list';



//[Start] - Header
export const HeaderLabel = ({label}) => {
    return (
        <Row>
            <Col>
                <div className="common_font_style header_label_style">{label}</div>
            </Col>
        </Row>
    );
}
//[End] - Header

//[Start] - Date Range Picker Field
const dateRangeInitialSettings = ({startDate, endDate}) => {
    return {
        startDate: startDate ? startDate.toDate() : moment().subtract(10, 'years'),
        endDate: endDate? endDate.toDate() : moment(),
        ranges: {
            Today: [moment().toDate(), moment().toDate()],
            Yesterday: [
                moment().subtract(1, 'days').toDate(),
                moment().subtract(1, 'days').toDate(),
            ],
            'Last 7 Days': [
                moment().subtract(6, 'days').toDate(),
                moment().toDate(),
            ],
            'Last 30 Days': [
                moment().subtract(29, 'days').toDate(),
                moment().toDate(),
            ],
            'This Month': [
                moment().startOf('month').toDate(),
                moment().endOf('month').toDate(),
            ],
            'Last Month': [
                moment().subtract(1, 'month').startOf('month').toDate(),
                moment().subtract(1, 'month').endOf('month').toDate(),
            ],
        },
    }
}

export const InitialDateRangeState = () => {
    return {
        startDate: moment().subtract(10, 'years'),
        endDate: moment(),
    }
}

export const DatePickerField = (props) => {
    const [selectedDateRange, setSelectedDateRange] = useState(InitialDateRangeState);

    const { startDate, endDate } = selectedDateRange;

    const handleCallback = (startDate, endDate) => {
        setSelectedDateRange({ startDate, endDate });
        props.onChangeDateValues({ startDate, endDate });
    };

    return (
        <Paper {...CommonPaperStyle}  >
            <InputBase sx={{ ml: 1, flex: 1 }} 
                       placeholder="All time" 
                       inputProps={{ 'Work Sans': 'all time' }} 
                       value={startDate.format('MMMM D, YYYY') + ' - ' + endDate.format('MMMM D, YYYY')} />
            <DateRangePicker initialSettings={dateRangeInitialSettings(startDate.toDate(), endDate.toDate())} onCallback={handleCallback}>
                <IconButton type="button" sx={{ p: '10px' }} aria-label="all time" >
                    <CalendarMonthIcon />
                </IconButton>
            </DateRangePicker>
        </Paper>
    );

}
//[End] - Date Range Picker Field

//[Start] - Search Text Field
export const SearchTextField = ({onChange}) => {
    return (
        <Paper {...CommonPaperStyle} >
            <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                <SearchIcon />
            </IconButton>
            <InputBase sx={{ ml: 1, flex: 1 }} placeholder="Keyword search..." inputProps={{ 'work sans': 'keyword search' }} onChange={onChange}/>
        </Paper>
    );
}
//[End] - Search Text Field

//[Start] - Tab Container - All Posts
export const TabContainerAllPosts = (props) => {
    const viewPortRef = useRef(null);
    const viewPortListRef = useRef(null);

    return (<>
     <Row>
        <Col>
            <SearchTextField onChange={props.onSearchTextFieldChange} />
        </Col>
        <Col>
            <DatePickerField onChangeDateValues={props.onChangeDateValues} />
        </Col>
        <Col>
        </Col>
     </Row>
     <Row style={{padding: "25px 0px 0px 10px"}}>
        {props.isLoading ? (
            <div>
                <div style={{marginBottom: "25px"}}><PostShimmeringEffect/></div>
                <div style={{marginBottom: "25px"}}><PostShimmeringEffect/></div>
            </div>) 
            : 
            (
                <div className="scroll-container" ref={viewPortRef}>
                    { 
                        props.data.length > 0 ? 
                            (
                                <>
                                    <ViewportList ref={viewPortListRef} viewportRef={viewPortRef} items={props.data} itemMinSize={40} margin={8}>
                                        {(item) => (
                                            <div className="item" key={"allposts-" + item.id} id={"allposts-content-id-" + item.id}>
                                                <IncidentPostInnerDetailContainer 
                                                data={item} 
                                                onPreviewImage={props.onPreviewImage}
                                                redirectMessageUser={props.redirectMessageUser}
                                                isSuperAdmin={props.isSuperAdmin}
                                                onReportPost={props.onReportPost}
                                                onRemovePost={props.onRemovePost} 
                                                onSetNotification={props.onSetNotification}
                                                onSharePost={props.onSharePost}
                                                />
                                            </div>
                                        )}
                                    </ViewportList>
                                    <br />
                                    <br />
                                    <br />
                                </>
                            ) 
                            : 
                            (<>No record found</>)
                    }
                </div>
            )
            }
       
     </Row>



    </>);

}
//[End] - Tab Container - All Posts


//[Start] - Incident Post Inner Detail
const IncidentPostInnerDetailContainer = (props) => {
    
    return ( 
        <Card sx={{ display: "flex", margin: "10px 0px 20px 0px", boxShadow: "0px 2px 34px rgba(0, 65, 162, 0.08)" }}>
            <div style={{display: "flex", flex: 1, padding: "16px", cursor: "pointer"}}>
                <InnerDetailImageContainer data={props.data.primaryFile} onPreviewImage={props.onPreviewImage}  />
            </div>
            <div style={{display: "flex", flex: 3, flexDirection:"column"}}>
                <div style={{flex: 2}}>
                    <InnerDetailInfoContainer 
                    data={props.data} 
                    isSuperAdmin={props.isSuperAdmin} 
                    onReportPost={props.onReportPost}  
                    onRemovePost={props.onRemovePost} 
                    onSetNotification={props.onSetNotification}
                    onSharePost={props.onSharePost}
                    />
                </div>
                <div style={{display: "flex", flex: 1, paddingLeft: "51px", paddingBottom: "15px"}}>
                {
                    props.data.myPost === true ? 
                    (<><label className="lblProfileNoCursor">My Post</label></>) 
                    : 
                    (<InnerDetailUserInfoContainer  data={props.data} redirectMessage={props.redirectMessageUser} />)
                    
                }
                </div>
               
            </div>
         
        </Card>);
}

const iconType = {
    1 : { icon: require("../../assets/pdficon.png").default}, //PDF 
    2 : {  icon: require("../../assets/wordicon.svg").default}, //WORD
    3 : {  icon: require("../../assets/excelicon.png").default}, //EXCEL
    4 : {  icon: require("../../assets/powericon.png").default}, //POWERPOINT
    5 : {  icon: require("../../assets/txticon.png").default}, //TEXT
    6 : {  icon: require("../../assets/iconrarzip.png").default}, //RARZIP
}

const InnerDetailImageContainer = (props) => {
    var _imgSource = props.data.iconTypeId > 0 ? iconType[props.data.iconTypeId].icon : props.data.path;

    return (
        
            <CardMedia 
                component="img" 
                sx={{ width: "308px", height: "291px", borderRadius: "15px",   objectFit: "fill"}}
                image={_imgSource}
                onClick={() => props.onPreviewImage(props.data)} //onClick={() => PreviewImage(item.filesIncidents[0])
                alt="Incident"/>
       
        
    );
}

const InnerDetailInfoContainer = (props) => {

    const ActionContainer = () => {
        return (
            <div>
                <IconButton aria-label="settings" id={"detail-incident-id-" + props.data.id}><MoreVertIcon /></IconButton>
                <UncontrolledPopover trigger="legacy" placement="bottom" target={`${"detail-incident-id-" + props.data.id}`}>
                    <PopoverBody>
                        <PopoverPostOptionsContainer 
                        data={props.data} 
                        isSuperAdmin={props.isSuperAdmin} 
                        onReportPost={props.onReportPost}
                        onRemovePost={props.onRemovePost} 
                        onSetNotification={props.onSetNotification}
                        onSharePost={props.onSharePost}/>
                    </PopoverBody>
                </UncontrolledPopover>
            </div>
        );
    }

    const TitleContainer = () => {
        return (
            <div className="common_font_style" style={{display: "flex", flexDirection: "column", justifyContent: "flex-start"}}>
                <div style={{fontWeight: 500, fontSize: "14px", color: "#008EEE"}}>{props.data.agencyName}</div>
                <div style={{fontWeight: 700, fontSize: "26px", color: "#002542"}}>Incident: {props.data.incidentNum}</div>
            </div>
        );
    }

    const SubHeaderContainerStyle = {
        alignItems: "center", 
        display: "flex", 
        flexDirection: "row", 
        fontFamily: PrimaryFontFamily,
        fontSize: "16px",
        fontWeight: 400, 
        paddingTop: "16px", 
    }

    const SubHeaderTextContainerStyle = {
        color: "#002542",
        padding: "3px 25px 0px 10px",
    }

    const SubHeaderContainer = () => {
        return (
            <div style={SubHeaderContainerStyle}>
                <ClockIcon width={25} height={25} />
                <div style={SubHeaderTextContainerStyle}>{moment(props.data.dateCreated).local().startOf('seconds').fromNow()}</div>

                <PinLocationIcon width={25} height={25} />
                <div style={SubHeaderTextContainerStyle}>{props.data.address}</div>

                <FolderIcon width={25} height={25} />
                <div style={SubHeaderTextContainerStyle}>{props.data.incidentType}</div>
            </div>
        );
    }

    return (
        <CardContent sx={{ padding: "0px 0px 0px 35px", width: "100%" }}>
                <CardContent sx={{ flex: '1 0 auto' }}>
                    <CardHeader style={{padding: "25px 25px 0px 0px"}} 
                                action={ <ActionContainer />}
                                title={ <TitleContainer />} 
                                subheader={ <SubHeaderContainer /> }/>
                </CardContent>
                <CardContent>
                    <ReadMore>
                        {props.data.description}
                    </ReadMore>
                </CardContent>
            </CardContent>

    );
}

const InnerDetailUserInfoContainer = (props) => {

    const _emailLength = props.data.userProfile.email.length;
    const _fullNameLength = props.data.userProfile.fullName.length;
    const _minWidth = (_fullNameLength > _emailLength  ? _fullNameLength : _emailLength) * 10; 

    return (
        <div style={{display: "flex", flexDirection: "column-reverse"}}>
            <div style={{display: "flex", flexDirection: "row",  alignItems: "center", fontFamily: PrimaryFontFamily,}}>
                <div style={{fontWeight: 500, fontSize: "16px", color: "#5F798C", marginRight: "5px"}}>
                    {props.data.userProfile.division + ":"}
                </div>
                <div style={{fontWeight: 500, fontSize: "16px",cursor: "pointer", display: "flex", flexDirection: "column-reverse"}} id={`PopoverLegacy${props.data.id}`}>
                    <div style={{display: "flex", flexDirection: "row",  alignItems: "center"}}>
                        <div style={{color: "#008EEE"}}>
                            {props.data.userProfile.fullName}
                        </div>
                        <div style={{color: "#8494A0"}}>
                            <ExpandMoreIcon width={4.95} height={8.48} />
                        </div>
                    </div>
                </div>
                <UncontrolledPopover trigger="legacy" placement="top-start" target={`PopoverLegacy${props.data.id}`} style={{minWidth: _minWidth + "px"}}>
                    <PopoverBody>
                        <UserProfileCard data={props.data} redirectMessage={props.redirectMessage} />
                    </PopoverBody>
                </UncontrolledPopover>
            </div>
        </div>
    );
};
//[End] - Incident Post Inner Detail

//[Start] - Modal - Image Preview
export const ImagePreviewModal = (props) => {
    return (
        <Modal size="lg" isOpen={props.isOpen} toggle={props.toggleImagePreviewModal}  >
            <ModalBody className="PreviewImage">
                <img src={props.data.path} width="100%" height="100%" alt={props.data.name || "" } />
            </ModalBody>
            <ModalFooter>
                <Button className="btn btn-secondary btn-cancel-modal" onClick={props.toggleImagePreviewModal}>Close</Button>
            </ModalFooter>
        </Modal>
    );
}
//[End] - Modal - Image Preview

//[Start] - Modal - Report Post
export const ReportPostModal = (props) => {

    const [optionReport, setOptionReport] = useState("");
    const [messageReport, setMessageReport] = useState("");
    const [isAnonymous, setIsAnonymous] = useState(false);

    const ChangeOption = (e) => {
        setOptionReport(e.target.value);
    }

    const ChangeMessageReport = (e) => {
        setMessageReport(e.target.value);
    }

    const ToggleAnonymous = e => {
        setIsAnonymous(e.target.checked);
    }

    useEffect(() => {if(!props.isOpen) {setOptionReport(""); setMessageReport(""); setIsAnonymous(false);}},[props.isOpen]);

    return (
        <Modal size="lg" isOpen={props.isOpen} toggle={props.toggleReportPostModal}  >
            <ModalHeader className="center modalheader">
                    Report Post
                </ModalHeader>
                <ModalBody>
                    <div className="row">
                        <label className="subtitlemodal">Let us know what is wrong with this post</label>
                    </div>
                    <div onChange={ChangeOption}>
                    <div className="row radiorowoption">
                        <div className="col-1">
                            <input type="radio" name="option" value="Spam: Content that is irrelevant, promotional, or repeated multiple times." />
                        </div>
                        <div className="col-11">
                            <label className="optionradioreport">Spam: Content that is irrelevant, promotional, or repeated multiple times.</label>
                        </div>
                    </div>
                    <div className="row radiorowoption">
                        <div className="col-1">
                            <input type="radio" name="option" value="Impersonation: Accounts or posts pretending to be someone else, particularly public figures or celebrities." />
                        </div>
                        <div className="col-11">
                            <label className="optionradioreport">Impersonation: Accounts or posts pretending to be someone else, particularly public figures or celebrities.</label>
                        </div>
                    </div>
                    <div className="row radiorowoption">
                        <div className="col-1">
                            <input type="radio" name="option" value="Misinformation or False News: Content that spreads false or misleading information, particularly in cases where it could cause harm." />
                        </div>
                        <div className="col-11">
                            <label className="optionradioreport">Misinformation or False News: Content that spreads false or misleading information, particularly in cases where it could cause harm.</label>
                        </div>
                    </div>
                    <div className="row radiorowoption">
                        <div className="col-1">
                            <input type="radio" name="option" value="Other" />
                        </div>
                        <div className="col-11">
                            <label className="optionradioreport">Other</label>
                        </div>
                        </div>
                        </div>
                    <div className="row">
                        <label className="textinf">Provide information why do you report this post</label>
                    </div>
                    <div className="row">
                        <textarea className="messagetextarea" 
                                value={messageReport} 
                                onChange={ChangeMessageReport} 
                                disabled={optionReport !=="Other"}>
                        </textarea>
                    </div>
                    <div className="row" style={{paddingLeft: "15px", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center"}}>
                        <div style={{width: "15px", marginTop: "11px"}}>
                            <input type="checkbox" 
                            className="form-check-input nomt" 
                            id="isAnonymous" v
                            alue={isAnonymous} 
                            checked={isAnonymous} 
                            onChange={ToggleAnonymous}
                            />
                        </div>
                        <div style={{flex: 1}}><label className="form-check-label" htmlFor="isAnonymous">Anonymous</label></div>
                    </div>
                </ModalBody>
                <ModalFooter className="footerright">

                    <div>
                        <Button className="btn btn-secondary btn-cancel-modal" onClick={props.toggleReportPostModal} disabled={props.isLoading}>
                            Cancel
                        </Button>
                        
                            <Button
                                className="btn btn-primary"
                                color="primary"
                                disabled={optionReport === "" ||( optionReport === "Other" && messageReport === "" )}
                                onClick={() => {props.submit({optionReport, messageReport, isAnonymous})}}
                            >
                                Create &nbsp; {props.isLoading && (<span className="spinner-border spinner-border-sm"></span>)}
                            </Button>

                        
                    </div>

                </ModalFooter>
        </Modal>
    );
}
//[End] - Modal - Report Post

//[Start] - Modal - Share Post
export const SharePostModal = (props) => {

    const [selectedUser, setSelectedUser] = useState({
        id: "",
        name: "",
        incidentid: 0,
        incidentNum: "",
        message: ""
    });

    return (
        <Modal size="md" isOpen={props.isOpen}>
        <ModalHeader className="center modalheader">
            Share Post
        </ModalHeader>
        <ModalBody>
            {selectedUser.id === "" &&
                <div>
                    <Row>
                        <Col md="5">
                            <label className="bold">
                                Share Post
                            </label>
                        </Col>
                        <Col md="7">
                            <label>
                                # {props.data.incidentNum}
                            </label>
                        </Col>
                    </Row>
                    <GridOfficerSharePost SelectUser={setSelectedUser}></GridOfficerSharePost>
                </div>
            }
            {/* {selectedUser.id !== "" &&
                <div>
                    <Row className="center">
                    <Row>
                        <Label className="lblinfo">With</Label>
                        </Row>
                    <Row>
                        <Col md="2">
                        </Col>
                            <Col md="2" className="rowinfouser">
                            <Avatar src={usersharepost.image} name={usersharepost.name} round size="50" />
                            <label className="lblnameofficer mglb">  {usersharepost.name}</label>
                            
                               
                            </Col>
                        </Row>
                        <hr />
                        <Row className="rowmessageuser">
                            <Col md="12">
                            <Label className="lblinfo">Include a message for your colleague</Label>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="12">
                                <textarea className="form-control" onChange={ChangeMessage} style={{ minHeight: "4rem", marginLeft: "4%", resize: "none" }} ></textarea>
                            </Col>
                        </Row>

                    </Row>
                </div>
            } */}

        </ModalBody>
        {/* <ModalFooter className="footerright">
           
                <div>
                    <Button className="btn btn-secondary" onClick={toggleModal}>
                        Cancel
                </Button>
                {usersharepost.id != "" &&
                    <Button
                        className="btn btn-primary"
                        color="primary"
                        disabled={usersharepost.message == ""}
                        onClick={() => SharedPost(usersharepost)}
                    >
                        Share   {loadingPostsShare && (
                            <span className="spinner-border spinner-border-sm"></span>
                        )}
                    </Button>

                }
                </div>
            
        </ModalFooter> */}
    </Modal>);
}
//[End] - Modal - Share Post

//[Start] - User Profile Card
export const UserProfileCard = (props) => {
    const _data = props.data.userProfile;
    return (<div>
        <div className="user_popover_container">
             <div className="user_popover_header_container">
                 <div className="user_popover_header_avatar_container">
                    {
                        _data.picturePath ? 
                        (<img className="picUrlPreviewAdmin" src={_data.picturePath} alt={_data.fullName} />) 
                        : 
                        (<Avatar className="sb-avatar--textProfile" size="40" round="50%" name={_data.fullName}></Avatar>) 
                    }
                     
                 </div>
                 <div className="user_popover_header_info_container">
                     <div style={{fontWeight: 600, color: "#002542"}}>{_data.fullName}</div>
                     <div style={{fontWeight: 400, color: "#5F798C"}}>{_data.division}</div>
                 </div>
             </div>
             <div className="user_popover_body_container">
                {
                    _data.address !== "" && 
                    (<div className="user_popover_body_row_container">
                        <div style={{marginRight: "15px"}}><CardIcon width={25} height={25} /></div>
                        <div>{_data.address}</div>
                    </div>)
                }
                {
                    _data.showPhone && _data.phoneNumber !== "" && 
                    (<div className="user_popover_body_row_container">
                            <div style={{marginRight: "15px"}}><PhoneIcon width={25} height={25} /></div>
                            <div><a href={"tel:" + _data.phoneNumber}>{_data.phoneNumber}</a></div>
                    </div>)
                }
                {
                    _data.showEmail && _data.email !== "" && 
                    (<div className="user_popover_body_row_container">
                        
                            <div style={{marginRight: "15px"}}><EmailIcon width={25} height={25} /></div>
                            <div><a href={"mailto:" + _data.email}>{_data.email}</a></div>
                      
                    </div>)
                }
               
             </div>
             {
                !(_data.statusApp.toLowerCase() === "suspended" || _data.statusApp.toLowerCase() === "removed") &&
                (<div className="user_popover_footer_container">
                    <button onClick={() => props.redirectMessage(props.data)} className="btn btn-outline-primary btn-lg btn-bloc col-lg-12">Message</button>
                </div>)
             }
               
         </div>
    </div>);
}
//[End] - User Profile Card

//[Start] - Popover Post Options
const PopoverPostOptionsContainer = (props) => {
    
    return (
        <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', borderRadius: "10px" }}>
           <List sx={{padding: "20px"}} key={"post-options-item-" + props.data.id}>

                {
                    (props.data.canEdit === true && !props.isSuperAdmin) && 
                    (
                    <ListItem disablePadding>
                        <ListItemButton href={"/post/edit/" + props.data.id}>
                            <ListItemIcon>
                                <FontAwesomeIcon className="iconPopup" icon={faPencil} size="xl"/>
                            </ListItemIcon>
                            <ListItemText primary="Edit post" />
                        </ListItemButton>
                    </ListItem>
                    )
                }

                <ListItem disablePadding>
                    <ListItemButton onClick={() => {props.onReportPost(props.data)}}>
                        <ListItemIcon>
                            <FontAwesomeIcon className="iconPopup" icon={faTriangleExclamation} size="xl"/>
                        </ListItemIcon>
                        <ListItemText primary="Report post" />
                    </ListItemButton>
                </ListItem>

                {
                    (props.data.canEdit === true) && 
                    (
                    <ListItem disablePadding>
                        <ListItemButton onClick={() => {props.onRemovePost(props.data)}}>
                            <ListItemIcon>
                                <FontAwesomeIcon className="iconPopup" icon={faTrashCan} size="xl"/>
                            </ListItemIcon>
                            <ListItemText primary="Remove post" />
                        </ListItemButton>
                    </ListItem>
                    )
                }

                <ListItem disablePadding>
                    <ListItemButton onClick={() => {props.onSetNotification(props.data)}}>
                        <ListItemIcon>
                            <FontAwesomeIcon className="iconPopup" icon={faBell} size="xl"/>
                        </ListItemIcon>
                        <ListItemText primary="Get notified" />
                    </ListItemButton>
                </ListItem>

                <ListItem disablePadding>
                    <ListItemButton onClick={() => {props.onSharePost(props.data)}}>
                        <ListItemIcon>
                            <FontAwesomeIcon className="iconPopup" icon={faShareSquare} size="xl"/>
                        </ListItemIcon>
                        <ListItemText primary="Share post with fellow user" />
                    </ListItemButton>
                </ListItem>

               

                {/* <ListItem disablePadding>
                    <ListItemButton onClick={() => handleRemovePost(props.data.incidentId)}>
                        <ListItemIcon>
                            <FontAwesomeIcon className="iconPopup" icon={faTrashCan} size="xl"/>
                        </ListItemIcon>
                        <ListItemText primary="Remove post" />
                    </ListItemButton>
                </ListItem> */}
            </List>
        </Box>
    );
}
//[End] - Popover Post Options


