/*eslint unicode-bom: ["error", "always"]*/
import React, { useCallback, useEffect, useState, useRef} from "react";
import { useForm } from 'react-hook-form';
import Autocomplete from "react-google-autocomplete";
import { useParams } from 'react-router-dom';
import './Post.css';
import { useHistory } from 'react-router-dom';
import { faCoffee, faHashtag, faLocationArrow, faSitemap, faChair, faCalendarDays, faNoteSticky, faCloudArrowUp } from '@fortawesome/free-solid-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core';
import api from "../../services/api";
import AuthService from "../../services/auth.service";
import { Row, Col } from "react-bootstrap";
import postPicture from "../../assets/PostPicture.png";
import { NotificationContainer, NotificationManager } from 'react-notifications';
library.add(faCoffee, faHashtag, faLocationArrow, faSitemap, faChair, faCalendarDays, faNoteSticky, faCloudArrowUp);
const Post = (props) => {
    const { setOpenCreatePost, reloadData, setIsAfterCreatedPost } = props;
    const { register, handleSubmit, setValue, formState: { errors }, setError, clearErrors} = useForm({
        mode: 'onChange'
});
    const { id } = useParams();
    let history = useHistory();
    const [loading, setLoading] = useState(false);
    const [list, setList] = useState([]);
    const [listcategories, setListcategories] = useState([]);
    const GOOGLE_API = "AIzaSyA3KsiVMTTNXNNWrViDI8qwSYFfbRkaQI4";
    const [incident, setIncident] = useState({
        id:0,
        category: 1,
        locationinput: "",
        onlyagency: false,
        draft: false,
        addressId: "-"
    });
    const [images, setImages] = useState([]);
    const [imagesError, setImageError] = useState(false);
    const [imagessaved, setImagesSaved] = useState([]);
    const [location, setLocation] = useState({
    });
    const [isChangeLocation, setIsChangeLocation] = useState(false);
    const [isKeyPressEnter,setIsKeyPressEnter] = useState(false);

    const uploadInput = useRef(null);
    const cleanData = () => {
        setIncident({
            id: 0,
            category: 1,
            locationinput: "",
            onlyagency: false,
            draft: false,
            addressId: "-"
        });
        setLocation({});
        setImages([]);
    }
    const SavePost = useCallback((data) => {

        if(isKeyPressEnter) {
            setIsKeyPressEnter(false);
            return;
        }

        if (!loading) {
            
            setLoading(true);

            const formData = new FormData()
            for (var i = 0; i < images.length; i++) {
                formData.append(`Files[${i}].FileName`, "-");
                formData.append(`Files[${i}].File`, images[i]);
            }

            for (var iCounter2 = 0; iCounter2 < imagessaved.length; iCounter2++) {
                formData.append(`FilesName[${iCounter2}]`, imagessaved[iCounter2]);
            }

            //Images and attachments will be optional based on the client's request
            // if ((imagessaved.length === 0 || imagessaved.length === undefined) && images.length === 0) {
            //     setError('image', { type: 'required' });
            //     setImageError(true);
            //     setLoading(false);
            //     return;
            // }
            // else {
            //     setImageError(false);
            // }
            setImageError(false);
           
            formData.append("Id", incident.id);
            formData.append("IncidentNum", data.num);

            if(location.e) {
                formData.append("AddressId", location.e.place_id);
            } else {
                //If the user edit the post but not changing the selected option under location. The addressId should remain.
                if(!isChangeLocation) {
                    if(incident.addressId !== "-") {
                        formData.append("AddressId", incident.addressId);
                    }
                }
            }

            formData.append("NameAddress", location.e ? location.e.formatted_address : data.locationinput);
            formData.append("Latitude", location.e ? location.e.geometry.location.lat() : 0);
            formData.append("Longitude", location.e ? location.e.geometry.location.lng() : 0);
            formData.append("OnlyAgency", incident.onlyagency);
            formData.append("UserId", "-");
            formData.append("Draft", incident.draft);
            formData.append("Date", data.date);
            formData.append("Description", data.description);
            formData.append("IncidentTypeId", data.type);
            formData.append("IncidentCategoryId", data.category);

            api.post("/incidents/save", formData, AuthService.getToken())
                .then(response => {
                    if (incident.id === 0) {
                        // console.log(response);
                        if (response.data.id && response.data.id !== 0) {
                            reloadData();
                            setLoading(false);
                            NotificationManager.success('Success', 'The Post was created.', 5000);
                            try {
                                setIsAfterCreatedPost(true);
                                setOpenCreatePost(false);
                            } catch (ex) {
                                console.log(ex);
                            }
                            setTimeout(function () { history.push("/home"); }, 100);
                        }
                    } else {
                        setLoading(false);
                        NotificationManager.success('Success', 'The Post was updated.', 3000);
                        setTimeout(function () { history.push("/home"); }, 3000);
                       
                    }

                });
        }
        
    },[isChangeLocation, images, imagessaved,incident,isKeyPressEnter])

    const onChangeLocation = e => {
        setLocation({
            ...location, e
        });
        setValue('locationinput', e.formatted_address);
        setIsChangeLocation(true);
        clearErrors("locationinput");
    }


    
    const [isChangeCategory, setIsChangeCategory] = useState(false);
    const onChangeCategory = e => {
        setIsChangeCategory(true);
        setIncident({
            ...incident,
            category: e.target.value
        });

    }

    const isItemChecked = useCallback((itemId) => {
        if(incident.id > 0) {
            return (incident.category === itemId);
        } else {
            if(!isChangeCategory) {
                if(itemId === 1) return true;
            }

        }
    },[incident,isChangeCategory])

    const onChangeInputMap = useCallback((e) => {

        if (e.target.value === "" || e.target.value==null) {
            setError('locationinput', { type: 'required' });
        } else {
            //It means if the user input any text on the location field, the user should select on pop-up list box.
            setError('locationinput', { type: 'freetextlocation'});
        }
        
    },[setError])

    const onBlurInputMap = useCallback((e) => {

        if (e.target.value === "" || e.target.value==null) {
            setError('locationinput', { type: 'required' });
        }

    },[setError])

    const onChangeOnlyAgency = e => {
        setIncident({
            ...incident,
            onlyagency: e.target.checked
        });

    }

    const onChangeDraft = e => {

        setIncident({
            ...incident,
            draft: e.target.checked
        });

    }
    const RemoveSaved = useCallback((id) => {
        if(!isKeyPressEnter) {
            var newimages = imagessaved.filter(function (image) {
                return image !== id
            });

            setImagesSaved(newimages);

            if (newimages.length === 0)
                setValue('image',null);
        }

    },[isKeyPressEnter])

    const RemoveImage = id => {
        var newimages = images.filter(function (image) {
            return image !== id
        });
        setImages(newimages);
        if (newimages.length === 0)
            setValue('image',null);
    }

    const SelectInput = () => {
        uploadInput.current.click();
    }

    useEffect(() => {
        api.get(
            "/incidents/types"
        )
            .then((response) => {
                // console.log(response);
                setList(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
        api.get(
            "/incidents/categories"
        )
            .then((response) => {
                // console.log(response);
                if (response.data.length > 0) {
                    let idc = response.data[0].id;
                    setIncident({
                        ...incident,
                        category: 
                          idc
                    });
                }
                setListcategories(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }, [setList, setListcategories]);


    const Cancel = () => {
        try {
            setOpenCreatePost(false);
        } catch (ex) {
            console.log(ex);
        }
        cleanData();
        setTimeout(function () { history.push("/home"); }, 100);
    }

    const getIncident = async () => {
        api.get("/incidents/get?id=" + id, AuthService.getToken())
            .then(response => {
                // console.log(response);
                setValue('num', response.data.incidentNum);
                setValue('type', response.data.incidentTypeId);
                setValue('description', response.data.description);
                setValue('date', response.data.date);
                setValue('locationinput', response.data.addressId === "-" ? "" : response.data.nameAddress);
                setValue('category', response.data.incidentCategoryId);
                setValue('addressId', response.data.addressId);
                setIncident({
                    ...incident,
                    id:response.data.id,
                    category: response.data.incidentCategoryId,
                    onlyagency: response.data.onlyAgency,
                    locationinput: response.data.addressId === "-" ? "" : response.data.nameAddress,
                    draft: response.data.draft,
                    addressId: response.data.addressId
                });
                setImagesSaved(response.data.filesName);
            });
    }

    if (id !== undefined && id > 0 && incident.id ===0) {
        getIncident();

    }

    const keyDownHandler = event => {
        setIsKeyPressEnter(event.key === 'Enter' ? true : false);
    };

    useEffect(() => {
        document.addEventListener('keydown', keyDownHandler);

        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, []);

    return (
        <>
            <div className="card-container">
                <div className="card-post">
                    <form onSubmit={handleSubmit(SavePost)} >
                        <div className="card-body">
                            <Row>
                                <Col lg={2}>
                                    <Row>
                                        {
                                           
                                            <>
                                                <img alt="" src={postPicture} onClick={() => {SelectInput();}} />

                                                <input
                                                    type="file"
                                                    id="input-file" className="input-file"
                                                    accept="image/*"
                                                    name="myImage"
                                                    multiple
                                                    ref={uploadInput}



                                                    onChange={(event) => {
                                                        var arrayFiles = [];
                                                        if (images && images.length > 0) {
                                                            arrayFiles = images;
                                                        }
                                                       

                                                        Array.from(event.target.files).forEach(file => arrayFiles.push(file));

                                                        setImages(arrayFiles);
                                                        clearErrors("image");


                                                    }}
                                                />

                                                <button type="button" className="buttonWithoutBorder" variant="outline-light" onClick={() => {
                                                    SelectInput();
                                                }}>
                                                    Upload new file
                                                </button>
                                                <div className="errordiv">
                                                    {imagesError && imagesError === true && <span className="text-danger">This field is required</span>}

                                                </div>
                                            </>
                                        }
                                           
                                        {
                                            <>

                                                {images &&  images.length > 0 && (

                                                    <>
                                                        {images.map((item) => {
                                                            return <>
                                                                <div className="row" key={item}>
                                                                    <img alt="Not Found" width={"250px"} src={URL.createObjectURL(item)} />

                                                                    <button type="button" className="btn btn-danger" onClick={() => RemoveImage(item)}>Remove</button>
                                                                </div>
                                                            </>

                                                        })


                                                        }

                                                    </>
                                                )}
                                                {imagessaved.length > 0 && (

                                                    <div>
                                                        {imagessaved.map((item, index) => (
                                                            
                                                            <div className="row" key={item}>
                                                                <img alt="Not Found" width={"250px"} src={item} />
                                                             
                                                                <button className="btn btn-danger" onClick={() => RemoveSaved(item)} >Remove</button>
                                                            </div>
                                                        ))}

                                                    </div>
                                                )}
                                            </>
                                        }
                                       
                                        
                                    </Row>
                                    
                                </Col>
                                <Col lg={10}>
                                    <Row>
                                        <div>
                                                {listcategories.map((item) => (
                                                    <>
                                                        <div key={item.id} className="typeBtnRadio">
                                                            <input checked={isItemChecked(item.id)} value={item.id} {...register("category", { required: true })} type="radio" onClick={onChangeCategory} />
                                                            <label className="form-check-label" htmlFor="flexCheckDefault">
                                                                    {item.name}
                                                            </label>
                                                        </div>
                                                    </>
                                                ))}

                                           
                                       
                                           
                                        </div>
                                       
                                    </Row>
                                    <br/>
                                    <Row>
                                        <Col>
                                            <div className="mb-3">
                                                <label htmlFor="exampleFormControlInput1" className="form-label">Incident number</label>
                                                <input type="text" className={errors.num ? 'form-control is-invalid' : 'form-control'} {...register("num", { required: true, maxLength: 80 })} placeholder="Enter the number" aria-label="Username" aria-describedby="basic-addon1" />

                                            </div>
                                            <div className="errordiv">
                                                {errors.num && errors.num.type === "required" && <span className="text-danger">This field is required</span>}
                                            </div>
                                        </Col>
                                        <Col>
                                            <div className="mb-3">
                                                <label className="form-label">Incident date</label>

                                                <input type="datetime-local" className={errors.date ? 'form-control  is-invalid' : 'form-control'} {...register("date", { required: true })} placeholder="Incident Date and Time" aria-label="Username" aria-describedby="basic-addon1" />
                                            </div>
                                            <div className="errordiv">
                                                {errors.date && errors.date.type === "required" && <span className="text-danger">This field is required</span>}
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                        <div className="mb-3">
                                                <label htmlFor="ss" className="form-label">Location</label>

                                            <Autocomplete
                                                apiKey={GOOGLE_API}
                                                {...register("locationinput", { required: true })}
                                                className={errors.locationinput ? 'form-control is-invalid' : 'form-control'}
                                                placeholder="Enter the location"
                                               
                                                options={{
                                                    
                                                    types: ["address"],
                                                    componentRestrictions: { country: "us" },
                                                }}
                                                defaultValue={incident.locationinput}
                                                onChange={onChangeInputMap}
                                                onBlur={onBlurInputMap}
                                                onPlaceSelected={(place) => {
                                                    onChangeLocation(place);
                                                    // console.log(place);
                                                }}
                                            />

                                        </div>
                                        <div className="errordiv">
                                            {errors.locationinput && errors.locationinput.type === "required" && <span className="text-danger">This field is required</span>}
                                            {errors.locationinput && errors.locationinput.type === "freetextlocation" && <span className="text-danger">Select an address in the option list</span>}
                                            </div>
                                        </Col>
                                        <Col>
                                                <div className="mb-3">
                                                <label className="form-label">Incident type</label>

                                                    <select defaultValue="" className={errors.type ? 'form-control is-invalid' : 'form-control'}  {...register("type", { required: true })}>
                                                        <option value="" disabled hidden>Incident Type</option>
                                                        {list.map((item) => (
                                                            <option key={item.id} value={item.id}>{item.name}</option>
                                                        ))}
                                                    </select>

                                                </div>
                                                <div className="errordiv">
                                                    {errors.type && errors.type.type === "required" && <span className="text-danger">This field is required</span>}
                                                </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <div className="mb-3">
                                                <label className="form-label">Description</label>

                                                <textarea className={errors.description ? 'form-control  textareaform is-invalid' : 'form-control textareaform'} {...register("description", { required: true })} placeholder="Description" aria-label="Username" aria-describedby="basic-addon1" />
                                            </div>
                                            <div className="errordiv">
                                                {errors.description && errors.description.type === "required" && <span className="text-danger">This field is required</span>}
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col>
                                            <div className="col-md-6 col-sm-12">
                                                <div className="form-check">
                                                    <input type="checkbox" className="form-check-input nomt" id="exampleCheck1" value={incident.draft} checked={incident.draft} onChange={onChangeDraft} />
                                                    <label className="form-check-label" htmlFor="exampleCheck1">Save as draft</label>
                                                </div>
                                                <div className="form-check">
                                                    <input type="checkbox" className="form-check-input nomt" id="exampleCheck1" value={incident.onlyagency} checked={incident.onlyagency} onChange={onChangeOnlyAgency} />
                                                    <label className="form-check-label" htmlFor="exampleCheck1">Just Share with my agency</label>
                                                </div>
                                            </div>
                                            <div className="col-3">
                                               
                                            </div>
                                            <div className="row">
                                            <div className="col-md-4">
                                                <button type="submit" className="btn btn-primary btnsubmit"   disabled={loading} >  
                                                {loading && (
                                                    <span className="spinner-border spinner-border-sm"></span>
                                                    )} Submit
                                                    </button>
                                                </div>
                                                <div className="col-md-4">
                                                <button type="button" className="btn btn-secondary btnsubmit" onClick={() =>  Cancel()} disabled={loading}>
                                                    Cancel
                                                </button>
                                            </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    </form>
                </div>
            </div>
            <NotificationContainer />
        </>
      
    );
}
export default Post;