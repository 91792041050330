import DepartmentApp from "./Department";
import DepartmentProvider from "./Department.Provider";

export const Department = () => {
    return (
        <DepartmentProvider>
            <DepartmentApp />
        </DepartmentProvider>
    );
}

