import { HubConnectionBuilder } from '@microsoft/signalr';
import authService from './auth.service';
let signalRConnection = null;
class SignaRService {
    

    initConnection() {
        if (signalRConnection == null || signalRConnection == undefined)
        {
            let user = JSON.parse(localStorage.getItem('user'));
            let token = "";
            if (user && user.token) {
                token = user.token;
            } 

            signalRConnection = new HubConnectionBuilder()
                .withUrl('/hubs/chat?access_token=' + token + "&hubKey=" + authService.getKeyHub())
                    .withAutomaticReconnect()
                .build();

            signalRConnection.start();
        }
    }

    getHubConnection() {
        return signalRConnection;
    }

    
}
export default new SignaRService();