
import { useEffect, useState,useCallback } from "react";
import { useForm } from 'react-hook-form';
import Table from "../../../components/Common/table";
import authService from "../../../services/auth.service";
import Pagination from "../../../components/Common/pagination";
import { Button, Col, Input, Row,Label } from "reactstrap";
import {Modal, ModalBody, ModalFooter, ModalHeader } from "react-bootstrap";
import ModalAlert from "../../Common/ModalAlert";
import ManageAdmins from "../../Admin/ManageAdmins";
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import api from "../../../services/api";
import btnawait from "../../../assets/badge_status_await.png";
import btnjoin from "../../../assets/badge_status.png";
import location from "../../../assets/location_full.png";
import editpen from "../../../assets/edit.png";
import deletetrash from "../../../assets/delete.png";
import AuthService from "../../../services/auth.service";
import { useHistory } from 'react-router-dom';
import LoaderAgency from "../Officer/LoaderAgency";
import {

    NoResultFoundContainer
} from "../../Department/Department.Style";

library.add(faSearch);
const RequestedAgencies = () =>
{
    let history = useHistory();
    const { formState: { errors }, setError, clearErrors } = useForm({
        mode: 'onChange'
    });

    const [isSuper, setIsSuper] = useState(AuthService.isSuperAdmin() || AuthService.isAdmin());
    const [isAdminOnly, setIsAdminOnly] = useState(AuthService.isAdmin() && !AuthService.isSuperAdmin())
    const [modal, setModal] = useState(false);
    const [modalInvitation, setmodalmodalInvitation] = useState(false);
    const [showUsers, setShowUsers] = useState(false);
    const [sendInvitation, setSendInvitation] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [modalDelete, setModalDelete] = useState({
        show: false,
        id: "",
        name: ""
    });
    const [manageAgency, setmanageAgency] = useState(0);
    const [usersAvailables, setUsersAvailables] = useState([]);
    const [agencyId, setAgencyId] = useState(false);
    const [validation, setValidation] = useState({ error: false, message: "" });
    const RedirectEditAgency = (agency) => {
        history.push("/agency/create?agency=" + agency.agencyId);
    }
    const [emailExist, setEmailExist] = useState([]);

    const DeleteAgency = (id) => {

        const formDatadelete = new FormData();

        formDatadelete.append("id", id);

        api.post("/agency/delete", formDatadelete, AuthService.getToken())
            .then(response => {

                if (response.data) {
                    toggleDeleteModal(false);
                    getData(1).then((info) => {
                        const { totalPages, data, totalData } = info;
                        setPageData({
                            isLoading: false,
                            rowData: data,
                            totalPages,
                            totalData,
                        });
                    });
                }


            });
    }

    const toggleDelete = (agency) => {
        setModalDelete({
            ...modalDelete,
            show: !modalDelete.show,
            id: agency.agencyId,
            name: agency.agencyName
        });

    }
    const toggleDeleteModal = (shomwod) => {
        setModalDelete({
            ...modalDelete,
            show: shomwod,
            id: "",
            name: ""
        });

    }
    const sendUserInvitation = async (event) => {

        // eslint-disable-next-line
        const regexemail = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        if (usersAvailables !== undefined && usersAvailables.length === 0 && regexemail.test(filterinvitation) === true) {
            var addOfficerViewModel = {
                Email: filterinvitation,
                Agency: agencyId,
                UserId: filterinvitation
            };
            const responseUser = await api.post(
                `/authenticate/sendInvitation`, addOfficerViewModel
            ).then((response) => {

                if (response !== undefined && response.data.message !== undefined) {
                    setValidation({
                        error: true,
                        message: response.data.message
                    })
                } else {
                    setValidation({
                        error: false,
                        message: ""
                    })
                    setModal(false);
                    setmodalmodalInvitation(true);
                }
                setFilterInvitation("");

            });
        }

    }


    const getUserEmailExist = (agencyId) => {
        api.post("/officer/emails?agencyId="+ agencyId, AuthService.getToken())
            .then(response => {
                setEmailExist(response.data.data);
            });

    }

    const toggle = (agency) => {
        getUserEmailExist(agency);

        setValidation({
            error: false,
            message: ""
        });
        setModal(!modal);
        setAgencyId(agency);
        setFilterInvitation("");   
        clearErrors("emailaddress");    
    }

    const manageAdminUsers = async (agency) => {
        setmanageAgency(agency.agencyId);
        setShowUsers(true);
    }

    const toggleManageAdmins = () => {
        setShowUsers(false);
    }
    const removeRequest = async (request) =>
    {
        setIsLoading(true);
        const response = await api.get(
            `/agency/removerequest?request=${request.requestId}`,
            authService.getToken()
        ).then(() => {
            getData(currentPage, filter).then((info) => {
                reload(info);
                setRemoveModalRequest(true);
                setIsLoading(false);
            });
        });
    }

    const handleSuscribe = async (agency) => {

       
        if (agency.isDefault === false) {
            setStateCheckBox({ disabled: true });
            var request = {
                AgencyId: agency.agencyId,
                AgencyName: agency.agencyName,
                State: agency.state,
                Joined: agency.joined
            };
            var url = agency.joined ? `/agency/unsubscribe` : `/agency/request`;
            const response = await api.post(
                url,
                request,
                authService.getToken()
            ).then((response) => {               
                getData(currentPage, filter).then((info) => {
                    reload(info, request,response);
                });

                setStateCheckBox({ disabled: false });
            });
        }
        else {
            setmodalDefaultAgency(true);
        }

        


    }
    const toggleInvitation = () => {
        setmodalmodalInvitation(false);
        setFilter("");
        setSendInvitation(false);
    };
    const reload = (info, request, response) => {
      
        const { totalPages, data, totalData } = info;
        setPageData({
            isLoading: false,
            rowData: data,
            totalPages,
            totalData,
        });
        if (request !== undefined && !request.Joined) {
            if (response.data.state == 0) {
                setModalRequest(true);
            }
            if (response.data.state == 1) {
                setPendingModalRequest(true);
            }
            
        }
        
    }

    const columns = isSuper ? (isAdminOnly ? [
        {
            Header: "Department",
            accessor: "agencyName",
        },
        {
            Header: "Location",
            accessor: "state",
            Cell: cellInfo => {
                return <>
                    <img className="locationpng" src={location}></img><label> {cellInfo.data[cellInfo.row.index].state}</label>
                </>;
            }
        },
        {
            Header: "Admins",
            accessor: "",
            Cell: cellInfo => {
                if (AuthService.isSuperAdmin() || cellInfo.data[cellInfo.row.index].isAdmin) {
                    return <>

                        <label onClick={() => manageAdminUsers(cellInfo.data[cellInfo.row.index])} className="link">Manage</label>



                    </>
                }
                return <></>;
            }
                

            
        },
        {
            Header: "Officers",
            accessor: "",
            Cell: cellInfo => {

                if (AuthService.isSuperAdmin() || cellInfo.data[cellInfo.row.index].isAdmin) {
                    return <>

                        <label onClick={() => toggle(cellInfo.data[cellInfo.row.index].agencyId)} className="link">+ Add officers</label>



                    </>
                }
                return <></>;
            }



        },
        {
            Header: "Status",
            accessor: "joined",
            Cell: cellInfo => {
                if (cellInfo.row.values.joined === true) {
                    return <>
                        {/* <img src={btnjoin} className="iconsstatus" onClick={() => { handleSuscribe(cellInfo.data[cellInfo.row.index]) }}></img> */}
                        <Button style={{width: "100px", fontFamily: "Work Sans", fontSize: "14px"}} color="primary" onClick={() => { handleSuscribe(cellInfo.data[cellInfo.row.index]) }}>
                            Joined
                        </Button>
                    </>
                } else {
                    if (cellInfo.data[cellInfo.row.index].request === true) {
                        if (cellInfo.data[cellInfo.row.index].stateRequest == "Pending") {
                            return <>
                                {/* <img src={btnawait} className="iconsstatus" onClick={() => removeRequest(cellInfo.data[cellInfo.row.index])} ></img> */}
                                <Button style={{width: "100px", fontFamily: "Work Sans", fontSize: "14px"}} onClick={() => removeRequest(cellInfo.data[cellInfo.row.index])} >
                                        Awaiting <br/> Approval
                                    </Button>
                            </>
                        } else {
                            return <> 
                                <Button style={{width: "100px", fontFamily: "Work Sans", fontSize: "14px"}} onClick={() => { handleSuscribe(cellInfo.data[cellInfo.row.index]) }} >
                                    Join
                                </Button>
                            </>
                        }
                    } else {
                        return <> 
                            
                            <Button style={{width: "100px", fontFamily: "Work Sans", fontSize: "14px"}} onClick={() => { handleSuscribe(cellInfo.data[cellInfo.row.index]) }} >
                                    Join
                                </Button>
                        </>
                    }
                }
            }
        },
        {
 
            Header: " ",
            accessor: "",
            Cell: cellInfo => {

                //console.log(cellInfo);
                if (AuthService.isSuperAdmin()) {
                    return <>

                        <img className="actionspng" src={editpen} onClick={() => RedirectEditAgency(cellInfo.data[cellInfo.row.index])}></img>
                        <img className="actionspng" src={deletetrash} onClick={() => toggleDelete(cellInfo.data[cellInfo.row.index])}></img>


                    </>;
                }
                if (cellInfo.data[cellInfo.row.index].isAdmin)
                {
                    return <>

                        <img className="actionspng" src={editpen} onClick={() => RedirectEditAgency(cellInfo.data[cellInfo.row.index])}></img>
                    </>;
                }
                return <></>;
            }



        
        }
    ] : [
        {
            Header: "Department",
            accessor: "agencyName",
        },
        {
            Header: "Location",
            accessor: "state",
            Cell: cellInfo => {
                return <>
                    <img className="locationpng" src={location}></img><label> {cellInfo.data[cellInfo.row.index].state}</label>
                </>;
            }
        },
        {
            Header: "Admins",
            accessor: "",
            Cell: cellInfo => {
                if (AuthService.isSuperAdmin() || cellInfo.data[cellInfo.row.index].isAdmin) {
                    return <>

                        <label onClick={() => manageAdminUsers(cellInfo.data[cellInfo.row.index])} className="link">Manage</label>



                    </>
                }
                return <></>;
            }



        },
        {
            Header: "Officers",
            accessor: "",
            Cell: cellInfo => {

                if (AuthService.isSuperAdmin() || cellInfo.data[cellInfo.row.index].isAdmin) {
                    return <>

                        <label onClick={() => toggle(cellInfo.data[cellInfo.row.index].agencyId)} className="link">+ Add officers</label>



                    </>
                }
                return <></>;
            }



        },
        {

            Header: " ",
            accessor: "",
            Cell: cellInfo => {

                //console.log(cellInfo);
                if (AuthService.isSuperAdmin()) {
                    return <>

                        <img className="actionspng" src={editpen} onClick={() => RedirectEditAgency(cellInfo.data[cellInfo.row.index])}></img>
                        <img className="actionspng" src={deletetrash} onClick={() => toggleDelete(cellInfo.data[cellInfo.row.index])}></img>


                    </>;
                }
                if (cellInfo.data[cellInfo.row.index].isAdmin) {
                    return <>

                        <img className="actionspng" src={editpen} onClick={() => RedirectEditAgency(cellInfo.data[cellInfo.row.index])}></img>
                    </>;
                }
                return <></>;
            }




        }
    ]) : [
        {
            Header: "Department",
            accessor: "agencyName",
        },
        {
            Header: "Location",
            accessor: "state",
            Cell: cellInfo => {
                return <>
                    <img className="locationpng" src={location}></img><label> {cellInfo.data[cellInfo.row.index].state}</label>
                </>;
            }
        },
        {
            Header: "Status",
            accessor: "joined",
            Cell: cellInfo => {
               
                    if (cellInfo.row.values.joined === true) {
                        return <>

                            {/* <img src={btnjoin} className="iconsstatus" onClick={() => { handleSuscribe(cellInfo.data[cellInfo.row.index]) }}></img> */}
                            <Button style={{width: "100px", fontFamily: "Work Sans", fontSize: "14px"}} color="primary" onClick={() => { handleSuscribe(cellInfo.data[cellInfo.row.index]) }}>
                                Joined
                            </Button>
                        </>
                    } else {
                        if (cellInfo.data[cellInfo.row.index].request === true) {
                            if (cellInfo.data[cellInfo.row.index].stateRequest === "Pending") {
                                return <>

                                    {/* <img src={btnawait} className="iconsstatus" onClick={() => removeRequest(cellInfo.data[cellInfo.row.index])} ></img> */}
                                    <Button style={{width: "100px", fontFamily: "Work Sans", fontSize: "14px"}} onClick={() => removeRequest(cellInfo.data[cellInfo.row.index])} >
                                        Awaiting <br/> Approval
                                    </Button>

                                </>
                            } else {
                                return <> 
                                {/* <Button className="btn-nojoin" onClick={() => { handleSuscribe(cellInfo.data[cellInfo.row.index]) }} >Join</Button> */}
                                <Button style={{width: "100px", fontFamily: "Work Sans", fontSize: "14px"}} onClick={() => { handleSuscribe(cellInfo.data[cellInfo.row.index]) }} >
                                    Join
                                </Button>
                                </>
                            }
                        } else {

                            return <> 
                            {/* <Button className="btn-nojoin" onClick={() => { handleSuscribe(cellInfo.data[cellInfo.row.index]) }} >Join</Button> */}
                            <Button style={{width: "100px", fontFamily: "Work Sans", fontSize: "14px"}} onClick={() => { handleSuscribe(cellInfo.data[cellInfo.row.index]) }} >
                                    Join
                                </Button>
                            </>
                        }
                      
                    
                } 
               

            }
        }
    ];

    const [pageData, setPageData] = useState({
        rowData: [],
        isLoading: false,
        totalPages: 0,
        totalData: 0,
    });
    const [filter, setFilter] = useState("");
    const [filterinvitation, setFilterInvitation] = useState("");
    const [modalRequest, setModalRequest] = useState(false);
    const [modalPendingRequest, setPendingModalRequest] = useState(false);
    const [modalRemoveRequest, setRemoveModalRequest] = useState(false);
    const [modalDefaultAgency, setmodalDefaultAgency] = useState(false);
    const [stateCheckBox, setStateCheckBox] = useState({disabled: false});
    
    const [currentPage, setCurrentPage] = useState(1);
    useEffect(() => {
        setPageData((prevState) => ({
            ...prevState,
            rowData: [],
            isLoading: true,
        }));
        getData(currentPage, filter).then((info) => {
            const { totalPages, data, totalData } = info;
            setPageData({
                isLoading: false,
                rowData: data,
                totalPages,
                totalData,
            });
            setIsLoading(false);
        });
    }, [currentPage]);

    const getData = async (pageNo = 1, text) => {
        const response = await api.get(
            `/agency/getMyAgencies?page=${pageNo}&take=15&filter=${text}`, authService.getToken()
        );
        return response.data;

    };
    const RedirectAddAgency = () => {
        history.push("/agency/create");
    }
    const onFilterChanged = async (e) => {
        setIsLoading(true);
        await setFilter(e.target.value);

        getData(currentPage, e.target.value === undefined ? "" : e.target.value).then((info) => {
            const { totalPages, data, totalData } = info;
            setPageData({
                isLoading: false,
                rowData: data,
                totalPages,
                totalData,
            });
            setIsLoading(false);
        });;
       
    }
    const onFilterChangedInvitation = useCallback((e) => {
        setFilterInvitation(e.target.value);

        if(emailExist.includes(e.target.value.toLowerCase())) {
            setError('emailaddress', { type: 'exist' });
        } else {
            clearErrors("emailaddress");
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[emailExist])

    const toggleAddedRequest = () => {
        setModalRequest(false);
        

    };
    const togglePendingRequest = () => {
        setPendingModalRequest(false);


    };
    const toggleRemoveRequest = () => {
        setRemoveModalRequest(false);


    };

    const toggleModalDefaultAgency = () => {
        setmodalDefaultAgency(false);


    };

    const handleClose = () => setShowUsers(false);

    return <>
  
      
            <div className="AgenciesDiv">
                <Row>
                    <Col md="3">
                        {isSuper &&
                            <label className="titlediv">Agencies</label>
                        }
                        {!isSuper &&
                            <label className="titlediv">My Departments</label>
                        }
                    </Col>
                    {isSuper &&
                        <Col md="3">
                        </Col>
                    }
                    {!isSuper &&
                        <Col md="6">
                        </Col>
                    }

                    {isSuper &&

                        <Col md="4">

                            <div className="input-group">
                            <span className="input-group-prepend">
                                <div className="input-group-text  border-right-0"> <FontAwesomeIcon icon={faSearch} /></div>
                                </span>
                                <Input placeholder="Search agency..." onChange={onFilterChanged} value={filter === undefined ? "" : filter} type="text" className="form-control" />

                            <span className="input-group-append">

                                </span>
                            </div>

                        </Col>

                    }
                {(isSuper && AuthService.isSuperAdmin() ) &&
                        <Col md="2">
                            <button className="btn btn-primary" onClick={RedirectAddAgency}>Add Agency</button>
                        </Col>
                    }
                    {!isSuper &&
                        <Col md="3">

                        <div className="input-group">
                            <span className="input-group-prepend">
                                <div className="input-group-text  border-right-0"> <FontAwesomeIcon icon={faSearch} /></div>
                                </span>
                                <Input placeholder="Search department..." onChange={onFilterChanged} value={filter === undefined ? "" : filter} type="text" className="form-control" />

                            <span className="input-group-append">

                                </span>
                            </div>
                        </Col>

                    }

            </Row>
            {isLoading &&
                <LoaderAgency/>
            }
            {!isLoading &&
                <>
                <div>
                    <Table columns={columns} data={pageData.rowData}></Table>
                </div>
                <Pagination
                    totalRows={pageData.totalData}
                    pageChangeHandler={setCurrentPage}
                    rowsPerPage={15}
                />

                {pageData.totalData === 0 &&

                    <NoResultFoundContainer message={"No results found. Try another keywords..."} />
                }
                </>
            }
            </div>
        
        <Modal size="lg" show={modal}>
            <form onSubmit={sendUserInvitation}>
                <ModalHeader>
                    Add new officer
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col xl="3">
                            <Label>Email officer</Label>
                        </Col>
                        <Col>
                            <div className="input-group mb-3">
                                <input id="email" name="email" onChange={onFilterChangedInvitation} value={filterinvitation ?? ""} required type="email" className="form-control"  aria-label="Email officer" aria-describedby="basic-addon1" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" />
                            </div>

                            {errors.emailaddress && errors.emailaddress.type === "exist" && 
                                <div className="input-group mb-3">
                                    <span className="text-danger">Email address is already exist</span>
                                </div>
                            }
                        </Col>
                    </Row>
                    <Row>
                        {(validation !== undefined && validation.error === true) && <p className="alert alert-danger">{validation.message}</p>}
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary"type="submit" disabled={errors.emailaddress && errors.emailaddress.type === "exist"}>Send invitation</Button>
                    {' '}
                    <Button onClick={toggle}>Cancel</Button>
                </ModalFooter>
            </form>
        </Modal>


        <Modal size="sm" show={modalDelete.show}>
          
            <ModalBody>
                <Row>
                    <label className="bodyagencies" >Are you sure you want to delete the {modalDelete.name} agency?</label>
                </Row>

            </ModalBody>
            <ModalFooter>
                
                <Button
                    className="btncancel"
                    onClick={toggleDelete}>
                    Cancel
                </Button>
                {' '}
                <Button
                    className="btnaccept"
                    color="primary"
                    onClick={() => DeleteAgency(modalDelete.id)}
                >
                    Delete
                </Button>
              
            </ModalFooter>
        </Modal>
        <Modal size="mb" show={showUsers} onHide={handleClose}>
            <ModalHeader closeButton>
                <h5>Manage</h5>
            </ModalHeader>
            <ModalBody>
                <ManageAdmins Agency={manageAgency} />
            </ModalBody>
            
        </Modal>
        <ModalAlert Toggle={toggleInvitation} Message="An invitation was sent" Show={modalInvitation} />
        <ModalAlert Toggle={toggleAddedRequest} Message="Your membership application is now approved. You can now view the posts of the department." Show={modalRequest} />
        <ModalAlert Toggle={togglePendingRequest} Message="Thank you for applying to join the department! Your application has been sent and will be reviewed by an administrator for approval." Show={modalPendingRequest} />
        <ModalAlert Toggle={toggleRemoveRequest} Message="Your application has been canceled." Show={modalRemoveRequest} />
        <ModalAlert Toggle={toggleModalDefaultAgency} Message="You are not allowed to sign out from your main agency" Show={modalDefaultAgency} />

    </>;

}

export default RequestedAgencies;