import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import authService from "../../../services/auth.service";
import { Container } from 'reactstrap';
import { NavMenu } from '../../../components/NavMenu';

const NewResetPasswordGenerate = () =>
{

    useEffect(() => {
        const windowUrl = window.location.search;
        const params = new URLSearchParams(windowUrl);
        authService.resetPasswordExpiration(params.get('userid')).then((result) => {
            if (result.data !== undefined && result.data.url)
            {
                window.location.replace(result.data.url);

            }
        });
    },[]);

    return (<div className="DivLogOut">
                <NavMenu />
                <Container>
                    <>
                        <h4>Reset password, sending information wait... <span><img src="static/media/spinner.d3fbaf2b.gif"></img></span></h4>
                    </>
                </Container>
           </div>) 
}

export default NewResetPasswordGenerate;