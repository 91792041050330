import { Button, Form, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { useForm } from 'react-hook-form';
import { useState } from "react";
import api from "../../../services/api";
import AuthService from "../../../services/auth.service";
import { NotificationContainer, NotificationManager } from 'react-notifications';
const AddFolderModal = (props) => {

    const { register, handleSubmit, setValue, formState: { errors }, setError, reset } = useForm({
        mode: 'onChange'
    });
    const [loading, setLoading] = useState(false);
    const [exists, setExists] = useState(false);
    const SaveInformation = (data) => {
        setLoading(true);
        var formData = new FormData();

        formData.append("Name", data.name);
        formData.append("Id", 0);

        api.post("releatedcrimes/save", formData, AuthService.getTokenPost()).then((result) => {
            if (result.data && result.data.status === 0) {
                reset({ name: "" });
            
                setExists(false);
                
                NotificationManager.success('Success', 'Folder added', 5000);
                props.closeModal();
               
            }
            if (result.data && result.data.status === -1) {
                setExists(true);
            }

            setLoading(false);
            
        }, error => {
            
            setLoading(false);
        });
    }

    return <>
        <Modal size="lg" isOpen={props.show}>

            <ModalHeader>
                <h4> Add new folder</h4>
            </ModalHeader>
            <ModalBody>
                <Form onSubmit={handleSubmit(SaveInformation)}>
                    <div className="row accountinp">
                        <div className="col-3">
                            <label>Name:*</label>
                        </div>
                        <div className="col-6">
                            <input type="text" className={errors.name ? 'form-control is-invalid' : 'form-control'} {...register("name", { required: true, maxLength: 200 })} placeholder="Name..." />
                            {errors.name && errors.name.type === "required" && <span className="text-danger">This is required</span>}
                            {exists && <p className="alert alert-danger">The folder name already exists.</p>}
                        </div>
                    </div>
                    <ModalFooter>
                        <Button
                            type="submit"
                            color="primary"
                            disabled={loading}
                        >
                            { "Add"}
                            {loading && (
                                <span className="spinner-border spinner-border-sm"></span>
                            )}
                        </Button>
                        {' '}
                        <Button onClick={() => {
                            props.closeModal()
                        }}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </Form>

            </ModalBody>


        </Modal>
    </>
}

export default AddFolderModal;