import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import AuthService from "../../../../services/auth.service";
import '../Login.css';

const LoginOptions = (props) => {
    let history = useHistory();
    const [loading, setLoading] = useState(false);
    const [code, setCode] = useState();
    const [loadingCode, setLoadingCode] = useState(false);
    const [validation, setValidation] = useState({
        email: "",
        password: "",
        code: ""
    });

    const [isExecuteLogin2FA,setIsExecuteLogin2FA] = useState(false);

    const onChangeCode = e => {
        setCode(e.target.value);
    }

    const checkValidationCode = () => {
        var isValid = true;
        if (code === undefined || !code.trim()) {
            setValidation({
                email: "",
                password: "",
                code: "Code is required"
            });
            isValid = false;
        }

        return isValid;
    }

    const success = element => {
        if (element.requiresTwoFactor && element.requiresTwoFactor === true) {
            setLoading(false);
            props.EnableReqCode();
        }
        else if (element.roles !== "") {
            history.push("/home");
            history.go(0);
        } else {
            history.push("/");
            history.go(0);
        }

    }

    const handleCode = (e) => {
        setLoadingCode(true);

        var isValid = checkValidationCode();

        if (isValid) {
            var data = {
                UserName: props.username,
                Code: code
            };

            AuthService.login2FA(data).then(
                (element) => {
                    success(element);
                    setLoading(false);

                },
                error => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();



                    setValidation({
                        ...validation,
                        code: "Invalid code",
                    });
                    setLoading(false);
                }
            );
        }
        else {
            setLoading(false);
        }
    }

    const handleKeyPress = (event) => {
        if (event.charCode === 13) {
            setLoadingCode(true);
            var isValid = checkValidationCode();
            if (isValid) {
                setIsExecuteLogin2FA(true);
            } else {
                setLoading(false);
            }
            event.preventDefault();
        }
    }
    
    useEffect(() => {
        if(isExecuteLogin2FA) {
            var data = {
                UserName: props.username,
                Code: code
            };
            AuthService.login2FA(data).then(
                (element) => {
                    success(element);
                    setLoading(false);
                },
                error => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                        console.log(resMessage);
                        setValidation({
                            email: "",
                            password: "",
                            code: "Invalid code"
                        });

                    setLoading(false);
                    setIsExecuteLogin2FA(false);
                }
            );
           
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[isExecuteLogin2FA]);

    return (
        <Form >
            {props.codeMultiOptions && <a href="#" onClick={props.setSelectOptions}>Try other option</a>}
            <label htmlFor="username" className="labellogin">We have sent you a code to your {props.codeOptionText}.</label>
            <div className="form-group login">
                <label htmlFor="username" className="login-label">Code</label>
                <Input
                    id="3"
                    type="text"
                    className="form-control"
                    placeholder="Enter code here"
                    name="username"
                    value={code}
                    onChange={onChangeCode}
                    onKeyPress={handleKeyPress}

                />

                {validation.code && <p className="alert alert-danger">{validation.code}</p>}
            </div>
            <div className="form-group button">
                <button type="button"
                    onClick={handleCode}
                    className="btn btn-primary btn-block login-button"
                    disabled={loading}
                >
                    {loading && (
                        <span className="spinner-border spinner-border-sm"></span>
                    )}
                    <span>Validate code</span>
                </button>
            </div>
        </Form>
    );
};

export default LoginOptions;