import React from "react";
import { format } from 'date-fns';

const CardPostFileList = (props) => {

    return (
    <>
        <h2>Files</h2> <hr></hr>
        {props.listfiles.map((x) => (
            <div className="row cardpost">

                <div className="row">
                    <div className="col-9">
                        {x.incident.incidentNum} - {x.incident.address.address1}
                    </div>
                </div>
                <div className="row">
                    <div className="col-9">
                        {x.file.file.name}
                    </div>
                    <div className="col-3">
                        {format(new Date(x.file.incident.date), 'dd/MM/yyyy HH:mm')}
                    </div>
                </div>
                <div className="row">
                    <div className="col-9">
                        {(x.incident.agency !== undefined && x.incident.agency !== null ?  x.incident.agency.name : "")} - {x.incident.incidentCategory.name}
                    </div>
                    <div className="col-3">
                        <a target="_blank" href={x.file.file.path} className="" rel="noreferrer">View File</a>
                    </div>
                </div>

            </div>
        ))}
    </>);

}


export default CardPostFileList;