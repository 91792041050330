/*eslint unicode-bom: ["error", "always"]*/
import React, { useState, useEffect } from "react";
import Pagination from "../../components/Common/pagination";
import Table from "../../components/Common/table";
import { faEdit, faTrash, faFolder,faCalendarDays } from '@fortawesome/free-solid-svg-icons'
import { Button, NavLink, Modal, Row } from "reactstrap"
import { ModalBody, ModalFooter, ModalHeader, Col } from "react-bootstrap"
import { library } from '@fortawesome/fontawesome-svg-core';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import api from "../../services/api";
import { useParams } from 'react-router-dom';
import AuthService from "../../services/auth.service";
import { NotificationContainer, NotificationManager } from 'react-notifications';

library.add(faEdit, faTrash, faFolder, faCalendarDays);

const RelatedCrimes = () => {
    const { id } = useParams();
    const [search, setsearch] = useState("");
    const [modalDelete, setModalDelete] = React.useState({
        show: false,
        id: ""
    });
    const [datestart, setdatestart] = useState("");
    const [dateend, setdateend] = useState("");
    const OnChangeStart = (e) => {
        setdatestart(e.target.value);
    }
    const OnChangeEnd = (e) => {
        setdateend(e.target.value);
       
    }
    const DeletePostRelate = () => {
        setModalDelete({
            ...modalDelete,
            show: !modalDelete.show
        });
        api.post("/releatedcrimes/deletepost", {
            Id: modalDelete.id,
        }, AuthService.getTokenPost()).then(response => {
            NotificationManager.success('The post was removed', 'Success', 5000);
            SearchRelate();
        });
     
    }
    const toggleDelete = (data) => {
        setModalDelete({
            ...modalDelete,
            show: !modalDelete.show,
            id: data.id
        });

    }
    const OnChangeSearch = (e) => {
        setsearch(e.target.value);
        getData(currentPage).then((info) => {
            const { totalPages, data, totalData } = info;
            setPageData({
                isLoading: false,
                rowData: data,
                totalPages,
                totalData,
            });
        });
    }
    const [pageData, setPageData] = useState({
        rowData: [],
        isLoading: false,
        totalPages: 0,
        totalData: 0,
    });
    const [currentPage, setCurrentPage] = useState(1);
    useEffect(() => {

    });
    useEffect(() => {
        setPageData((prevState) => ({
            ...prevState,
            rowData: [],
            isLoading: true,
        }));
        getData(currentPage).then((info) => {
            const { totalPages, data, totalData } = info;
            setPageData({
                isLoading: false,
                rowData: data,
                totalPages,
                totalData,
            });
        });
    }, [currentPage, datestart,dateend]);
    const SearchRelate = () => {
        getData().then((info) => {
            const { totalPages, data, totalData } = info;
            setPageData({
                isLoading: false,
                rowData: data,
                totalPages,
                totalData,
            });
        });
    }
    const getData = async (pageNo = 1) => {
        const response = await api.get(
            `/releatedcrimes/getreleated?page=${pageNo}&id=${id}&search=${search}&dates=${datestart}&datee=${dateend}`
            , AuthService.getToken());
        return response.data;

    };
    const columns = [
        {
            Header: "View",
            accessor: "id",
            Cell: cellInfo =>
                <NavLink tag={Link} className="col-4" to={"/post/view/" + cellInfo.data[cellInfo.row.index].incidentId}><FontAwesomeIcon icon={faFolder} />VIEW POST</NavLink>
        },
        {
            Header: "Type",
            accessor: "type",
        },
        {
            Header: "Name",
            accessor: "name",
        },
        {
            Header: "Location",
            accessor: "location",
        },
        {
            Header: "Comment",
            accessor: "comment",
        },
        {
            Header: "Date Added",
            accessor: "date",
        },
        {
            Header: "Remove",
            accessor: "",
            Cell: cellInfo => <FontAwesomeIcon icon={faTrash} onClick={() => toggleDelete(cellInfo.data[cellInfo.row.index])} />
        },

    ];
    return <>
        <h1>Related crimes</h1>
        <div className="row">
            <div className="col-3">
                <div className="input-group mb-12">
                    <input type="text" className="form-control" placeholder="Keywords search..." onChange={OnChangeSearch} value={search} />
                    <div className="input-group-append">
                        <span className="input-group-text" id="basic-addon2" onClick={SearchRelate}>GO!</span>
                    </div>
                </div>
            </div>
            <div className="col-1"></div>
            <div className="col-4">

                <div className="input-group mb-5">
                    <label className="labelinputgroup">From:</label>
                    <div className="input-group-prepend">
                        <span className="input-group-text" id="basic-addon1">  <FontAwesomeIcon icon={faCalendarDays} /></span>
                    </div>
                    <input type="datetime-local" className="form-control" placeholder="Incident Date and Time" aria-label="Username" aria-describedby="basic-addon1" value={datestart} onChange={OnChangeStart} />
                </div>
            </div>
            <div className="col-4">

                <div className="input-group mb-5">
                    <label className="labelinputgroup">To:</label>
                    <div className="input-group-prepend">
                        <span className="input-group-text" id="basic-addon1">  <FontAwesomeIcon icon={faCalendarDays} /></span>
                    </div>
                    <input type="datetime-local" className="form-control" placeholder="Incident Date and Time" aria-label="Username" aria-describedby="basic-addon1" value={dateend} onChange={OnChangeEnd} />
                </div>
            </div>
        </div>
        <div>
            
            <div>
                <Table
                    columns={columns}
                    data={pageData.rowData}
                    isLoading={pageData.isLoading}
                />
            </div>
            <Pagination
                totalRows={pageData.totalData}
                pageChangeHandler={setCurrentPage}
                rowsPerPage={15}
            />
        </div>
        <Modal size="lg" isOpen={modalDelete.show}>
            <ModalHeader>
                Delete Post
            </ModalHeader>
            <ModalBody>
                <Row>
                    <label >Are you sure you want to delete this post?</label>
                </Row>

            </ModalBody>
            <ModalFooter>
                <Button
                    color="primary"
                    onClick={() => DeletePostRelate(modalDelete.id)}
                >
                    Delete
                </Button>
                {' '}
                <Button onClick={toggleDelete}>
                    Cancel
                </Button>
            </ModalFooter>
        </Modal>
        <NotificationContainer />
    </>
}
export default RelatedCrimes;