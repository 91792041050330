/*eslint unicode-bom: ["error", "always"]*/
import React, { useState, useEffect, useCallback } from "react";
import Select from 'react-select';
import './Account.css';
import { Row, Col, Modal, Button } from "reactstrap";
import InputMask from "react-input-mask";
import { useForm } from 'react-hook-form';
import { Container, ModalBody, ModalFooter, ModalHeader, Tab, Tabs, Form} from "react-bootstrap";
import AuthService from "../../services/auth.service";
import { Link, useHistory } from 'react-router-dom';
import ModalAlert from "../Common/ModalAlert";
import authService from "../../services/auth.service";
import api from "../../services/api";
import 'react-notifications/lib/notifications.css';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import ProfilePicture from "../Profile/ProfilePictureUpload";
import locationIcon from "../../assets/locationBlue.svg";
import editProfile from "../../assets/EditProfilePic.png";
import AdminPreview from "../Admin/AdminPreview";
import Autocomplete from "react-google-autocomplete";

import { QueryString } from "../Common/standardfunction";


const exportDataListSelection = [
    { value: 1, label: 'All my data' },
    { value: 2, label: 'Just my posts' },
    { value: 3, label: 'Just my messages' }
]

const ExportDataDropDownField = ({data, onChange}) => {
    return (
        <Select className="basic-single"
                classNamePrefix="Export data"
                components={{IndicatorSeparator: () => null}}
                isDisabled={false}
                isLoading={false}
                isRtl={false}
                isSearchable={true}
                defaultValue={data[0]}
                name="color"
                onChange={onChange}
                options={data}
                styles={{control: styles => 
                    ({ ...styles
                        , minHeight: "45px"
                        , borderColor: "#cccccc"
                        , boxShadow: "0 0 0 0px #cccccc"
                        , "&:hover": {
                            borderColor: "#cccccc"
                        }}
                    )
                }}
    />);
}

const Account = () => {
    const GOOGLE_API = "AIzaSyA3KsiVMTTNXNNWrViDI8qwSYFfbRkaQI4";
    const { register, handleSubmit, setValue, formState: { errors }, setError } = useForm({
        mode: 'onChange'
    });

    const {
        register: register2,
        formState: { errors: errors2 },
        handleSubmit: handleSubmit2,
    } = useForm({
        mode: "onChange",
    });

    const {
        register: register3,
        formState: { errors: errors3 },
        handleSubmit: handleSubmit3,reset:reset3
    } = useForm({
        mode: "onChange",
    });

    let history = useHistory();
    const [modalDelete, setModalDelete] = React.useState(false);
    const [Deleted, setDeleted] = React.useState(false);
    const [list, setList] = useState([]);
    const [listState, setListState] = useState([]);
    const [loading, setLoading] = useState(false);
    const [validationErros, setValidationErros] = useState({ matchError: false, rulesError: false });
    const [serverErrors, setServerErrors] = useState();

    let isSuperAdmin = false;
    // eslint-disable-next-line no-unused-vars
    const [userRoles, setUserRoles] = useState(AuthService.getRoles());
    if (userRoles.length > 0 && userRoles.includes("SuperAdmin")) {
        isSuperAdmin = true;
    }

    
    
    const [account, setAccount] = useState({
        name: "",
        departament:"",
        division: "",
        phone: "",
        email: "",
        status: "",
        notificationAgency: false,
        notificationAgencySign: false,
        notificationChat: false,
        notificationUpdateMyPost: false,
        notificationReportedPost: false,
        notificationRequests:false,
        allowSearchMyProfile: false,
        showEmail: true,
        showPhone: true,
        selectedImage: null,
        twofactorAuthentication: false,
        imageRemoved: false,
        agencyInfo: {},
        addressId:"",
        nameAddress: "",
        latitude: 0,
        longitude: 0,
        state: ""
        
    });
    const [location, setLocation] = useState({
    });

    const onChangeLocation = e => {
        setLocation({
            ...location, e
        });
    }

    const onChangeInputMap = e => {
        setAccount({
            ...account,
            locationinput: e.target.value
        });

    }

    const DeleteAccountModal = () => {
        setModalDelete(true);
    }
    const DeleteAccount = () => {
        const formData = new FormData();
        api.post("/account/delete", formData, AuthService.getToken())
            .then(response => {
                setDeleted(true);
            });
    }

    const toggleDeleted = () => {
        setDeleted(false);
    };
    const toggleModal = () => {
        setModalDelete(false);
    };

    const SaveInformation = (data) => {
        save(data,true);
    }

    const SavePrivacy = useCallback(() => {
        setLoading(true);

        const formData = new FormData();
        formData.append("UserId", account.userId);
        formData.append("AllowSearchMyProfile", account.allowSearchMyProfile);
        formData.append("NotificationAgency", account.notificationAgency);
        formData.append("NotificationAgencySign", account.notificationAgencySign);
        formData.append("NotificationUpdateMyPost", account.notificationUpdateMyPost);
        formData.append("NotificationRequests", account.notificationRequests);
        formData.append("NotificationReportedPost", account.notificationReportedPost);
        formData.append("NotificationChat", account.notificationChat);
        formData.append("TwofactorAuthentication", account.twofactorAuthentication);

        api.post("/account/savePrivacy", formData, AuthService.getToken())
        .then(response => {
            if (response.data.id && response.data.id !== 0) {
                NotificationManager.success('Success', 'DATA UPDATED CORRECTLY', 5000);
            } else {
                NotificationManager.error("ERROR WHILE SAVING PRIVACY INFORMATION", 5000);
                console.log(response);
            }
            setLoading(false);
        })
        .catch((error) => {
            NotificationManager.error("ERROR WHILE SAVING PRIVACY INFORMATION", 5000);
            console.log(error);
        });
    
    },[account])

    const save = useCallback((data,isSaveInformation) => {
        setLoading(true);

        var action = 0;
        if (data.name) {
            action = 1;
        } else
        {
            action = 2;
        }

        if(isSaveInformation !== undefined) {
            if(isSaveInformation === true) {
                action = 1;
            }
        }

        let isvalid = true;
        if (action === 1 && data.name.trimEnd() === "") {
            setError('name', { type: 'required' });
            isvalid = false;
        }

        if (action === 1 && data.phone.trimEnd() === "") {
            setError('phone', { type: 'required' });
            isvalid = false;
        }

        let _tempPhone = data.phone.replace(/\D/g,'');

        if (action === 1 && _tempPhone.length < 11) {
            setLoading(false);
            setError('phone', { type: 'invalid-format' });
            isvalid = false;
        }
        if (action === 1 && data.division === "") {
            setError('division', { type: 'required' });
            isvalid = false;
        }
        if (!isvalid)
            return;
        const formData = new FormData();
        formData.append("Name", data.name);
        formData.append("Departament", "-");
        formData.append("Division", data.division);
        formData.append("Phone", data.phone);
        formData.append("Email", account.email);
        formData.append("Status", account.status);
        formData.append("UserId", "-");
        formData.append("NotificationAgency", account.notificationAgency);
        formData.append("NotificationAgencySign", account.notificationAgencySign);
        formData.append("NotificationChat", account.notificationChat);
        formData.append("NotificationUpdateMyPost", account.notificationUpdateMyPost);
        formData.append("NotificationReportedPost", account.notificationReportedPost);
        formData.append("NotificationRequests", account.notificationRequests);
        formData.append("AllowSearchMyProfile", account.allowSearchMyProfile);
        formData.append("ShowPhone", account.showPhone);
        formData.append("ShowEmail", account.showEmail);
        formData.append("FormFile", account.selectedImage);
        formData.append("TwofactorAuthentication", account.twofactorAuthentication);
        formData.append("ImageRemoved", account.imageRemoved);
        formData.append("AddressId", location.e ? location.e.place_id : account.addressId === undefined || account.addressId === null ? "" : account.addressId );
        formData.append("NameAddress", location.e ? location.e.formatted_address : account.locationinput === undefined || account.locationinput === null ? "" : account.locationinput);
        formData.append("Latitude", location.e ? location.e.geometry.location.lat() : account.latitude === null || account.latitude === undefined ? 0 : account.latitude);
        formData.append("Longitude", location.e ? location.e.geometry.location.lng() : account.longitude === null || account.longitude === undefined ? 0 : account.longitude);
        formData.append("State", location.e ? location.e.address_components[2].long_name : account.state === null || account.state === undefined ? "" : account.state );

        if (action === 1) {
            api.post("/account/saveProfile", formData, AuthService.getToken())
                .then(response => {
                    if (response.data.id && response.data.id !== 0) {
                        NotificationManager.success('Success', 'DATA UPDATED CORRECTLY', 5000);
                    }
                    setLoading(false);

                });
        }
        // else if (action === 2) {
        //     api.post("/account/savePrivacy", formData, AuthService.getToken())
        //         .then(response => {
        //             if (response.data.id && response.data.id !== 0) {
        //                 NotificationManager.success('Success', 'DATA UPDATED CORRECTLY', 5000);
        //             }
        //             setLoading(false);

        //         });
        // }
        else {
            api.post("/account/save", formData, AuthService.getToken())
                .then(response => {
                    if (response.data.id && response.data.id !== 0) {
                        NotificationManager.success('Success', 'DATA UPDATED CORRECTLY', 5000);
                    }

                    setLoading(false);
                });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[account,location])


    const onChangeName = e => {
        setAccount({
            ...account,
            name: e.target.value.trimEnd()

        });
    }
    const onChangePhone = e => {
        setAccount({
            ...account,
            phone: e.target.value.trimEnd()

        });
    }
    const onChangeEmail = e => {
        setAccount({
            ...account,
            email: e.target.value

        });
    }

    const onChangePostPublished = e => {
        setAccount({
            ...account,
            notificationAgency: e.target.checked

        });
    }
    const onChangePostPublishedSign = e => {
        setAccount({
            ...account,
            notificationAgencySign: e.target.checked

        });
    }
    const onChangeStatus = e => {
        setAccount({
            ...account,
            status: e.target.value

        });
    }
    const onChangeTwofactorAuthentication= e => {
        setAccount({
            ...account,
            twofactorAuthentication: e.target.checked

        });
    }

    const onChangeAllowSearch = e => {
        setAccount({
            ...account,
            allowSearchMyProfile: e.target.checked

        });
    }

    const onChangeChat = e => {
        setAccount({
            ...account,
            notificationChat: e.target.checked

        });
    }
    const onChangeUpdatePost = e => {
        setAccount({
            ...account,
            notificationUpdateMyPost: e.target.checked

        });
    }
    const onChangeReportedPost = e => {
        setAccount({
            ...account,
            notificationReportedPost: e.target.checked

        });
    }
    const onChangeRequests = e => {
        setAccount({
            ...account,
            notificationRequests: e.target.checked

        });
    }
    const onChangeDivision = e => {
        setAccount({
            ...account,
            division: e.target.value

        });
    }

    const onChangeShowEmail = e => {
        setAccount({
            ...account,
            showEmail: e.target.checked

        });
    }

    const onChangeShowPhone = e => {
        setAccount({
            ...account,
            showPhone: e.target.checked

        });
    }

    const onChangeSelectedImage = e => {
        setAccount({
            ...account,
            selectedImage: e,
            imageRemoved: e === null || e === undefined

        });
    }

    const changePassword = (data) =>
    {
        setLoading(true);

        const regex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
        if (!data.password || regex.test(data.password) === false) {
            setValidationErros({
                ...validationErros,
                rulesError :true
            });
            setLoading(false);
            return;

        } else if (data.password !== data.newPassword) {
            setValidationErros({
                ...validationErros,
                matchError: true
            });
            setLoading(false);
            return;
        }

        setValidationErros({
            rulesError: false,
            matchError: false
        });
        const formData = new FormData();
        formData.append("Password", data.password);
        formData.append("OldPassword", data.oldPassword);

        api.post("/authenticate/resetPasswordProfile", formData, AuthService.getToken())
            .then(data => {
                if (data.data && data.data.errors && data.data.errors !== "") {
                    setServerErrors(data.data.errors);
                } else {
                    reset3({ password: "", newPassword: "", oldPassword:"" });
                    NotificationManager.success('Success', 'PASSWORD UPDATED CORRECTLY', 5000);
                    
                    setServerErrors(undefined);
                }
                setLoading(false);
            });
    }

    const getAccount = () => {
        setLoading(true);
        api.get("/account/get")
            .then(response => {

                setAccount({
                    ...account,
                    name: response.data.name.trimEnd(),
                    departament: response.data.departament,
                    phone: response.data.phone.trimEnd(),
                    email: response.data.email,
                    division: response.data.division,
                    status: response.data.status,
                    notificationAgency: response.data.notificationAgency,
                    notificationAgencySign: response.data.notificationAgencySign,
                    notificationChat: response.data.notificationChat,
                    notificationUpdateMyPost: response.data.notificationUpdateMyPost,
                    notificationReportedPost: response.data.notificationReportedPost,
                    notificationRequests: response.data.notificationRequests,
                    allowSearchMyProfile: response.data.allowSearchMyProfile,
                    showEmail: response.data.showEmail,
                    showPhone: response.data.showPhone,
                    selectedImage: response.data.urlPic,
                    twofactorAuthentication: response.data.twofactorAuthentication,
                    agencyInfo: response.data.agencyInfo,
                    addressId: response.data.addressId,
                    nameAddress: response.data.nameAddress,
                    latitude: response.data.latitude,
                    longitude: response.data.longitude,
                    state: response.data.state
                });
                setValue('name', response.data.name.trimEnd().trimStart());
                setValue('phone', response.data.phone.trimEnd().trimStart());
                setValue('division', response.data.division);
                setLoading(false);
            });
    }
    useEffect(() => {
        getAccount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
    
        api.get(
            "/officer/divisions",
            authService.getToken())
            .then((response) => {
                setList(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }, [setList]);

    useEffect(() => {

        api.get(
            "/officer/statusofficer",
            authService.getToken())
            .then((response) => {
                setListState(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }, [setListState]);

    const uploadPicAgency = (args) => {
        var data = new FormData();
        setLoading(true);
        data.append("FormFile", args.target.files[0]);
        data.append("AgencyId", account.agencyInfo.id);

        api.post("/account/saveAgencyProfilePic", data, AuthService.getToken())
            .then(response => {
                if (response.data.id && response.data.id !== 0) {
                    NotificationManager.success('Success', 'Profile picture updated successfully', 5000);
                    getAccount();
                }
                setLoading(false);
            });
    }

    // eslint-disable-next-line no-unused-vars
    const [currentUser, setCurrentUser] = useState(AuthService.getCurrentUser());

    // eslint-disable-next-line no-unused-vars
    const [selectedExportData, setSelectedExportData] = useState(1);
    const [isExportingData, setIsExportingData] = useState(false);

    const onChangeExportData = (e) => {
        setSelectedExportData(e.value);
    }

    const exportData = () => {
        setIsExportingData(true);
        const queryStringResult = QueryString({ typeId: selectedExportData});

        api.get("/account/exportData" + queryStringResult,
            {
            responseType: "blob",
            },
            authService.getToken())
            .then((response) => {
                const link = document.createElement('a');
                const url = URL.createObjectURL(response.data);
                link.href = url;
                link.download = 'myData.zip';
                link.click();
                setIsExportingData(false);
            })
            .catch((error) => {
                setIsExportingData(false);
                console.log(error);
            });
    }

    return (
        <>
            <Container className="customContainer" fluid>
                <br />
                <div >
                    <Row>
                        <Col>
                            <h5>Profile & Privacy</h5>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Tabs >
                                <Tab eventKey="Mydeparment" title="My Department">
                                    <br />

                                    <Row>
                                        <Form>
                                            <div className="card-header-profile">
                                               
                                                <img alt="" className="card-img-profile" src={account.agencyInfo.picture} />
                                                {
                                                    authService.isAdmin() === true && 
                                                    <>
                                                     <input disable={loading} id="input-file" className="input-file" onChange={uploadPicAgency} type="file" accept="image/*" />
                                                     <label for="input-file" className="label-input-file">
                                                        <img alt="" src={editProfile}/>
                                                        </label>
                                                    </>
                                                }
                                            </div>
                                        </Form>
                                        
                                            <div className="divContent">
                                                <br />
                                                <br />
                                                <div className="centerDivProfile">
                                                    <Row>
                                                        <div className="d-flex justify-content-center">
                                                            <h4>{account.agencyInfo.name}</h4>
                                                        </div>
                                                    </Row>
                                                    <Row>
                                                        <Col className="d-flex justify-content-center lg-1">
                                                            <object data={locationIcon} width="14" height="17"> </object> &nbsp;{account.agencyInfo.address}
                                                        </Col>
                                                    </Row>
                                                </div>
                                          
                                               <br/>
                                            <div className="row justify-content-md-center">

                                                    <div className="col-md-auto">
                                                        <div className="col-xs-1 center-block">
                                                            <div className="textCenter">
                                                                    <label className="textCenter textSecondary">Members:</label>
                                                                </div>
                                                            <div className="textCenter">
                                                                    <label className="textCenter text">{account.agencyInfo.officers} officers</label>
                                                                </div>
                                                        <br />
                                                        {
                                                            account.agencyInfo &&   account.agencyInfo.email && account.agencyInfo.email !== "" &&
                                                            <>
                                                                <div className="textCenter">
                                                                    <label className="textCenter textSecondary">Email</label>

                                                                </div>
                                                                <div className="textCenter">
                                                                    <label className="textCenter textBlue"><a href={"mailto:" + account.agencyInfo.email}>{account.agencyInfo.email}</a></label>
                                                                </div>
                                                            </>
                                                        }
                                                        </div>
                                                </div>

                                                    <div className="col-md-auto">
                                                        <div className="col-xs-1 center-block">
                                                            <div className="textCenter">
                                                                <label className="textCenter textSecondary">Post:</label>
                                                            </div>
                                                            <div className="textCenter">
                                                                <label className="textCenter text">{account.agencyInfo.posts} published</label>
                                                            </div>
                                                        <br />
                                                        {
                                                            account.agencyInfo && account.agencyInfo.url && account.agencyInfo.url !== "" &&
                                                            <>
                                                            <div className="textCenter">
                                                                <label className="textCenter textSecondary">Website:</label>
                                                            </div>
                                                            <div className="textCenter">
                                                                    <label className="textCenter textBlue">
                                                                       
                                                                        <Link to={{ pathname: account.agencyInfo.url}} target="_blank" >{account.agencyInfo.url}</Link>
                                                                    </label>

                                                                </div>
                                                            </>
                                                        }
                                                            
                                                        </div>
                                                </div>
                                                {
                                                    account.agencyInfo && account.agencyInfo.phone && account.agencyInfo.phone !== "" &&
                                                    <div className="col-md-auto">

                                                        <div className="col-xs-1 center-block">
                                                            <div className="textCenter">
                                                                <label className="textCenter textSecondary">Phone:</label>
                                                            </div>
                                                            <div className="textCenter">
                                                                <label className="textCenter text"><a href={"tel:" + account.agencyInfo.phone}>{account.agencyInfo.phone}</a></label>
                                                            </div>

                                                        </div>

                                                    </div>
                                                }
                                               
                                                </div>
                                                <br />
                                            <hr className="hrProfile" />
                                            <br/>
                                            <Row className="hrProfile">
                                                <Col>
                                                    <div>
                                                        <div>
                                                            <label className="textCenter textSecondary">Admin:</label>
                                                        </div>
                                                        {

                                                            account.agencyInfo && account.agencyInfo.admins ?
                                                                account.agencyInfo.admins.map((item) => {

                                                                    return (

                                                                        <AdminPreview key={item.id} data={item} /> 

                                                                    );
                                                                }

                                                                )
                                                                : ""
                                                        }
                                                           
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                        
                                    </Row>
                                    <br/>
                                   
                                        

                                </Tab>
                                <Tab eventKey="Profile" title="Profile">
                                    <br />
                                    <form key={1} onSubmit={handleSubmit(SaveInformation)}>
                                        <Row>
                                            <Col lg="2">
                                                <div >
                                                    <ProfilePicture name={account.name} pictureChanged={onChangeSelectedImage} selectedImage={account.selectedImage} />
                                                </div>
                                            </Col>
                                            <Col lg="10">
                                                <Row>
                                                    <Col>
                                                       
                                                        <Form.Label className="text" >Name</Form.Label>
                                                        <Form.Control
                                                            type="text" className={errors.name ? 'form-control is-invalid' : 'form-control'} {...register("name", { required: true, maxLength: 20 })} placeholder="Name..." />
                                                        {errors.name && errors.name.type === "required" && <span className="text-danger">This is required</span>}
                                                       
                                                    </Col>
                                                    <Col>
                                                        <Row>
                                                            <Col>
                                                                <Form.Label className="text">Phone</Form.Label>
                                                            </Col>
                                                            <Col lg="3">
                                                                <div className="row">
                                                                    <div className="col-6">
                                                                        <label className="labelText">Public</label>
                                                                    </div>
                                                                    <div className="col-1 toggle-switch">
                                                                        <div className="toggle-switch">
                                                                            <input id="phone" className="toggle-switch-1" checked={account.showPhone} onChange={onChangeShowPhone} type="checkbox" />
                                                                            <label for="phone"></label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </Row>

                                                        <InputMask placeholder="Phone..." className={errors.phone ? 'form-control is-invalid' : 'form-control'} {...register("phone", { required: true })} mask="+1\ 999 999 9999" />
                                                        {errors.phone && errors.phone.type === "invalid-format" && <span className="text-danger">Invalid format</span>}
                                                        {errors.phone && errors.phone.type === "required" && <span className="text-danger">This is required</span>}
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <Form.Label className="text">
                                                            Division
                                                        </Form.Label>
                                                        <select defaultValue="" className={errors.division ? 'form-control is-invalid' : 'form-control'} {...register("division", { required: true })}>
                                                            <option value="" disabled>Select</option>
                                                            {list.map((item) => (
                                                                <option key={item.id} value={item.id}>{item.name}</option>
                                                            ))}
                                                        </select>
                                                        {errors.division && errors.division.type === "required" && <span className="text-danger">This is required</span>}
                                                    </Col>
                                                    <Col>
                                                        <Row>
                                                            <Col>
                                                                <Form.Label className="text">
                                                                    Email
                                                                </Form.Label>
                                                            </Col>
                                                            <Col lg="3">
                                                                <div className="row">
                                                                    <div className="col-6">
                                                                        <label className="labelText">Public</label>
                                                                    </div>
                                                                    <div className="col-1 toggle-switch">
                                                                        <div className="toggle-switch">
                                                                            <input id="email" type="checkbox" className="toggle-switch-1" checked={account.showEmail} onChange={onChangeShowEmail} />
                                                                            <label for="email"></label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Col>

                                                        </Row>

                                                        <input readOnly type="text" className="form-control" placeholder="Email..." onChange={onChangeEmail} value={account.email} />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <Form.Label className="text">
                                                            Address
                                                        </Form.Label>
                                                        <Autocomplete
                                                            apiKey={GOOGLE_API}
                                                            className="form-control"
                                                            placeholder="Enter Agency's location"
                                                            defaultValue={account.nameAddress}
                                                            
                                                            options={{

                                                                componentRestrictions: { country: "us" },
                                                            }}

                                                            onChange={onChangeInputMap}
                                                            onPlaceSelected={(place) => {
                                                                onChangeLocation(place);
                                                            }}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <br />

                                        <Row>
                                            <Col lg="2">
                                            </Col>
                                            <Col>
                                               
                                                <div className="form-group" style={{fontFamily: "Work Sans"}}>
                                                    <Button color="primary" disabled={loading} >
                                                        Save changes
                                                        {loading && (
                                                            <span className="spinner-border spinner-border-sm"></span>
                                                        )}
                                                    </Button>
                                                    &nbsp;&nbsp;
                                                    {/* <Button onClick={DeleteAccountModal}>Delete account</Button> */}


                                                </div>
                                            </Col>
                                        </Row>
                                    </form>
                                   
                                </Tab>
                                <Tab eventKey="Privacy" title="Privacy & Notifications">
                                    <form key={2} onSubmit={handleSubmit2(SavePrivacy)}>
                                        <br />
                                        <div className="row">
                                            <div className="col-7">
                                                <label className="labelText">Receive email notifications for posts published to my department:</label>
                                            </div>
                                            <div className="col-1 toggle-switch">
                                                <div className="toggle-switch float-end">
                                                    <input id="notificationAgency" type="checkbox" onChange={onChangePostPublished} checked={account.notificationAgency} className="toggle-switch-1" />

                                                    <label for="notificationAgency"></label>
                                                </div>
                                            </div>
                                        </div>
                                        <br />
                                        <div className="row">
                                            <Col lg="8">
                                                <hr />
                                            </Col>
                                        </div>

                                        <div className="row">
                                            <div className="col-7">
                                                <label className="labelText">Receive email notifications for posts published to departments to which I have signed up:</label>
                                            </div>
                                            <div className="col-1 toggle-switch">
                                                <div className="toggle-switch float-end">
                                                    <input id="notificationAgencySign" type="checkbox" onChange={onChangePostPublishedSign} checked={account.notificationAgencySign} className="toggle-switch-1" />

                                                    <label for="notificationAgencySign"></label>
                                                </div>
                                            </div>
                                        </div>
                                        <br />

                                        <div className="row">
                                            <Col lg="8">
                                                <hr />
                                            </Col>
                                        </div>

                                        <div className="row">
                                            <div className="col-7">
                                                <label className="labelText">Receive email notifications for new messages sent to chat rooms I am in:</label>
                                            </div>
                                            <div className="col-1 toggle-switch">
                                                <div className="toggle-switch float-end">
                                                    <input id="notificationChat" type="checkbox" onChange={onChangeChat} checked={account.notificationChat} className="toggle-switch-1" />

                                                    <label for="notificationChat"></label>
                                                </div>
                                            </div>
                                        </div>
                                        <br />

                                        <div className="row">
                                            <Col lg="8">
                                                <hr />
                                            </Col>
                                        </div>
                                        <div className="row">
                                            <div className="col-7">
                                                <label className="labelText">Receive email notifications for updated posts I have saved:</label>
                                            </div>
                                            <div className="col-1 toggle-switch">
                                                <div className="toggle-switch float-end">

                                                    <input id="notificationUpdateMyPost" type="checkbox" onChange={onChangeUpdatePost} checked={account.notificationUpdateMyPost} className="toggle-switch-1" />

                                                    <label for="notificationUpdateMyPost"></label>
                                                </div>
                                            </div>
                                        </div>
                                        <br />
                                        {
                                            authService.isAdmin() === true &&
                                            <>
                                                <div className="row">
                                                    <Col lg="8">
                                                        <hr />
                                                    </Col>
                                                </div>
                                        <div className="row">
                                            <div className="col-7">
                                                <label className="labelText">Receive email notifications for reported posts:</label>
                                            </div>
                                            <div className="col-1 toggle-switch">
                                                <div className="toggle-switch float-end">

                                                    <input id="notificationReportedPost" type="checkbox" onChange={onChangeReportedPost} checked={account.notificationReportedPost} className="toggle-switch-1" />

                                                    <label for="notificationReportedPost"></label>
                                                </div>
                                            </div>
                                        </div>
                                                <br />
                                                <div className="row">
                                                    <Col lg="8">
                                                        <hr />
                                                    </Col>
                                                </div>
                                        <div className="row">
                                            <div className="col-7">
                                                        <label className="labelText">Receive email notifications for requests agency(only if the agency's requests are as manually):</label>
                                            </div>
                                            <div className="col-1 toggle-switch">
                                                <div className="toggle-switch float-end">

                                                    <input id="notificationRequests" type="checkbox" onChange={onChangeRequests} checked={account.notificationRequests} className="toggle-switch-1" />

                                                    <label for="notificationRequests"></label>
                                                </div>
                                            </div>
                                        </div>
                                        <br />
                                            </>
                                        }

                                        <div className="row">
                                            <Col lg="8">
                                                <hr />
                                            </Col>
                                        </div>

                                        {!isSuperAdmin && 
                                        (
                                        <>
                                            <div className="row">
                                                <div className="col-7">
                                                    <label className="labelText">Allow officers to search my profile:</label>
                                                </div>
                                                <div className="col-1 toggle-switch">
                                                    <div className="toggle-switch float-end">
                                                        <input id="allowSearchMyProfile" type="checkbox" onChange={onChangeAllowSearch} checked={account.allowSearchMyProfile} className="toggle-switch-1" />
                                                        <label for="allowSearchMyProfile"></label>
                                                    </div>
                                                </div>
                                            </div>
                                            <br />
                                            <div className="row">
                                                <Col lg="8">
                                                    <hr />
                                                </Col>
                                            </div>
                                        </>
                                        )}
                                        

                                        <div className="row">
                                            <div className="col-7">
                                                <label className="labelText">Two-factor authentication:</label>
                                            </div>
                                            <div className="col-1 toggle-switch">
                                                <div className="toggle-switch float-end">
                                                    <input id="twofactorAuthentication" type="checkbox" onChange={onChangeTwofactorAuthentication} checked={account.twofactorAuthentication} className="toggle-switch-1" />
                                                    <label for="twofactorAuthentication"></label>
                                                </div>
                                            </div>
                                        </div>
                                        <br />
                                        <Row>
                                            <Col lg={8}>
                                                <div className="form-group" style={{fontFamily: "Work Sans"}}>
                                                    <Button color="primary" disabled={loading} >
                                                        Save changes
                                                        {loading && (
                                                            <span className="spinner-border spinner-border-sm"></span>
                                                        )}
                                                    </Button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </form>
                                </Tab>
                                <Tab eventKey="Password" title="Password">
                                    <form key={3} onSubmit={handleSubmit3(changePassword)}>
                                        <br />
                                        <Row>
                                            <Col lg="4">

                                                <Form.Label className="text">Old password</Form.Label>
                                                <Form.Control
                                                    type="password" placeholder="Enter your password" className={errors.name ? 'form-control is-invalid' : 'form-control'}
                                                    {...register3("oldPassword", { required: true })}  />
                                                {errors3.oldpassword && errors3.oldpassword.type === "required" && <span className="text-danger">This is required</span>}
                                                
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg="4">

                                                <Form.Label className="text">New password</Form.Label>
                                                <Form.Control
                                                    type="password" placeholder="Enter new password" className={errors.name ? 'form-control is-invalid' : 'form-control'}
                                                    {...register3("password", { required: true })}  />
                                                {errors3.password && errors3.password.type === "required" && <span className="text-danger">This is required</span>}
                                                {validationErros.rulesError && <span className="text-danger">This field need format password(miminum 8 letter ( 1 lowercase,uppercase,numeric,special character)!</span> }
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg="4">

                                                <Form.Label className="text">Confirm New password</Form.Label>
                                                <Form.Control
                                                    type="password" placeholder="Confirm the new password"  className={errors.name ? 'form-control is-invalid' : 'form-control'}
                                                    {...register3("newPassword", { required: true })}  />
                                                {errors3.newPassword && errors3.newPassword.type === "required" && <span className="text-danger">This is required</span>}
                                                {validationErros.matchError && <span className="text-danger">The password does not match</span> }
                                                
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <span className="hintSpan">Should be at least 8 characters </span>
                                            </Col>
                                            
                                        </Row>
                                        {serverErrors && <span className="text-danger">{serverErrors}</span>}

                                        <br />
                                        <Row>
                                            <Col lg={7}>
                                                <div className="form-group" style={{fontFamily: "Work Sans"}}>
                                                    <Button color="primary" disabled={loading} >
                                                        Change
                                                        {loading && (
                                                            <span className="spinner-border spinner-border-sm"></span>
                                                        )}
                                                    </Button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </form>
                                    <br />
                                   
                                </Tab>
                                <Tab eventKey="Export data" title="Export data">
                                    <br />
                                    <div className="row">
                                        <br />
                                        <h5>Export your data</h5>
                                        <div className="col-4">
                                            {<ExportDataDropDownField data={exportDataListSelection} onChange={onChangeExportData} />}
                                        </div>
                                        <div className="col-2" style={{fontFamily: "Work Sans"}}>
                                            {!isExportingData && (<Button type="button" className="btn btn-default baseButton" onClick={() => {exportData()}} >
                                            Export
                                            </Button>)}
                                            {isExportingData && (<Button type="button" className="btn btn-default baseButton" disabled >
                                                <span className="spinner-border spinner-border-sm"></span> &nbsp; Exporting...
                                            </Button>)}
                                        </div>
                                        <Row>
                                            <Col >
                                                <span className="hintSpan">All data by selected type</span>
                                            </Col>
                                        </Row>
                                    </div>
                                </Tab>
                            </Tabs>
                        </Col>
                    </Row>
                    
                </div>
                
            </Container>
            <Modal size="lg" isOpen={modalDelete} style={{fontFamily: "Work Sans"}}>
                <ModalHeader>
                    Delete My Account
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <label>
                            Are you sure you want to delete this information?
                        </label>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="primary"
                        onClick={DeleteAccount}
                    >
                        Delete
                    </Button>
                    {' '}
                    <Button onClick={toggleModal}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
            <NotificationContainer />
            <ModalAlert Toggle={toggleDeleted} Message="Account Deleted" Show={Deleted} />
          
        </>);
}
export default Account;