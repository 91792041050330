import React, { useState } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import { useHistory } from "react-router-dom";
import './Login.css';
import nbcLogo from "../../../assets/v2/auth_logo.png";
import authService from "../../../services/auth.service";
import { LoginForm } from "./LoginForm"
import { LoginOptions } from "./LoginOptions"
import { LoginCode } from "./LoginCode"


const NewLogin = () => {
    let history = useHistory();
    const [username, setUsername] = useState("");
    const [requestCode, setRequestCode] = useState(false);
    const [requestCodeOptions, setRequestCodeOptions] = useState(false);
    const [showLogin, setShowLogin] = useState(true);
    const [codeMultiOptions, setCodeMultiOptions] = useState(false);
    const [codeOptionText, setCodeOptionText] = useState("Email");

    const CodeOptionTextChanged = (e) => {
        setCodeOptionText(e === "0" ? "Email" : "Phone");
    }

    const UsernameChanged = (e) => {
        setUsername(e);
    }

    const EnableRequestCode = e => {
        setRequestCode(true);
        setRequestCodeOptions(false);
        setShowLogin(false);
    }

    const EnableOptionsCode = e => {
        setRequestCode(false);
        setRequestCodeOptions(true);
        setCodeMultiOptions(true);
        setShowLogin(false);
    }

    const setSelectOptions = e => {
        e.preventDefault();
        EnableOptionsCode();
    }

    return (
        <div className="loginMain">
            <div className="login-left-pane">
                <div className="login-logo">
                    <img className="login-logo-img" src={nbcLogo} />
                </div>
                <div className="login-form">
                    <h1 className="login-welcome"> Welcome </h1>
                    <p className="login-welcome-label"> Welcome back! Please enter your details </p>
                    {
                        showLogin === true &&
                        <LoginForm 
                                EnableReqCode={EnableRequestCode}
                                EnableOptCode={EnableOptionsCode}
                                UNChanged={UsernameChanged}
                        ></LoginForm>
                    }

                    {
                        requestCode === true &&
                        <LoginCode
                                EnableReqCode={EnableRequestCode}
                                username={username}
                                codeOptionText={codeOptionText}
                                codeMultiOptions={codeMultiOptions}
                                setSelectOptions={setSelectOptions}>
                        </LoginCode>
                    }

                    {
                        requestCodeOptions === true &&
                        <LoginOptions
                                CodeOptionChanged={CodeOptionTextChanged}
                                EnableReqCode={EnableRequestCode}
                                username={username}>
                        </LoginOptions>
                    }
                </div>
            </div>
            <div className="login-right-pane">
            </div>
        </div>
        );
};
 
export default NewLogin;
 

