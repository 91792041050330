/*eslint unicode-bom: ["error", "always"]*/
import React, { useState, useEffect } from "react";

import { Button, Input, Label, Modal,Nav, NavItem, NavLink, Row} from 'reactstrap';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import './AdminAgency.css';
import AuthService from "../../services/auth.service";
import { useHistory } from 'react-router-dom';
import { Col, ModalBody, ModalFooter, ModalHeader } from "react-bootstrap";
import ModalAlert from "../Common/ModalAlert";
import api from "../../services/api";
import ManageAdmins from "./ManageAdmins";
import { GridOfficer } from "../Officer";
import RequestedAgencies from '../Agency/Officer/RequestedAgencies';
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

library.add(faEdit, faTrash,faSearch);
const AdminAgency = () => {
    let history = useHistory();
    // Modal open state
    const [modal, setModal] = React.useState(false);
    const [modalDelete, setModalDelete] = React.useState({
        show: false,
        id: "",
        name:""
    });
    const [modalChief, setmodalChief] = useState(false);
    const [modalInvitation, setmodalmodalInvitation] = useState(false);
    const [usersAvailables, setUsersAvailables] = useState([]);
    const [filter, setFilter] = useState("");
    const [sendInvitation, setSendInvitation] = useState(false);
    const [agencyId, setAgencyId] = useState(false);
    const [validation, setValidation] = useState({ error: false, message: "" });
    const [showUsers, setShowUsers] = useState(false);
    const [manageAgency, setmanageAgency] = useState(0);
    const [isSuper, setIsSuper] = useState(AuthService.isSuperAdmin());
   
    // Toggle for Modal
    const toggle = (agency) => {
        setValidation({
            error: false,
            message: ""
        });
        setModal(!modal);
        setAgencyId(agency);
        setFilter("");
    }
    const DeleteAgency = (id) => {

        const formDatadelete = new FormData();

        formDatadelete.append("id", id);

        api.post("/agency/delete", formDatadelete, AuthService.getToken())
            .then(response => {

                if (response.data) {
                    toggleDeleteModal(false);
                    getData(1).then((info) => {
                        const { totalPages, data, totalData } = info;
                        setPageData({
                            isLoading: false,
                            rowData: data,
                            totalPages,
                            totalData,
                        });
                    });
                }


            });
    }
    const toggleDelete = (agency) => {
        setModalDelete({
            ...modalDelete,
            show: !modalDelete.show,
            id: agency.id,
            name:agency.name
        });
       
    }
    const toggleDeleteModal = (shomwod) => {
        setModalDelete({
            ...modalDelete,
            show: shomwod,
            id: "",
            name: ""
        });

    }


  

    const toggleInvitation = () => {
        setmodalmodalInvitation(false);
        setFilter("");
        setSendInvitation(false);
    };


    const toggleAddedShief = () => {
        setmodalChief(false);
        setFilter("");
        setSendInvitation(false);

    };

  

    const onFilterChanged = (e) => {
        setFilter(e.target.value);
        
    }

  

    const sendUserInvitation = async () => {
        // eslint-disable-next-line
        const regexemail = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        if (usersAvailables !== undefined && usersAvailables.length === 0 && regexemail.test(filter) === true)
        {
            var addOfficerViewModel = {
                Email: filter,
                Agency: agencyId,
                UserId: filter
            };
            const responseUser = await api.post(
                `/authenticate/sendInvitation`, addOfficerViewModel
            ).then((response) => {

                if (response !== undefined && response.data.message !== undefined) {
                    setValidation({
                        error: true,
                        message: response.data.message
                    })
                } else {
                    setValidation({
                        error: false,
                        message: ""
                    })
                    setModal(false);
                    setmodalmodalInvitation(true);
                }
                
            });
        }
        
    }

    const manageAdminUsers = async (agency) => {
        setmanageAgency(agency.id);
        setShowUsers(true);
    }

    const toggleManageAdmins = () => {
        setShowUsers(false);
    }


    const getData = async (pageNo = 1) => {
        const response = await api.get(
            `/agency/get?page=${pageNo}&take=15`
        );
        return response.data;

    };
 
    const [pageData, setPageData] = useState({
        rowData: [],
        isLoading: false,
        totalPages: 0,
        totalData: 0,
    });
    const [currentPage, setCurrentPage] = useState(1);
    useEffect(() => {
        setPageData((prevState) => ({
            ...prevState,
            rowData: [],
            isLoading: true,
        }));
        getData(currentPage).then((info) => {
            const { totalPages, data, totalData } = info;
            setPageData({
                isLoading: false,
                rowData: data,
                totalPages,
                totalData,
            });
        });
    }, [currentPage]);
    return (
        <>
            <div>
                        <RequestedAgencies/>
                      
            </div>

            <div className="AgenciesDiv">
                <label className="titlediv">Officers</label>
                <GridOfficer/>
            </div>
            
            <Modal size="sm" isOpen={modal}>
                <ModalHeader>
                    Add new officer 
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col xl="3">
                            <Label>Email officer</Label>
                        </Col>
                        <Col>
                            <div className="input-group mb-3">
                                <Input autoComplete="off" onChange={onFilterChanged} value={filter ?? ""} type="text" className="form-control" />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        {(validation !== undefined && validation.error ===true) && <p className="alert alert-danger">{validation.message}</p> }
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="primary"
                        onClick={sendUserInvitation}
                    >
                        Send invitation
                    </Button>
                    {' '}
                    <Button onClick={toggle}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
            <ModalAlert Toggle={toggleAddedShief} Message="User added as a chief" Show={modalChief} />
            <ModalAlert Toggle={toggleInvitation} Message="An invitation was sent" Show={modalInvitation} />
            <Modal size="sm" isOpen={modalDelete.show}>
                <ModalHeader>
                    Delete Agency
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <label >Are you sure you want to delete the {modalDelete.name} agency?</label>
                    </Row>
                   
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="primary"
                        onClick={() =>DeleteAgency(modalDelete.id)}
                    >
                        Delete
                    </Button>
                    {' '}
                    <Button onClick={toggleDelete}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
            <Modal  isOpen={showUsers}>
                <ModalBody>
                    <ManageAdmins Agency={manageAgency} />
                </ModalBody>
                <ModalFooter>
                    <Button onClick={toggleManageAdmins} outline>
                        Close
                    </Button>
                </ModalFooter>
            </Modal>
        
        </>
    );
}
export default AdminAgency;