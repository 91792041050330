import React from "react";
import UserCard from '../Profile/UserCard';


const CardPostOfficersCard = (props) => {

    return (
    <>
        <h2>Officers</h2><hr></hr><br/> 
        <div className="row">
            {props.listofficer.map((user) => (
                <UserCard key={user.id} openMessage={props.OpenMessage} id={user.id} ></UserCard>
            )
            )}
        </div>
    </>);

}


export default CardPostOfficersCard;