
import { useState, useRef } from 'react';
import ImageUpLoader from '../Common/ImageLoader';
import './ProfilePictureUpload.css';
import profilelogo from "../../assets/user-profile.png";
import Avatar from 'react-avatar';

const ProfilePicture = (props) => {

    const [profilePic, setProfilePic] = useState();
    const uploadInput = useRef(null);

    if (props.selectedImage !== null && props.selectedImage !== undefined && (profilePic === null || profilePic === undefined)) {
        setProfilePic(props.selectedImage);
    }

    const remove = () => {
        //setProfilePic(null);
        //props.pictureChanged(null);
        uploadInput.current.click();
    }

    return <>
        {
            profilePic && <ImageUpLoader removeAction={remove} selectedImage={profilePic} />
        }

        {
            (profilePic === undefined || profilePic === null) &&
            <>
                <label id="inputTagLabel" for="inputTag">
                    <Avatar name={props.name} size="128" round />
                </label>

                
                
            </>

            
           
        }
        <input
            id="inputTag"
            type="file"
            ref={uploadInput}
            name="myImage"
            className="inputfile"
            onChange={(event) => {
                console.log(event.target.files[0]);
                setProfilePic(event.target.files[0]);
                props.pictureChanged(event.target.files[0])
            }} />
        <button type="button" className="buttonWithoutBorder" variant="outline-light" onClick={() => {
            remove();
        }}>
            Upload new photo
        </button>
    </>
}

export default ProfilePicture;
