import React from "react";
import Badge from 'react-bootstrap/Badge';

const StatusBadge = (type) => {
    let _bg = '';
    let _statMessage = '';
    let _textColor = '';

    switch (type) {
        case 2: 
            _bg = 'warning';
            _statMessage = 'SUSPENDED';
            _textColor = 'dark'
            break;
        case 3:
            _bg = 'danger';
            _statMessage = 'REMOVED';
            break;
        default:
            _bg = 'primary';
            _statMessage = 'ACTIVE';
    }

    return (
        <Badge style={{fontFamily: "Work Sans", fontStyle: "bold", fontWeight: 400, fontSize: 12}} pill bg={_bg} text={_textColor}> {_statMessage}</Badge>
    )
}

export default StatusBadge;