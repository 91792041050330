import React, {useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Card, CardBody, CardText, CardTitle, Form, Input } from "reactstrap";
import authService from "../../services/auth.service";

const ForgotPassword = ()=>
{
    let history = useHistory();
    const [email, setEmail] = useState();
    const [isvalid, setIsvalid] = useState();
    const [loading, setLoading] = useState(false);
    const [successResult, setSuccessResult] = useState();
    const [validation, setValidation] = useState({
        email:"",
    });
    const onChangeEmail = e =>
    {
        setEmail(e.target.value);
       
    }

    const handleForgotPassword = (e) => {
        setLoading(true);
        e.preventDefault();
        let errors = validation;
        setIsvalid(true);
        // eslint-disable-next-line
        const regexemail = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        if (!email || regexemail.test(email) === false) {
            errors.email = " This field need format email!";
            setIsvalid(false);
            setLoading(false);
        }
        else{
            errors.email = "";
            setIsvalid(false);
        }
        setValidation(errors);

        authService.forgotPassword(email).then(
            () => {

                setSuccessResult(true);
                setEmail("");
                setLoading(false);
                setTimeout(function () { history.push("/login"); }, 4000);
            },
            error => {
                setLoading(false);
            }
        );
    };


    return  <Form onSubmit={handleForgotPassword} >
                <Card>
                    <CardBody>
                        <CardTitle><h3>Forgot Password</h3></CardTitle>
                        {successResult !== undefined && successResult && <p className="alert alert-success">An email was sent, please check your email!</p>}

                        <CardText>To reset your password, please provide your email.</CardText>
                        <Input id="email" name="email" onChange={onChangeEmail} value={email ?? ""} type="email" placeholder="email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" required></Input>
                        <br></br>
                        {!isvalid && validation.email && <p className="alert alert-danger">{validation.email}</p>}
                <br></br>
                <Button disabled={loading} className="btn btn-primary forGotPasswordBtn">
                    {loading && (
                        <span className="spinner-border spinner-border-sm"></span>
                    )}
                    Send
                </Button>
                    </CardBody>
                </Card>
            </Form>
     
}



export default ForgotPassword;