import { faCircleXmark, faUserGroup, faBookmark, faShareSquare, faBell, faTrashCan, faTriangleExclamation, faPencil, faBrain,faUndo,faClipboardUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row, Col} from 'reactstrap';
import { library } from '@fortawesome/fontawesome-svg-core';
import './OptionAccount.css';
import AuthService from "../../services/auth.service";
import api from "../../services/api";

library.add(faCircleXmark, faUserGroup, faBookmark, faShareSquare, faBell, faTrashCan, faTriangleExclamation, faPencil, faBrain,faUndo,faClipboardUser)
const OptionsPost = (props) => {
    const { data
        ,EditFunction
        ,ViewFunction
        ,DeleteFunction
        ,SuspendFunction
        ,UnSuspendFunction
        ,RestoreAccount
        ,AllowedSuspend

        } = props;

    const removeRelate = (data) => {


        api.post("/releatedcrimes/deletepost", { Id: data.Id }, AuthService.getTokenPost())
            .then((response) => {
                props.LoadIncidents();
            });
    }

    const SuspendAccountContainer = () => {
        return (<Row className="option" onClick={() => SuspendFunction(data)}>
                <Col md="2">
                    <FontAwesomeIcon className="iconPopup" icon={faTriangleExclamation} size="xl" />
                </Col>
                <Col md="10">
                    Suspend Account
                </Col>
            </Row>);
    }

    const UnSuspendAccountContainer = () => {
        return (<Row className="option" onClick={() => UnSuspendFunction(data)}>
        <Col md="2">
            <FontAwesomeIcon className="iconPopup" icon={faTriangleExclamation} size="xl" />
        </Col>
        <Col md="10">
            Unsuspend
        </Col>
    </Row>);
    }

    return (
        <>

            <Row className="option">
             
                {

                    (data.relateCrimes && data.relateCrimes.length > 0) &&
                    <>
                        <Col md="2" className="centerDivVerticall">
                            <FontAwesomeIcon className="iconPopup" icon={faCircleXmark} size="xl" />
                        </Col>
                        <Col md="6">



                            <a className="transparentLink" href="#" onClick={(e) => removeRelate({ Id: data.relateCrimes[0].id })}>
                                Remove from {' '}
                                {data.relateCrimes[0].name}
                            </a>
                        </Col>
                    </>
                }


            </Row>

            {
                data.canEdit && data.canEdit === true && <Row className="option">

                    <Col md="2">
                        <a href={"/post/edit/" + data.id}>
                            <FontAwesomeIcon className="iconPopup" icon={faPencil} size="xl" />
                        </a>
                    </Col>
                    <Col md="5">
                        <a href={"/post/edit/" + data.id}>
                            Edit Post
                        </a>
                    </Col>

                </Row>
            }

            {
                (data.deleted || data.statusApp === "Suspended" 
                    ? 
                    <Row className="option" onClick={()=>ViewFunction(data)}>
                        <Col md="2">
                            <FontAwesomeIcon className="iconPopup" icon={faClipboardUser} size="xl" />
                        </Col>
                        <Col md="10">
                            View Account
                        </Col>
                    </Row> 
                    :
                    <Row className="option" onClick={()=>EditFunction(data)}>
                        <Col md="2">
                            <FontAwesomeIcon className="iconPopup" icon={faPencil} size="xl" />
                        </Col>
                        <Col md="10">
                            Edit Account
                        </Col>
                    </Row>  
                )


            }


        
            {
            /** Uncomment this when Suspend functionality will be re-activated
            
            { 
            (
                data.deleted ? 
                (<></>) 
                :
                (AllowedSuspend === undefined || AllowedSuspend === true ) ? 
                    ( data.statusApp === "Suspended" ? <UnSuspendAccountContainer/> : <SuspendAccountContainer/>) 
                    : 
                    (<></>) 
            )
            } 
            **/}

            {
                (
                    data.deleted ? 
                    (
                    
                        <Row className="option" onClick={() => RestoreAccount(data)}>
                        <Col md="2">
                            <FontAwesomeIcon className="iconPopup" icon={faUndo} size="xl" />
                        </Col>
                        <Col md="10">
                            Restore User
                        </Col>
                      </Row>
                    
                    )
                    : 
                    ( 
                      <Row className="option" onClick={() => DeleteFunction(data)}>
                        <Col md="2">
                            <FontAwesomeIcon className="iconPopup" icon={faTrashCan} size="xl" />
                        </Col>
                        <Col md="10">
                            Remove User
                        </Col>
                      </Row>
                    )
                )

            }
           
           
        </>
    );
}
export default OptionsPost;