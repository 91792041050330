import React, {useEffect, useRef, useState} from "react";
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import 'bootstrap-daterangepicker/daterangepicker.css';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import {
    ButtonFontStyle,
    CenterAllStyle,
    DatePickerField, 
    DepartmentDropDownField, 
    HeaderLabel, 
    IncidentPostContainer, 
    InitialDateRangeState,
    NoResultFoundContainer,
    PostShimmeringEffect,
    PrimaryFontFamily,
    SearchOfficerField, 
} from "./Department.Style";
import { UsedDepartmentContext } from "./Department.Provider";
import ViewportList from 'react-viewport-list';
import AuthService from "../../services/auth.service";
import api from "../../services/api";
import * as _ from "lodash";
import { QueryString } from "../Common/standardfunction";

const URI = "/department/listsearch";

const initialDepartmentData = [
    { value: 0, label: 'All' }
]

const Department = () => {
    const departmentContext = UsedDepartmentContext();

    const [isLoading, setIsLoading] = useState(true);
    const [isExecuteFetch, setIsExecuteFetch] = useState(false);

    // eslint-disable-next-line no-unused-vars
    const [userRoles, setUserRoles] = useState(AuthService.getRoles());
     // eslint-disable-next-line no-unused-vars
    const [currentUser, setCurrentUser] = useState(AuthService.getCurrentUser());

    const [data, setData] = useState([]); 
    const[departmentData, setDepartmentData] = useState(initialDepartmentData);

    const viewPortRef = useRef(null);
    const viewPortListRef = useRef(null);

    const [searchOfficer, setSearchOfficer] = useState("");
    const [dateRangeState, setDateRangeState] = useState(InitialDateRangeState());

    let departmentInitialVal = 0;
    if (userRoles.length > 0 && userRoles.includes("Admin")) {
        departmentInitialVal = currentUser.agencyId;
    }

    const [selectedDepartment, setSelectedDepartment] = useState(departmentInitialVal);

    const [timer, setTimer] = useState(null);

    const handleSearchOfficeFieldOnChange = (e) => {
        setSearchOfficer(e.nativeEvent.srcElement.value);

        if(e.nativeEvent.srcElement.value === "") {
                if (timer) {
                clearTimeout(timer);
                setTimer(null);
            }

            //set a pause after typing
            setTimer(
                setTimeout(1, 1000)
            );
            setIsExecuteFetch(true);
        }
    };

    const handleDateRangeFieldOnChange = (e) => {
        setDateRangeState({
            startDate: e.startDate,
            endDate: e.endDate,
        });
        setIsExecuteFetch(true);
    };

    const handleDepartmentOnChange = (e) => {
        setSelectedDepartment(e.value)
    };

    const handleCloseDeleteDialog = () => {
        departmentContext.onSetIncidentId(0);
        departmentContext.onShowDeleteDialog(false);
    };

    const handleCloseSuspendAccountDialog = () => {
        departmentContext.onSetUserId("");
        departmentContext.onShowSuspendAccountDialog(false);
    };
  
    const handleRemovePost = () => {
        const formData = new FormData();
        formData.append("id", departmentContext.incidentId);

        api.post("/department/incident/delete", formData, AuthService.getTokenJson())
            .then((response) => {
                console.log(response.data)
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
            departmentContext.onSetIncidentId(0);
            setIsExecuteFetch(true);
            departmentContext.onShowDeleteDialog(false);
            });
            
    }

    const handleSuspendAccount = () => {
        const formData = new FormData();
        formData.append("id", departmentContext.userId);

        api.post("/account/suspend", formData, AuthService.getTokenJson())
            .then((response) => {
                console.log(response.data)
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
            departmentContext.onSetUserId(0);
            setIsExecuteFetch(true);
            departmentContext.onShowSuspendAccountDialog(false);
            });
            
    }

    const filteredData = !searchOfficer ? (selectedDepartment > 0 ?  
                                                                (data.filter((item) => item.departmentId === selectedDepartment )) 
                                                                : 
                                                                (data))
                                        :  
                                        selectedDepartment > 0 ?  
                                                             (data.filter((item) => (item.fullName.toLowerCase()).includes(searchOfficer.toLowerCase()) && item.departmentId === selectedDepartment)) 
                                                             : 
                                                             (data.filter((item) => (item.fullName.toLowerCase()).includes(searchOfficer.toLowerCase())))
    ;

    //Initial state of data
    useEffect(() => {
        //[Start] - Populate department combo box
        api.get("/department/list/agency", AuthService.getTokenJson())
            .then((response) => {
                if(response.data.length > 0) {
                    let tempData = response.data;
                    tempData.push(initialDepartmentData[0]);

                    let sortData = _.sortBy(tempData,"value")

                    setDepartmentData(sortData);
                }
            })
            .catch((error) => {
                console.log(error);
            });
        //[End] - Populate department combo box

       
        //[Start] - Populate list of data for incidents
        const {startDate, endDate } = dateRangeState;

        const queryStringResult = QueryString({ officer: "", 
                                    startDate: startDate.format("YYYY-MM-DD"), 
                                    endDate: endDate.format("YYYY-MM-DD"),
                                    departmentId: 0});

        api.get(URI + queryStringResult, AuthService.getTokenJson())
        .then((response) => {
            if(response.data.length > 0) {
                setData(response.data);
                setIsLoading(false);
            } else {
                setData([]);
                setIsLoading(false);
            }
        })
        .catch((error) => {
            console.log(error);
            setIsLoading(false);
        });
        //[End] - Populate list of data for incidents

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    //Fetch data from API
    useEffect(() => {
        if(isExecuteFetch) {
            setIsLoading(true);
            const {startDate, endDate } = dateRangeState;

            const queryStringResult = QueryString({ officer: searchOfficer, 
                                        startDate: startDate.format("YYYY-MM-DD"), 
                                        endDate: endDate.format("YYYY-MM-DD"),
                                        departmentId: selectedDepartment});
            
            api.get(URI + queryStringResult, AuthService.getTokenJson())
            .then((response) => {
                if(response.data.length > 0) {
                    setData(response.data);
                    setIsLoading(false);
                } else {
                    setData([]);
                    setIsLoading(false);
                }
            })
            .catch((error) => {
                console.log(error);
                setIsLoading(false);
            });

            setIsExecuteFetch(false);
        }
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[isExecuteFetch, searchOfficer, dateRangeState, selectedDepartment]);

    return (
        <>
            <Container fluid>
                <HeaderLabel label={"Department"} ></HeaderLabel>
                <Row>
                    <Col>
                        <SearchOfficerField onChange={handleSearchOfficeFieldOnChange} />
                    </Col>
                    <Col>
                        <DatePickerField onChangeDateValues={handleDateRangeFieldOnChange} />
                    </Col>
                    <Col>
                        {
                            userRoles.length > 0 && userRoles.includes("SuperAdmin") ?
                            (<DepartmentDropDownField data={departmentData} onChange={handleDepartmentOnChange} />)
                            : (<></>)
                        }
                    </Col>
                </Row>

                <Row style={{padding: "25px 0px 0px 10px"}}>
                    {
                        isLoading ? 
                        (
                            <div>
                                <div style={{marginBottom: "25px"}}><PostShimmeringEffect/></div>
                                <div style={{marginBottom: "25px"}}><PostShimmeringEffect/></div>
                            </div>
                            
                        ) 
                        : 
                        (
                            <div className="scroll-container" ref={viewPortRef}>
                                { 
                                    filteredData.length > 0 ? 
                                        (
                                            <ViewportList ref={viewPortListRef} viewportRef={viewPortRef} items={filteredData} itemMinSize={40} margin={8}>
                                                {(item) => (
                                                    <div className="item" key={item.userId} id={"item-content-id-" + item.userId}>
                                                        <IncidentPostContainer data={item} />
                                                    </div>
                                                )}
                                            </ViewportList>
                                        ) 
                                        : 
                                        (<NoResultFoundContainer message={"No results found. Try another keywords..."} />)
                                }
                            </div>
                        )
                    }
                </Row>
            </Container>

            {/**[Start] - Delete Incident Post Dialog**/}
            <Dialog open={departmentContext.isDeleteDialogOpen}
                    onClose={handleCloseDeleteDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                <div style={{width: "290px", height: "160px", padding: "25px", ...CenterAllStyle}}>
                    <div style={{fontFamily: PrimaryFontFamily, fontSize: "18px", fontWeight: 600, color: "#002542", textAlign: "center", marginBottom: "10px"}}>
                        <div>Are you sure you want to remove this post?</div>
                    </div>
                    <div style={{display: "flex", padding: "5px", ...CenterAllStyle}}>
                            <div style={{marginRight: "15px"}}>
                                <Button onClick={handleCloseDeleteDialog} variant="outlined" sx={{borderColor: "#D9DFE1"}}>
                                    <div style={{...ButtonFontStyle, color: "#576F80"}}>Cancel</div>
                                </Button>
                            </div>
                            <div>
                                <Button onClick={handleRemovePost} variant="contained">
                                    <div style={{...ButtonFontStyle, color: "#FFFFFF"}}>Remove</div>
                                </Button>
                            </div>
                    </div>
                </div>
            </Dialog>
            {/**[End] - Delete Incident Post Dialog**/}

            {/**[Start] - Suspend Account Dialog**/}
            <Dialog open={departmentContext.isSuspendAccountDialogOpen}
                    onClose={handleCloseSuspendAccountDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                <div style={{width: "290px", height: "160px", padding: "25px", ...CenterAllStyle}}>
                    <div style={{fontFamily: PrimaryFontFamily, fontSize: "18px", fontWeight: 600, color: "#002542", textAlign: "center", marginBottom: "10px"}}>
                        <div>Are you sure you want to suspend this account?</div>
                    </div>
                    <div style={{display: "flex", padding: "5px", ...CenterAllStyle}}>
                            <div style={{marginRight: "15px"}}>
                                <Button onClick={handleCloseSuspendAccountDialog} variant="outlined" sx={{borderColor: "#D9DFE1"}}>
                                    <div style={{...ButtonFontStyle, color: "#576F80"}}>Cancel</div>
                                </Button>
                            </div>
                            <div>
                                <Button onClick={handleSuspendAccount} variant="contained">
                                    <div style={{...ButtonFontStyle, color: "#FFFFFF"}}>Suspend</div>
                                </Button>
                            </div>
                    </div>
                </div>
            </Dialog>
            {/**[End] - Suspend Account Dialog**/}
            

        </>
    );
};

export default Department