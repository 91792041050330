import { faInfo, faLocationArrow, faSitemap, faEnvelope, faMobile, faGlobe } from '@fortawesome/free-solid-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core';
import Avatar from 'react-avatar';
import AuthService from "../../services/auth.service";
import InputMask from "react-input-mask";
import api from "../../services/api";
import agencyimg from "../../assets/account-w.png";
import React, { useState } from "react";
import { Button} from 'reactstrap';
import { useForm } from 'react-hook-form';
library.add(faInfo, faLocationArrow, faSitemap, faEnvelope, faMobile, faGlobe);

const ModalAccount = (props) => {
    const { closeModal, divisions, departaments,AfterCreateEditOfficer } = props;
    const { register, handleSubmit, formState: { errors }, setError, clearErrors } = useForm({
        mode: 'onChange'
    });

    const [account, setAccount] = useState(props.account);
    const [isLoading, setIsLoading] = useState(false);

    const onChangeName = e => {
        setAccount({
            ...account,
            name: e.target.value
        });

    }
    const onchangeEmail = e => {
        setAccount({
            ...account,
            email: e.target.value
        });
    }
   
    const ChangeDivision = e => {
        setAccount({
            ...account,
            divisions: e.target.value
        });
    }
    const onchangePhone = e => {

        let _tempPhone = e.target.value.replace(/\D/g,'');
        if(_tempPhone.trimEnd() !== "") {
            if (  _tempPhone.length < 11) {
                setError('phone', { type: 'invalid-format' });
            } else {
                clearErrors("phone");
            }
        } else {
            clearErrors("phone");
        }

        setAccount({
            ...account,
            phone: e.target.value
        });
    }
    const ChangeDepartament = (e) => {
        setAccount({
            ...account,
            departaments: e.target.value
        });
    }
    const SaveAccount = (event) => {

        if(account.phone !== undefined ) {
            if(account.phone !== null) {
                let _tempPhone = account.phone.replace(/\D/g,'');
                if(_tempPhone.trimEnd() !== "") {
                    if (  _tempPhone.length < 11) {
                        setError('phone', { type: 'invalid-format' });
                        return ;
                    } else {
                        clearErrors("phone");
                    }
                } else {
                    clearErrors("phone");
                }
            } else {
                clearErrors("phone");
            }
           
        }
        
        let isEdit = !(account.id === undefined || account.id === null || account.id === 0);
        
        //NEW REGISTER
        const formData = new FormData();
        formData.append("Name", account.name);
        formData.append("Departament", account.departaments);
        formData.append("Division", account.divisions);
        formData.append("Phone", account.phone);
        formData.append("Email", account.email);
        formData.append("Status", "1");
        formData.append("UserId", account.id? account.id:"-");
        formData.append("NotificationAgency", false);
        formData.append("NotificationAgencySign", false);
        formData.append("NotificationChat", false);
        formData.append("NotificationUpdateMyPost", false);
        formData.append("AllowSearchMyProfile", true);
        formData.append("ShowPhone", true);
        formData.append("ShowEmail", true);
        formData.append("FormFile", account.selectedImage);
        formData.append("TwofactorAuthentication", true);
        formData.append("ImageRemoved", false);

        setIsLoading(true);
        api.post("/account/new", formData, AuthService.getToken())
            .then(response => {
                if (response) {
                    AfterCreateEditOfficer(true, isEdit);
                }
                else {
                    AfterCreateEditOfficer(false, isEdit);
                }
            });

        // setIsLoading(false);
    }

    return (
        
            <>
                <div className="row">
                   
                    <div className="col-12">
                    <div className="containermodal">
                        <div className="row titlerowcenter">
                            {(account.id !== undefined )&&
                                <label className="accounttitle">Edit Account</label>
                            }
                            {(account.id === undefined || account.id == null || account.id === 0) &&
                                <label className="accounttitle">Add Account</label>
                            }
                            </div>
                            <div className="">
                                <form onSubmit={handleSubmit(SaveAccount)}>
                                    <div>
                                        {account.id !== undefined && account.id > 0 &&
                                            <h5 className="card-titleagency">Edit Agency</h5>
                                        }
                                        {account.id === undefined && account.id === 0 &&
                                            <h5 className="card-titleagency">Add new Agency</h5>
                                        }

                                        <div className="row centerimg">
                                        {account.urlPic && !account.selectedImage &&
                                            <img alt="" src={account.urlPic} className="iconagency" />
                                            }
                                        {!account.selectedImage && !account.urlPic &&
                                             <Avatar src={agencyimg} size="80" round className="avataraccounttext avtborder" />
                                              
                                            }
                                            {account.selectedImage && (
                                                <div>
                                                <img alt="Not Found" className="iconofficersaved" src={URL.createObjectURL(account.selectedImage)} />
                                                    <br />
                                                    <button className="btn btn-danger" onClick={() => setAccount({
                                                        ...account,
                                                        selectedImage: null
                                                    })}>Remove</button>
                                                </div>
                                            )}
                                            <br />
                                        {!account.selectedImage && !account.img &&
                                            <input
                                                className={errors.image ? 'form-control is-invalid' : 'form-control'}
                                                type="file"
                                                name="myImage"
                                                disabled={account.isView}

                                            {...register("image")}
                                                onChange={(event) => {
                                                    setAccount({
                                                        ...account,
                                                        selectedImage: event.target.files[0]
                                                    });
                                                    clearErrors("image");
                                                }}
                                            />
                                        }
                                        </div>
                                        <div className="row">

                                            <div className="col-md-12 col-sm-12">
                                                <label className="lb">Name</label>
                                            </div>
                                            <div className="col-md-12 col-sm-12">
                                                <div className="input-group mb-3">
                                                   
                                                <input required type="text" className="form-control" onChange={onChangeName} value={account.name} disabled={account.id !== undefined} placeholder="Name..." aria-label="Username" aria-describedby="basic-addon1" />
                                                </div>
                                            </div>

                                        </div>
                                    

                                        <div className="row">

                                            <div className="col-md-12 col-sm-12">
                                                <label className="lb">Phone</label>
                                            </div>
                                            <div className="col-md-12 col-sm-12">
                                                <div className="input-group mb-3">
                                                  
                                                <InputMask 
                                                    placeholder="Phone" 
                                                    onChange={onchangePhone} 
                                                    value={account.phone} 
                                                    disabled={account.isView ? true : account.phone === null ? false: false} 
                                                    className="form-control" mask="+1\ 999 999 9999" />
                                               
                                                </div>
                                                {errors.phone && errors.phone.type === "invalid-format" && <span className="text-danger">Invalid format</span>}
                                            </div>

                                        </div>

                                        <div className="row">

                                            <div className="col-md-12 col-sm-12">
                                                <label className="lb">Email</label>
                                            </div>
                                            <div className="col-md-12 col-sm-12">
                                                <div className="input-group mb-3">
                                                   
                                                <input id="email" name="email" onChange={onchangeEmail} value={account.email} required type="email" disabled={account.id !== undefined} className="form-control" placeholder="E-mail" aria-label="Username" aria-describedby="basic-addon1"
                                                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                                                />
                                                </div>
                                            </div>

                                        </div>
                                        <div className="row">

                                            <div className="col-md-12 col-sm-12">
                                                <label className="lb">Department</label>
                                            </div>
                                            <div className="col-md-12 col-sm-12">
                                            <select className="form-control" onChange={ChangeDepartament} value={account.agencyId} required disabled={account.id !== undefined}>
                                               <option value="">Select Department</option>
                                                {departaments.map((item) => (
                                                    <option key={item.id} value={item.id}>{item.name}</option>
                                                ))}
                                            </select>
                                            </div>

                                        </div>
                                    <div className="row">

                                        <div className="col-md-12 col-sm-12">
                                            <label className="lb">Division</label>
                                        </div>
                                        <div className="col-md-12 col-sm-12">
                                            <select className="form-control" onChange={ChangeDivision} value={account.divisions} disabled={account.isView} required>
                                                <option value="">Select Division</option>
                                                {divisions.map((item) => (
                                                    <option key={`division ${item.id}`} value={item.id}>{item.name}</option>
                                                ))}
                                            </select>
                                        </div>

                                    </div>

                                        <div className="row">


                                            <div className="col-md-12 col-sm-12">
                                                {account.id !== undefined  && 
                                                    (
                                                        (!account.isView) 
                                                        ? 
                                                        <div className="col-md-12 col-sm-12" style={{marginTop: "25px", marginBottom: "5px"}}>
                                                            <Button
                                                                className="col-md-12 col-sm-12"
                                                                color="primary"
                                                                disabled={isLoading}>
                                                                    Edit Officer 
                                                                    {isLoading && (<>&nbsp;&nbsp;<span className="spinner-border spinner-border-sm"></span></>)}
                                                            </Button> 
                                                        </div>
                                                      
                                                        : 
                                                        <>
                                                     
                                                        <div className="row">&nbsp;</div>
                                                        </>
                                                    )
                                                }
                                               
                                                {(account.id === undefined || account.id == null || account.id === 0) &&
                                                <Button color="primary" className="col-md-12 col-sm-12" style={{marginTop: "25px", marginBottom: "5px"}} disabled={isLoading} >+ Add Officer {isLoading && (
                                                    <span className="spinner-border spinner-border-sm"></span>
                                                )}</Button>
                                            }

                                                <div className="col-md-12 col-sm-12" style={{marginTop: "10px", marginBottom: "5px"}}>
                                                    <Button className="col-md-12 col-sm-12" onClick={closeModal}>
                                                        <div style={{fontSize: "14px"}}>Cancel</div>
                                                    </Button>
                                                </div>
                                            
                                            </div>
                                        </div>



                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
         
        </>
    );
}

export default ModalAccount;