import { useEffect, useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader, Row, Col, Button, Form} from "reactstrap"
import Table from "../../../components/Common/table";
import api from "../../../services/api";
import AuthService from "../../../services/auth.service";
import Pagination from "../../../components/Common/pagination";
import AddFolderModal from "./AddFolderModal";
import folderIcon from "../../../assets/folderIcon.png"
import btnIconPlus from "../../../assets/BntIconPlus.png"
import savedIcon from "../../../assets/savedIcon.png"
import { useForm } from 'react-hook-form';
import { NotificationContainer, NotificationManager } from 'react-notifications';
const ShowFoldersModal = (props) =>
{
    //Table folders 
    const columns = [
        {
            Header: "",
            accessor: "folderName",
            Cell: cellInfo => <>
                <div onClick={() => onSelectChange(cellInfo.data[cellInfo.row.index])}>
                    <Row>
                        <Col sm={3}>
                            <img className="folderIcon" src={cellInfo.data[cellInfo.row.index].notCanDelete ===false ?  folderIcon: savedIcon} />
                        </Col>
                        <Col className="my-auto">
                            {cellInfo.data[cellInfo.row.index].folderName}
                        </Col>
                    </Row>
                    
                </div>

            </>
        }
    ];

    const [pageData, setPageData] = useState({
        rowData: [],
        isLoading: false,
        totalPages: 0,
        totalData: 0,
    });
    const [loading, setLoading] = useState(false);
    const [searchText, setSearchText] = useState();
    const [showNewFolder, setShowNewFolder] = useState(false);
    const { datamodel } = props;
    const [showCreateThread, setShowCreateThread] = useState(false);

    const loadFolders = async (filter) => {
        await api.get(
            `/releatedcrimes/get?page=${currentPage}&take=7&incident=${props.data === undefined ? 0:props.data.id}&filter=${filter}`, AuthService.getToken()
        ).then((info) => {
            const { totalPages, data, totalData } = info.data;
            setPageData({
                isLoading: false,
                rowData: data,
                totalPages,
                totalData,
            });
        });
    }

    const [currentPage, setCurrentPage] = useState(1);
    const openNewFolder = () => {
        setShowCreateThread(true);
    }

    const closeNewFolder = () => {
        setShowNewFolder(false);
    }

    useEffect(() => {
        if (datamodel != undefined && datamodel.id !== 0) {
            loadFolders("");
        }
       
    }, [currentPage, datamodel])

    const onSelectChange = (action) =>
    {
        saveRelateCrime({ name: action.folderName + ":" + props.datamodel.incidentNum, folder: action.id});
    } 
    const onSearchChanged = (e) => {
        setSearchText(e.target.value);
        loadFolders(e.target.value);
    }

    //New thread
    const [exists, setExists] = useState(false);
    const { register, handleSubmit, setValue, formState: { errors }, setError, reset } = useForm({
        mode: 'onChange'
    });

 

    const saveRelateCrime = (data) => {
        setLoading(true);
        var formData = new FormData();

        formData.append("Name", data.name);
        formData.append("IncidentId", props.datamodel.id);
        formData.append("FolderId", data.folder ? data.folder : 0);
        formData.append("IncidentNum", props.datamodel && props.datamodel.incidentNum ? props.datamodel.incidentNum : "");

        api.post("/releatedcrimes/relatecrime", formData, AuthService.getTokenPost()).then(response => {
            NotificationManager.success('The relate crime created successfully', 'Success', 5000);
            props.ReloadRelated();
            setLoading(false);
            props.closeFolders();
            props.AfterSaveRelateCrime();
            reset({ name: ""});
        });
    }

    return <>
        <Modal size="sm" isOpen={props.show}>
            <ModalHeader toggle={() => { props.closeFolders(); }} cssModule={{ 'modal-title': 'w-100 text-center' }}>
                Save to
            </ModalHeader>
            <ModalBody>
                {/*<Row>*/}
                {/*    <Col>*/}
                {/*        <div className="input-group mb-12">*/}
                {/*            <input onChange={onSearchChanged} value={searchText} type="text" className="form-control" placeholder="Keywords search..." />*/}
                {/*            <div className="input-group-append">*/}
                {/*                <span className="input-group-text" id="basic-addon2" >GO!</span>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </Col>*/}
                {/*</Row>*/}
                <div>
                    <div className="withoutHeader">
                        <Table  columns={columns} data={pageData.rowData} />
                    </div>
                    <Pagination
                        totalRows={pageData.totalData}
                        pageChangeHandler={setCurrentPage}
                        rowsPerPage={7}
                    />
                </div>

            </ModalBody>
            <ModalFooter className="borde_footer">
                <Form onSubmit={handleSubmit(saveRelateCrime)}>

                    {
                        showCreateThread === false &&
                        <Row>
                            <Col>
                                <Button
                                    type="submit"
                                    className="plusbtnIcon buttonWithoutBorder"
                                    onClick={openNewFolder}
                                    disabled={loading}
                                >
                                    <img src={btnIconPlus} />
                                    &nbsp;
                                    New thread

                                </Button>
                            </Col>
                        </Row>
                    }
               

                    {
                        showCreateThread === true &&
                        <>
                            <div className="row">
                                <label>Name</label>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <input type="text" className={errors.name ? 'form-control is-invalid' : 'form-control'} {...register("name", { required: true, maxLength: 200 })} placeholder="Name..." />
                                    {errors.name && errors.name.type === "required" && <span className="text-danger">This is required</span>}
                                    {exists && <p className="alert alert-danger">The folder name already exists.</p>}
                                </div>
                            </div>
                            <br/>
                            <Row>
                                <Col lg={4}></Col>
                                <Col>
                                    <div className="d-flex justify-content-end">
                                        <Button className="cancelBtn" onClick={() => {
                                            setShowCreateThread(false);
                                        }}>
                                            Cancel
                                        </Button>
                                        {' '}&nbsp;&nbsp;
                                        <Button
                                            type="submit"
                                            color="primary"
                                            disabled={loading}
                                        >
                                            {"Create"}
                                            {loading && (
                                                <span className="spinner-border spinner-border-sm"></span>
                                            )}
                                        </Button>
                                    </div>
                                  
                                </Col>
                            </Row>
                        </>
                    }

               
                </Form>
            </ModalFooter>
        </Modal>
        <AddFolderModal show={showNewFolder}  closeModal={closeNewFolder} />
    </>;
}

export default ShowFoldersModal;