import React from "react";

const CardPostFilterBy = (props) => {

    return (<>
    <br/>
        <div className="col-1" style={{marginRight: "10px"}}>
            <input type="checkbox" className="btn-check" id={"btn-check-1-outlined-" + props.Key} onChange={props.ToggleOfficers} checked={props.Officers} />
            <label className={`btn btn-primary ${props.Officers ? "" : "inactive"} label-filter-width`} htmlFor={"btn-check-1-outlined-" + props.Key} >OFFICERS</label><br />
           
        </div>
        <div className="col-1" style={{marginRight: "10px"}}>
            <input type="checkbox" className="btn-check" id={"btn-check-2-outlined-" + props.Key} onChange={props.TogglePosts} checked={props.Posts} />
            <label className={`btn btn-primary ${props.Posts ? "" : "inactive"} label-filter-width`} htmlFor={"btn-check-2-outlined-" + props.Key}>POSTS</label><br />
        </div>
        <div className="col-1" style={{marginRight: "10px"}}>
            <input type="checkbox" className="btn-check" id={"btn-check-3-outlined-" + props.Key} onChange={props.ToggleFiles} checked={props.Files} />
            <label className={`btn btn-primary ${props.Files ? "" : "inactive"} label-filter-width`} htmlFor={"btn-check-3-outlined-" + props.Key}>FILES</label><br />
        </div>
        <div className="col-1" style={{marginRight: "10px"}}>
            <input type="checkbox" className="btn-check" id={"btn-check-4-outlined-" + props.Key} onChange={props.ToggleAgencies} checked={props.Agencies} />
            <label className={`btn btn-primary ${props.Agencies ? "" : "inactive"} label-filter-width`} htmlFor={"btn-check-4-outlined-" + props.Key} >AGENCIES</label><br />
        </div>
    </>);

}


export default CardPostFilterBy;