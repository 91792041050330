import React from "react";
import {faCircleXmark,faUserGroup,faBookmark,faShareSquare,faBell,faTrashCan,faTriangleExclamation,faPencil,faBrain } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row, Col } from 'reactstrap';
import { library } from '@fortawesome/fontawesome-svg-core';
import './OptionsPost.css';
import AuthService from "../../services/auth.service";
import api from "../../services/api";

library.add(faCircleXmark,faUserGroup, faBookmark, faShareSquare, faBell, faTrashCan, faTriangleExclamation, faPencil, faBrain)
const CardPostOptionsPost = (props) => {
    const { data, OpenSharePost, OpenReportPost } = props;
    
    const removeRelate = (data) => {


        api.post("/releatedcrimes/deletepost", { Id: data.Id }, AuthService.getTokenPost())
            .then((response) => {
                props.AfterRemoveRelateCrime();
            });
    }

    return (
        <>
            <Row className="option">
                {
                    (data.relateCrimes && data.relateCrimes.length === 0) &&
                    <>
                        <Col md="2" >
                            <FontAwesomeIcon className="iconPopup" icon={faBookmark} size="xl" />
                        </Col>
                        <Col md="5">
                            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                            <a className="transparentLink" href="#" onClick={(e) => props.OpenFolders(e, data)}>
                                Save post
                            </a>
                        </Col>
                    </>
                }
                {

                    (data.relateCrimes && data.relateCrimes.length > 0) &&
                    <>
                        <Col md="2" className="centerDivVerticall">
                            <FontAwesomeIcon className="iconPopup" icon={faCircleXmark} size="xl" />
                        </Col>
                        <Col md="6">
                            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                            <a className="transparentLink" href="#" onClick={(e) => removeRelate({ Id: data.relateCrimes[0].id })}>
                                Remove from {' '}
                                {data.relateCrimes[0].name}
                            </a>
                        </Col>
                    </>
                }


            </Row>

            {
                (data.canEdit && data.canEdit === true && !props.IsSuperAdmin) && <Row className="option">

                    <Col md="2">
                        <a href={"/post/edit/" + data.id}>
                            <FontAwesomeIcon className="iconPopup" icon={faPencil} size="xl" />
                        </a>
                    </Col>
                    <Col md="5">
                        <a href={"/post/edit/" + data.id}>
                            Edit Post
                        </a>
                    </Col>

                </Row>
            }
            

            <Row className="option" onClick={OpenReportPost}>
                <Col md="2">
                    <FontAwesomeIcon className="iconPopup" icon={faTriangleExclamation} size="xl" />
                </Col>
                <Col md="5">
                    Report Post
                </Col>

            </Row>


            {
                data.canEdit && data.canEdit === true && <Row className="option" onClick={() => {
                    props.DeleteIncident(data.id);
                }}>
                    <Col md="2">
                        <FontAwesomeIcon className="iconPopup" icon={faTrashCan} size="xl" />
                    </Col>
                    <Col md="10">
                        Remove Post
                    </Col>

                </Row>
            }
           

            <Row className="option" onClick={()=> props.SetNotification(data)}>
                <Col md="2">
                    <FontAwesomeIcon className="iconPopup" icon={faBell} size="xl" />
                </Col>
                <Col md="10">
                    Get Notified
                </Col>

            </Row>

            <Row className="option" onClick={OpenSharePost}>
                <Col md="2">
                    <FontAwesomeIcon className="iconPopup" icon={faShareSquare} size="xl" />
                </Col>
                <Col md="10">
                    Share Post with fellow user
                </Col>

            </Row>

        </>
    );
}
     export default CardPostOptionsPost;