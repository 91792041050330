import React, { useState } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import { useHistory } from "react-router-dom";
import AuthService from "../../../../services/auth.service";
import '../Login.css';
import nbcLogo from "../../../../assets/v2/auth_logo.png";



const LoginForm = (props) => {
    let history = useHistory();
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const [isvalid, setIsvalid] = useState();
    const [validation, setValidation] = useState({
        email: "",
        password: "",
        code: ""
    });

    const checkValidation = () => {
        let errors = validation;
        let isvalid = true;
        setIsvalid(true);
        setValidation({
            email: "",
            password: ""
        });
        if (!username.trim()) {
            errors.email = "Email is required";
            isvalid = false;
        }
        if (!password.trim()) {
            errors.password = "Password is required";
            isvalid = false;
        }
        // eslint-disable-next-line
        const regex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
        if (!password || regex.test(password) === false) {
            errors.password = " Password format is wrong (miminum 8 letters ( 1 lowercase,uppercase,numeric,special character)!";
            isvalid = false;
        }
        // eslint-disable-next-line
        const regexemail = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        if (!username || regexemail.test(username) === false) {
            errors.email = " This is not a valid email";
            isvalid = false;

        }
        setValidation(errors);
        return isvalid;

    }

    const onChangePassword = e => {
        setPassword(e.target.value);

    }

    const onChangeUsername = e => {
        setUsername(e.target.value);
    }

    const handleLogin = (e) => {
        setMessage("");
        setLoading(true);

        if (e !== undefined) {
            e.preventDefault();
        }

        let val = checkValidation();

        if (val) {
            AuthService.login(username, password).then(
                (element) => {

                    if (element.requiresTwoFactor && element.requiresTwoFactor === true && element.multipleOptions === false) {
                        setLoading(false);
                        props.EnableReqCode();
                        props.UNChanged(username);
                    }
                    else if (element.requiresTwoFactor && element.requiresTwoFactor === true && element.multipleOptions && element.multipleOptions === true) {
                        setLoading(false);
                        props.EnableOptCode();
                        props.UNChanged(username);
                    }
                    else if (element.roles != "") {
                        history.push("/home");
                        history.go(0);
                    } else {
                        history.push("/");
                        history.go(0);
                    }

                },
                error => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    if (resMessage.includes("deactivated") || resMessage.includes("suspended")) {
                        setMessage(resMessage);
                    } else {
                        setMessage("The email or password you entered is incorrect");
                    }

                    setLoading(false);
                }
            );
        } else {
            setLoading(false);
        }
    }

    const handleKeyPress = (event) => {
        if (event.charCode === 13) {
            if (event.target.id === "3") {

            } else if (event.target.id === "1" || event.target.id === "2") {
                handleLogin();
            }
        }
    }

    return (
        <Form className="login-credentials-form">
            <div className="form-group login">
                <label className="login-label" htmlFor="username">Email</label>
                <Input
                    id = "1"
                    type="text"
                    className="form-control"
                    placeholder="Enter your email"
                    name="username"
                    value={username}
                    onChange={onChangeUsername}
                    onKeyPress={handleKeyPress}

                />
                {validation.email && <p className="alert alert-danger">{validation.email}</p>}
            </div>

            <div className="form-group login">
                <label className="login-label" htmlFor="password">Password</label>
                <Input
                    id = "2"
                    type="password"
                    className="form-control"
                    placeholder="Enter your password"
                    name="password"
                    value={password}
                    onChange={onChangePassword}
                    onKeyPress={handleKeyPress}

                />
                {validation.password && <p className="alert alert-danger">{validation.password}</p>}
            </div>

            <div className="form-group forgot-password">
                <Link className="forgot-link" to="/forgotpassword"  >Forgot password</Link>
            </div>

            <div className="form-group button">
                <button
                    onClick={handleLogin}
                    className="btn btn-primary btn-block login-button"
                    disabled={loading}
                >
                    {loading && (
                        <span className="spinner-border spinner-border-sm"></span>
                    )}
                    <span>Sign In</span>
                </button>
            </div>

            {message && (
                <div className="form-group">
                    <div className="alert alert-danger" role="alert">
                        {message}
                    </div>
                </div>
            )}

            <p className="no-account-label"> Don't have an account? </p>
            <p className="contact-supervisor-label"> Please contact your supervisor </p>
            <p className="build-number-label"> Version 1.0 Build 202304182023 </p>
        </Form>
    );
};

export default LoginForm;