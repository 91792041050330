import React, {useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Card, CardBody, CardText, CardTitle, Form, Input } from "reactstrap";
import authService from "../../../services/auth.service";
import './ForgotPassword.css';
import nbcLogo from "../../../assets/v2/auth_logo.png";
import keyImg from "../../../assets/v2/password_key.png";

const NewForgotPassword = ()=>
{
    let history = useHistory();
    const [email, setEmail] = useState();
    const [isvalid, setIsvalid] = useState();
    const [loading, setLoading] = useState(false);
    const [successResult, setSuccessResult] = useState();
    const [validation, setValidation] = useState({
        email:"",
    });
    const onChangeEmail = e =>
    {
        setEmail(e.target.value);
       
    }

    const onBack = () => {
        history.goBack();
    }

    const handleForgotPassword = (e) => {
        setLoading(true);
        e.preventDefault();
        let errors = validation;
        setIsvalid(true);
        // eslint-disable-next-line
        const regexemail = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        if (!email || regexemail.test(email) === false) {
            errors.email = " This is not a valid email";
            setIsvalid(false);
            setLoading(false);
        }
        else{
            errors.email = "";
            setIsvalid(false);
        }
        setValidation(errors);

        authService.forgotPassword(email).then(
            () => {

                setSuccessResult(true);
                setEmail("");
                setLoading(false);
                setTimeout(function () { history.push("/login"); }, 4000);
            },
            error => {
                setLoading(false);
            }
        );
    };


    return (
        <div className="forgot-password-main">
            <img className="forgot-password-logo-img" src={nbcLogo} />
            <div className="forgot-password-main-form">
                <div className="outer-dot"></div>
                <div className="inner-dot"></div>
                <img className="forgot-password-key-img" src={keyImg} />
                <h1 className="forgot-password-header"> Forgot Password </h1>
                <p className="forgot-password-message"> No worries, we'll send you reset instructions </p>

                <Form onSubmit={handleForgotPassword} className="form-group forgot-password-form">
                    <div>
                        <label className="forgot-password-email-label" htmlFor="username">Email</label>
                        <Input
                            id="email"
                            name="email"
                            onChange={onChangeEmail}
                            value={email ?? ""}
                            type="email"
                            placeholder="Enter your email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" required
                        />
                        {!isvalid && validation.email && <p className="alert alert-danger">{validation.email}</p>}
                    </div>

                    <div className="form-group button">
                        <button
                            className="btn btn-primary btn-block forgot-password-button"
                            disabled={loading}>
                            {loading && (
                                <span className="spinner-border spinner-border-sm"></span>
                            )}
                            <span>Send</span>
                        </button>
                    </div>
                </Form>
                <button className="forgot-password-back-to-login" onClick={onBack}>
                    <span> &lt; Back to Log in</span>
                </button>
            </div>
        </div>
    );
}

export default NewForgotPassword;