import React, { useState, useEffect, useRef } from "react";
import Table from "../../components/Common/table";
import api from "../../services/api";
import Pagination from "../../components/Common/pagination";
import { Input, Button } from "reactstrap";
import { Row, Col} from "react-bootstrap"
import AuthService from "../../services/auth.service";
import Avatar from 'react-avatar';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

library.add(faXmark);
const ManageAdmins = (props) => {

    const [selectedAdmins, setSelectedAdmins] = useState([]);
    const [loadingAdmins, setLoadingAdmins] = useState(false);
    const [value, setValue] = useState();

    const getData = async (pageNo = 1) => {
        const response = await api.get(
            `/agency/getUsers?page=${pageNo}&take=15&agencyId=${props.Agency}&all=false`
        );
        return response.data;

    };

    const manageAdmin = async (userAgency) => {
        const response = await api.post(
            `/agency/manageadmin`, userAgency, AuthService.getTokenPost()
        ).then(() => {
            loadData();
        });
    }

    const columns = [
        {
            Header: "",
            accessor: "UserName",
            Cell: cellInfo => <>
                <Row>
                    <Col lg={3}>
                        {cellInfo.data[cellInfo.row.index].path && <img className="picPreview" src={cellInfo.data[cellInfo.row.index].path} />}

                        {(cellInfo.data[cellInfo.row.index].path === undefined || cellInfo.data[cellInfo.row.index].path === null ||  cellInfo.data[cellInfo.row.index].path === "") && <Avatar className="sb-avatar--textProfile" size="40" round="50%" name={cellInfo.data[cellInfo.row.index].userName}></Avatar>}
                    </Col>
                    <Col>
                        <Row>
                            <Col>
                                <label>
                                    {cellInfo.data[cellInfo.row.index].userName}
                                 </label>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                {cellInfo.data[cellInfo.row.index].division}
                            </Col>
                        </Row>
                    </Col>
                </Row>
                </>
        },
        {
            Header: "",
            accessor: "isAdmin",
            Cell: cellInfo =>
                <>
                    {cellInfo.data[cellInfo.row.index].userId !== AuthService.getId() ? 
                        <div className="elementRight">
                            <Button onClick={() => { manageAdmin(cellInfo.data[cellInfo.row.index]) }} className="adminBtn">
                                Admin
                                <FontAwesomeIcon icon="fa-solid fa-xmark" />
                            </Button>
                        </div>
                    : 
                    <></>
                    }
                </>
        }
    ];

    const [pageData, setPageData] = useState({
        rowData: [],
        isLoading: false,
        totalPages: 0,
        totalData: 0,
    });

    const [currentPage, setCurrentPage] = useState(1);

    const loadData = () => {
        setPageData((prevState) => ({
            ...prevState,
            rowData: [],
            isLoading: true,
        }));
        getData(currentPage).then((info) => {
            const { totalPages, data, totalData } = info;
            setPageData({
                isLoading: false,
                rowData: data,
                totalPages,
                totalData,
            });
        });
    }

    useEffect(() => {

        loadData();
    }, [currentPage]);

    const [open, setOpen] = React.useState(false);
    const [options, setOptions] = React.useState([]);
    const loading = open && options.length === 0;




    React.useEffect(() => {
        let active = true;

        if (!loading) {
            return undefined;
        }

        (async () => {
            await sleep(1e3); // For demo purposes.

            if (active)
            {
                
                 await api.get(
                    `/agency/getUsers?page=${0}&take=15&agencyId=${props.Agency}&all=true`
                 ).then((result) => {
                     setOptions(result.data.data);
                    
                     
                 });
            }
        })();

        return () => {
            active = false;
        };
    }, [loading, setOptions]);

    React.useEffect(() => {
        if (!open) {
            setOptions([]);
        }
    }, [open]);

    function sleep(delay = 0) {
        return new Promise((resolve) => {
            setTimeout(resolve, delay);
        });
    }

    const handleChange = (event, value) =>
    {
        setSelectedAdmins(value);
    }
    const autoC = useRef(null);
    const asignAdmins = async () =>
    {
        setLoadingAdmins(true);
        const response = await api.post(
            `/agency/manageadmins`, selectedAdmins, AuthService.getTokenPost()
        ).then(() => {
            loadData();
            setLoadingAdmins(false);
            setValue([]);
            const ele = autoC.current.getElementsByClassName('MuiAutocomplete-clearIndicator')[0];
            if (ele) ele.click();
        });
    }


    return <>
        <Row>
            <Col>
                <Autocomplete
                    disablePortal
                    ref={autoC}
                    multiple
                    id="asynchronous-demo"
                    open={open}
                    value={selectedAdmins}
                    onChange={handleChange}
                    onOpen={() => {
                        setOpen(true);
                    }}
                    onClose={() => {
                        setOpen(false);
                    }}
                    isOptionEqualToValue={
                        (option, value) =>
                            option.userId === value.userId
                    }
                    getOptionLabel={(option) => option.userName}
                    options={options}
                    loading={loading}
                    renderOption={(props, option) => (
                        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>

                            <Row>
                                <Col lg={4}>
                                    {option.path && <img className="picPreview" src={option.path} />}
                                    {(option.path === undefined || option.path === null || option.path === "") && <Avatar className="sb-avatar--textProfile" size="40" round="50%" name={option.userName}></Avatar>}
                                    
                                </Col>
                                <Col>
                                    <Row>
                                        <Col>
                                            <label>
                                                {option.userName}
                                            </label>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            {option.division}
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Box>
                    )}
                    renderInput={(params) => (
                        <>
                            <TextField
                                {...params}
                                label="Search user..."
                                value={selectedAdmins}
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <React.Fragment>
                                            {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                            {params.InputProps.endAdornment}
                                        </React.Fragment>
                                    ),
                                }}
                            />
                        </>
                    )}
                />
            </Col>
            <Col lg={3}>
                <button disable={loadingAdmins} type="button" onClick={asignAdmins} className="btn btnAsign">
                    {loadingAdmins && (
                        <span className="spinner-border spinner-border-sm"></span>
                    )}
                    Assign

                </button>
            </Col>
        </Row>
        

        <div id="tableWithoutHead">
            <Table

                columns={columns}
                data={pageData.rowData}
                isLoading={pageData.isLoading}
            />
        </div>
        <Pagination
            totalRows={pageData.totalData}
            pageChangeHandler={setCurrentPage}
            rowsPerPage={15}
        />
    </>


}
export default ManageAdmins;