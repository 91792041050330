import { Button, Col, Form, Modal, ModalFooter, ModalHeader, Row, ModalBody,NavLink} from "reactstrap"
import { useForm } from 'react-hook-form';
import { useState } from "react";
import api from "../../../services/api";
import AuthService from "../../../services/auth.service";
import Table from "../../../components/Common/table";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { useEffect } from "react";
import Pagination from "../../../components/Common/pagination";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faFolder } from '@fortawesome/free-solid-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core';
import { Link } from 'react-router-dom';


library.add(faEdit, faTrash);
library.add(faEdit, faTrash, faFolder);
const Folders = () =>
{
    const [showModal, setShowModal] = useState(false);
    const { register, handleSubmit, setValue, formState: { errors }, setError , reset} = useForm({
        mode: 'onChange'
    });
    const [loading, setLoading] = useState(false);
    const [exists, setExists] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [folderName, setFolderName] = useState("");
    const [currentFolder, setCurrentFolder] = useState();

    const [folder, setFolder] = useState({
        name : ""
    });

    const openModal = () => {
        setShowModal(true);
    }

    const SaveInformation = (data) =>
    {
        setLoading(true);
        var formData = new FormData();

        formData.append("Name", data.name);
        formData.append("Id", (currentFolder !== null && currentFolder !== undefined && currentFolder.id > 0) ? currentFolder.id : 0 );

        api.post("releatedcrimes/save", formData, AuthService.getTokenPost()).then((result) =>
        {
            if (result.data && result.data.status ===0) {
                reset({ name: "" });
                setShowModal(false);
                setExists(false);
                loadFolders();
                NotificationManager.success('Success', 'Folder added', 5000);
                setCurrentFolder({});
            }
            if (result.data && result.data.status === -1) {
                setExists(true);
            }
            
            setLoading(false);
        }, error => {
            setCurrentFolder({});
            setLoading(false);
        });
    }

    const handleEdit = (data) => {
        setCurrentFolder(data);
        setValue("name", data.folderName);
        setShowModal(true);
    }

    const handleDelete = (data) => {
        setCurrentFolder(data);
        setFolderName(data.folderName);
        setShowDeleteModal(true);
    }

    //Table folders 
    const columns = [
        {
            Header: "Folder Name",
            accessor: "folderName",
        },
        {
            Header: "Created Date",
            accessor: "createdDate",
        },

        {
            Header: "Updated Date",
            accessor: "updatedDate",
        },
        {
            Header: "Actions",
            accessor: "",
            className: "flex-col",
            Cell: cellInfo => 
                <>
                    <NavLink tag={Link} className="btn btn-primary col-4" to={"/releatedcrimes/view/" + cellInfo.data[cellInfo.row.index].id}><FontAwesomeIcon icon={faFolder} />OPEN</NavLink>
                    <Button className="col-4" onClick={() => handleEdit(cellInfo.data[cellInfo.row.index])}> <FontAwesomeIcon icon={faEdit} />Edit</Button> &nbsp;
                    <Button className="col-4" onClick={() => handleDelete(cellInfo.data[cellInfo.row.index])}> <FontAwesomeIcon icon={faTrash} />Delete</Button>
                   
                </>
            
        },
    ];
    

    const [pageData, setPageData] = useState({
        rowData: [],
        isLoading: false,
        totalPages: 0,
        totalData: 0,
    });
    const [currentPage, setCurrentPage] = useState(1);
    useEffect(() => {
        loadFolders();
    }, [currentPage])

    const loadFolders = async () => {
        await api.get(
            `/releatedcrimes/get?page=${currentPage}&take=15&`, AuthService.getToken()
        ).then((info) => {
            const { totalPages, data, totalData } = info.data;
            setPageData({
                isLoading: false,
                rowData: data,
                totalPages,
                totalData,
            });
        });
    }

    const sendDelete = async () => {
        setLoading(true);


        api.get("/releatedcrimes/delete?id=" + currentFolder.id, AuthService.getTokenPost()).then((result) => {

            if (result.data.status && result.data.status === true) {
                setShowDeleteModal(false);
                NotificationManager.success('Success', 'Folder deleted!', 5000);
                loadFolders();
            }
            setCurrentFolder({});
            setLoading(false);
        }, error => {
            setCurrentFolder({});
            setLoading(false);
        });
    }

   
   

    return <>
        
        <Row>
            <Col>
                <Button onClick={openModal} outline type="button">Add folder</Button>
            </Col>
        </Row>
        <br />
        <Row>
            <div>
                <Table columns={columns} data={pageData.rowData}></Table>
            </div>
            
            <Pagination
                totalRows={pageData.totalData}
                pageChangeHandler={setCurrentPage}
                rowsPerPage={15}
            />
        </Row>
       
        <Modal size="lg" isOpen={showModal}>
           
            <ModalHeader>
                <h4>{currentFolder !== null && currentFolder !== undefined && currentFolder.id > 0 ? "Update" : "Add"} new folder</h4>
            </ModalHeader>
            <ModalBody>
                <Form onSubmit={handleSubmit(SaveInformation)}>
                    <div className="row accountinp">
                        <div className="col-3">
                            <label>Folder Name:*</label>
                        </div>
                        <div className="col-6">
                            <input type="text" className={errors.name ? 'form-control is-invalid' : 'form-control'} {...register("name", { required: true, maxLength: 200 })} placeholder="Name..." />
                            {errors.name && errors.name.type === "required" && <span className="text-danger">This is required</span>}
                            {exists && <p className="alert alert-danger">The folder name already exists.</p>}
                        </div>
                    </div>
                    <ModalFooter>
                        <Button
                            type="submit"
                            color="primary"
                            disabled={loading}
                        >
                            {currentFolder !== null && currentFolder !== undefined && currentFolder.id > 0 ? "Update" : "Add" }
                            {loading && (
                                <span className="spinner-border spinner-border-sm"></span>
                            )}
                        </Button>
                        {' '}
                        <Button onClick={() => {
                            setShowModal(false);
                        }}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </Form>

            </ModalBody>
            
              
        </Modal>


        <Modal size="lg" isOpen={showDeleteModal}>

            <ModalBody>

                <div>
                    <label>Do you want to delete the folder: {folderName} ?</label>
                </div>
                  
            </ModalBody>
            <ModalFooter>
                <Button
                    type="submit"
                    color="primary"
                    onClick={sendDelete}
                    disabled={loading}
                >
                    Delete
                    {loading && (
                        <span className="spinner-border spinner-border-sm"></span>
                    )}
                </Button>
                {' '}
                <Button onClick={() => {
                    setShowDeleteModal(false);
                }}>
                    Cancel
                </Button>
            </ModalFooter>

        </Modal>

        <NotificationContainer />
    </>
}

export default Folders;