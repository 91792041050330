import { faBuilding, faCircle, faMessage, faPhone, faUserGroup, faAt } from '@fortawesome/free-solid-svg-icons';
import {  Row, Col} from 'reactstrap';
import { library } from '@fortawesome/fontawesome-svg-core';
import { useState, useEffect } from 'react';
import { NotificationManager } from 'react-notifications';
import Avatar from 'react-avatar';
import api from "../../services/api";
import "./ProfileCard.css";
import userCard from "../../assets/CardUser.svg";
import phone from "../../assets/Phone.svg";
import email from "../../assets/Email.svg";
import { useHistory } from 'react-router-dom';
library.add(faUserGroup, faCircle, faBuilding, faMessage, faPhone, faAt)
const ProfileCard = (props) => {
    let history = useHistory();
    const { id } = props;
    const [profile, SetProfile] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    const RedirectMessage = (state,userId, name, type, urlPic, phone, email, address, typechat) => {
        if (state != null && (state === "Suspended" || state === "Removed")){
            NotificationManager.info('Information', 'The account cannot be contacted because it was deleted or suspended.', 5000);
            return;
        }

        let img = urlPic;
        if (props.Incident !== undefined && (props.Incident.filesIncidents != null && props.Incident.filesIncidents !== undefined && props.Incident.filesIncidents.length >0)) {
            img = props.Incident.filesIncidents[0].file.path;
        }
        
        let varIsSharePost = false;
        varIsSharePost = props.Incident !== undefined;

        history.push({
            pathname: `/messages`,
            search: `?userId=${userId}&name=${name}&type=${props.Incident !== undefined ? props.Incident.type : type}&typechat=${typechat}&incident=${props.Incident !== undefined ? props.Incident.id : ""}&address=${props.Incident !== undefined ? props.Incident.address : ""}`,
            state: {
                urlPic: img,
                userId: userId,
                name: name,
                type: (props.Incident !== undefined ? props.Incident.type : type),
                typechat: typechat,
                incident:props.Incident !== undefined ? props.Incident.id : "",
                incidentNum:props.Incident !== undefined ? props.Incident.incidentNum : "",
                address: props.Incident !== undefined ? props.Incident.address : "",
                isSharePost: varIsSharePost,
                userFullName: name.trim(),
                userAddress: address,
                phone: phone,
                email: email,
            }

        });

    }

    useEffect(() => {
        setIsLoading(true);
        api.get(
            `/officer/getofficer?userId=${id}`
        ).then((response) => {
           
            SetProfile(response.data);
            setIsLoading(false);
            
        })
            .catch((error) => {
                setIsLoading(false);
                console.log(error);
            });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setIsLoading]);

    return (
        <>

            {isLoading && isLoading === true &&
                <div className="popupProfileContent">
                    <div className="bodyProfilePopup">
                        <div className="spinner-border text-primary" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                </div>
            }
            {!isLoading && isLoading === false &&

                <>
                    <div className="popupProfileContent">
                        <div className="popupHeaderProfile">

                            <Row className="headerProfile">
                                <Col lg={3}>
                                    {profile.urlPic && <img alt="" className="picUrlPreviewAdmin" src={profile.urlPic} />}
                                    {(profile.urlPic === undefined || profile.urlPic === "" || profile.urlPic === null) &&
                                        <Avatar className="sb-avatar--textProfile" size="40" round="50%" name={profile.name}></Avatar>}

                                </Col>
                                <Col lg={9}>
                                    <Row>
                                        <label className="nameProfile">{profile.name}</label>
                                    </Row>
                                    <Row>
                                        <label className="divisionProfile">{profile.division}</label>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                        <div className="bodyProfilePopup">
                            <Row>
                                {
                                    profile.address && profile.address !== "" && profile.address !== undefined && profile.address !== null &&
                                    <>
                                        <Row >
                                            <Col lg={1}>
                                                <object data={userCard} />
                                            </Col>
                                            <Col>
                                                &nbsp; <label>{profile.address}</label>
                                            </Col>
                                        </Row>
                                        <br />
                                        <br />
                                    </>
                                }

                                {(profile.showPhone && profile.showPhone === true && profile.phone && profile.phone !== "" && profile.phone !== " ") &&
                                    <>
                                        <a href={"tel:" + profile.phone}>
                                            <Row>

                                                <Col lg={1}>
                                                    <object data={phone} />
                                                </Col>
                                                <Col>
                                                    &nbsp; {profile.phone}
                                                </Col>

                                            </Row>
                                        </a>
                                        <br />
                                        <br />
                                    </>
                                }
                                {(profile.showEmail && profile.showEmail === true && profile.email && profile.email !== "" && profile.email !== " ") &&
                                    <>
                                        <a href={"mailto:" + profile.email}>
                                            <Row className="row">
                                                <Col lg={1}>

                                                    <object data={email} />
                                                </Col>
                                                <Col>
                                                    &nbsp; {profile.email}
                                                </Col>

                                            </Row>
                                        </a>
                                        <br />
                                        <br />
                                    </>
                                }
                                <Row >
                                <Col>
                                    {!(profile.statusApp === "Suspended" || profile.statusApp === "Removed" || profile.deleted === true) &&
                                        <button onClick={() => RedirectMessage(profile.statusApp, profile.id, profile.name, profile.division, profile.urlPic, profile.phone, profile.email, profile.address, "incident")} className="btn btn-outline-primary btn-lg btn-bloc col-lg-12" disabled={profile.deleted}>Message</button>
                                    
                                     }
                                </Col>
                                </Row>
                            </Row>
                        </div>
                    </div>
                </>
            }
        </>
    );
}

export default ProfileCard;