import React, {useState} from "react";
import "./Department.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PopoverBody, UncontrolledPopover } from 'reactstrap';
import { ReactComponent as ClockIcon } from './../../assets/clock.svg';
import { ReactComponent as DepartmentIcon } from './../../assets/Department.svg';
import { ReactComponent as EmailIcon } from './../../assets/Email.svg';
import { ReactComponent as FolderIcon } from './../../assets/folder.svg';  
import { ReactComponent as NoPreviewIcon }  from "./../../assets/nopreview.svg"; 
import { ReactComponent as NoResultFoundImage } from './../../assets/no_result_found.svg';  
import { ReactComponent as PhoneIcon } from './../../assets/Phone.svg';
import { ReactComponent as PinLocationIcon } from './../../assets/pin_location.svg'; 
import { red } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import { UsedDepartmentContext } from "./Department.Provider";
import {faTriangleExclamation,faTrashCan} from '@fortawesome/free-solid-svg-icons';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import Col from 'react-bootstrap/Col';
import Collapse from '@mui/material/Collapse';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import moment from 'moment';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Paper from '@mui/material/Paper';
import Row from 'react-bootstrap/Row';
import SearchIcon from '@mui/icons-material/Search';
import Select from 'react-select';
import Skeleton from '@mui/material/Skeleton';
import StatusBadge from "../Officer/StatusBadge";
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';


export const CommonPaperStyle = {
    component: "form", 
    elevation: 0,
    variant: "outlined",
    sx: {
        display: 'flex', alignItems: 'center'
    }
};

const IncidentCategory = {
    Bolos: 1,
    OfficerSafety: 2,
    AttemptToId: 3,
    Feed: 4
}

export const PrimaryFontFamily = "Work Sans";

//[Start] - Header
export const HeaderLabel = ({label}) => {
    return (
        <Row>
            <Col>
                <div className="common_font_style header_label_style">{label}</div>
            </Col>
        </Row>
    );
}
//[End] - Header


//[Start] - Search Officer Text Field
export const SearchOfficerField = ({onChange}) => {
    return (
        <Paper {...CommonPaperStyle} >
            <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                <SearchIcon />
            </IconButton>
            <InputBase sx={{ ml: 1, flex: 1 }} placeholder="Search officers..." inputProps={{ 'work sans': 'search officers' }} onChange={onChange}/>
        </Paper>
    );
}
//[End] - Search Officer Text Field

//[Start] - Date Range Picker Field
const dateRangeInitialSettings = ({startDate, endDate}) => {
    return {
        startDate: startDate ? startDate.toDate() : moment().subtract(29, 'days'),
        endDate: endDate? endDate.toDate() : moment(),
        ranges: {
            Today: [moment().toDate(), moment().toDate()],
            Yesterday: [
                moment().subtract(1, 'days').toDate(),
                moment().subtract(1, 'days').toDate(),
            ],
            'Last 7 Days': [
                moment().subtract(6, 'days').toDate(),
                moment().toDate(),
            ],
            'Last 30 Days': [
                moment().subtract(29, 'days').toDate(),
                moment().toDate(),
            ],
            'This Month': [
                moment().startOf('month').toDate(),
                moment().endOf('month').toDate(),
            ],
            'Last Month': [
                moment().subtract(1, 'month').startOf('month').toDate(),
                moment().subtract(1, 'month').endOf('month').toDate(),
            ],
        },
    }
}

export const InitialDateRangeState = () => {
    return {
        startDate: moment().subtract(29, 'days'),
        endDate: moment(),
    }
}

export const DatePickerField = (props) => {
    const [selectedDateRange, setSelectedDateRange] = useState(InitialDateRangeState);

    const { startDate, endDate } = selectedDateRange;

    const handleCallback = (startDate, endDate) => {
        setSelectedDateRange({ startDate, endDate });
        props.onChangeDateValues({ startDate, endDate });
    };

    return (
        <Paper {...CommonPaperStyle}  >
            <InputBase sx={{ ml: 1, flex: 1 }} 
                       placeholder="All time" 
                       inputProps={{ 'work sans': 'all time' }} 
                       value={startDate.format('MMMM D, YYYY') + ' - ' + endDate.format('MMMM D, YYYY')} />
            <DateRangePicker initialSettings={dateRangeInitialSettings(startDate.toDate(), endDate.toDate())} onCallback={handleCallback}>
                <IconButton type="button" sx={{ p: '10px' }} aria-label="all time" >
                    <CalendarMonthIcon />
                </IconButton>
            </DateRangePicker>
        </Paper>
    );

}
//[End] - Date Range Picker Field

//[Start] - Department drodown
export const DepartmentDropDownField = ({data, onChange}) => {
    return (
        <Select className="basic-single"
                classNamePrefix="departments"
                components={{IndicatorSeparator: () => null}}
                defaultValue={data[0]}
                isDisabled={false}
                isLoading={false}
                isRtl={false}
                isSearchable={true}
                name="color"
                onChange={onChange}
                options={data}
                styles={{control: styles => 
                    ({ ...styles
                        , minHeight: "45px"
                        , borderColor: "#cccccc"
                        , boxShadow: "0 0 0 0px #cccccc"
                        , "&:hover": {
                            borderColor: "#cccccc"
                        }}
                    )
                }}
    />);
}
//[End] - Department drodown

//[Start] - Incident Post Container
export const IncidentPostContainer = ({data}) => {
   
    const [expanded, setExpanded] = useState(false);


    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    return (
        <Card {...IncidentPostMainContainerStyle}>
               <IncidentPostHeaderContainer 
                    userId={data.userId}
                    fullName={data.fullName}
                    initials={data.initials}
                    department={data.department} 
                    division={data.division} 
                    email={data.email}
                    phoneNumber={data.phoneNumber} 
                    profilePicSource={data.profilePicSource}
                    suspended={data.suspended}
                    deleted={data.deleted}
                    isAllowSuspended={data.isAllowSuspended}
                    />

                <IncidentPostDetailContainer expanded={expanded} data={data.incidents} />
                <IncidentPostFooterContainer expanded={expanded} onClick={handleExpandClick} />
            
                
        </Card>

    );
}

const SubHeaderContainerStyle = {
    alignItems: "center", 
    color: "#002542", 
    display: "flex", 
    flexDirection: "row", 
    fontFamily: "Work Sans", 
    fontSize: "16px", 
    fontWeight: 400, 
    paddingTop: "16px"
}

const SubHeaderTextContainerStyle = {
    padding: "3px 25px 0px 10px"
}

const IncidentPostHeaderContainer = (props) => {

    const AvatarContainer = () => {
        return (
            <Avatar src={props.profilePicSource} sx={{ bgcolor: red[500], width: 80, height: 80 }} aria-label="recipe">{props.initials}</Avatar> 
        );
    }

    const ActionContainer = () => {
        return (
            <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center"}}>
                {
                    props.suspended ? 
                    (<div style={{paddingRight: "15px"}}>
                        {/* <div style={{marginRight: "5px"}}><FontAwesomeIcon color={"#FF0404"} icon={faTriangleExclamation} size="xs" /></div>
                        <div style={{fontFamily: PrimaryFontFamily, fontSize: "14px", color: "#EC0000", marginRight: "15px"}}>Suspended</div> */}
                        {StatusBadge(2)}
                    </div>) 
                    : 
                    (
                        props.isAllowSuspended ? (
                            props.deleted ? (<div style={{paddingRight: "15px"}}>{StatusBadge(3)}</div>) 
                            : 
                            (
                            <div style={{paddingRight: "15px"}}>
                                {StatusBadge(1)}
                                {/** Uncomment this when re-activating the "Suspend" account functionality
                                 * 
                                <IconButton aria-label="settings" id={"header-id-" + props.userId}><MoreVertIcon /></IconButton>
                                <UncontrolledPopover trigger="legacy" placement="bottom" target={`${"header-id-" + props.userId}`}>
                                  <PopoverBody>
                                     <PopoverSuspendContainer userId={props.userId} />
                                  </PopoverBody>
                                </UncontrolledPopover> 
                                *
                                **/}
                            </div>)
                        ) : (<></>)
                    )
                }
                
            </div>
        );
    }

    const TitleContainer = () => {
        return (
            <div style={{display: "flex", flexDirection: "row",  alignItems: "center", fontFamily: PrimaryFontFamily, fontSize: "16px"}}>
                <div style={{paddingRight: "10px", fontWeight: 500, color: "#002542"}}>{props.fullName}</div> 
                <div style={{fontWeight: 400, color: "#5F798C"}}>{props.division}</div>
            </div>
        );
    }

    const SubHeaderContainer = () => {
        return (
            <div style={SubHeaderContainerStyle}>
                <DepartmentIcon width={25} height={25} />
                <div style={SubHeaderTextContainerStyle}>
                    {props.department}
                </div>

                <PhoneIcon width={25} height={25} />
                <div style={SubHeaderTextContainerStyle}>
                    {props.phoneNumber}
                </div>

                <EmailIcon width={25} height={25} />
                <div style={SubHeaderTextContainerStyle}>
                    {props.email}
                </div>
            </div>
        );
    }

    return (
        <CardHeader avatar={ <AvatarContainer /> }
                    action={ <ActionContainer />}
                    title={ <TitleContainer />}
                    subheader={ <SubHeaderContainer /> }
                    style={{paddingTop: "25px"}}/>
    );
}

const IncidentPostDetailContainer = (props) => {

    const [tabValue, setTabValue] = useState(0);
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const dataBolos = props.data.filter(item => {
        return item.incidentCategoryId === IncidentCategory.Bolos;
    });

    const dataOfficerSafety = props.data.filter(item => {
        return item.incidentCategoryId === IncidentCategory.OfficerSafety;
    });

    const dataAttemptToId = props.data.filter(item => {
        return item.incidentCategoryId === IncidentCategory.AttemptToId;
    });

    return (
        <Collapse in={props.expanded}>
        <CardContent>
            <Box sx={{ width: '100%'}}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example">
                    <Tab label="All Posts" {...a11yProps(0)} />
                    <Tab label="BOLOS" {...a11yProps(1)}/>
                    <Tab label="Attemp to ID" {...a11yProps(2)} />
                    <Tab label="Officer Safety" {...a11yProps(3)}/>
                    </Tabs>
                </Box>
                <TabPanel value={tabValue} index={0}>
                    <GenerateIncidentPostDetail data={props.data} />
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                    <GenerateIncidentPostDetail data={dataBolos} />
                </TabPanel>
                <TabPanel value={tabValue} index={2}>
                    <GenerateIncidentPostDetail data={dataAttemptToId} />
                </TabPanel>
                <TabPanel value={tabValue} index={3}>
                    <GenerateIncidentPostDetail data={dataOfficerSafety} />
                </TabPanel>
            </Box>
        </CardContent>
    </Collapse>

);
}

const GenerateIncidentPostDetail = ({data}) => {
    return (
        <>
            { 
                data.length > 0 ? 
                    (data.map((item) => (
                        <IncidentPostInnerDetailContainer data={item} />
                    ))) : 
                    (<NoResultFoundContainer />)
            }
        </>
    );
}

const InnerDetailImageContainer = (props) => {
    return (
        <Box sx={{  display: 'flex', flexDirection: 'column', padding: "25px 0px 0px 25px",}}>
        {
            props.src !== null ? 
            ( <CardMedia component="img" sx={{ width: "308px", height: "291px", borderRadius: "15px" }}image={props.src} alt="Incident"/>) 
            : 
            (<NoPreviewIcon />) 
        }
        </Box>
    );
}

const InnerDetailInfoContainer = (props) => {

    const ActionContainer = () => {
        return (
            <div>
                <IconButton aria-label="settings" id={"detail-incident-id-" + props.data.incidentId}><MoreVertIcon /></IconButton>
                <UncontrolledPopover trigger="legacy" placement="bottom" target={`${"detail-incident-id-" + props.data.incidentId}`}>
                    <PopoverBody>
                        <PopoverEditRemoveContainer data={props.data}/>
                    </PopoverBody>
                </UncontrolledPopover>
            </div>
        );
    }

    const TitleContainer = () => {
        return (
            <div className="common_font_style" style={{display: "flex", flexDirection: "column", justifyContent: "flex-start"}}>
                <div style={{fontWeight: 500, fontSize: "14px", color: "#008EEE"}}>{props.data.agencyName}</div>
                <div style={{fontWeight: 700, fontSize: "26px", color: "#002542"}}>Incident: {props.data.incidentNum}</div>
            </div>
        );
    }

    const SubHeaderContainerStyle = {
        alignItems: "center", 
        display: "flex", 
        flexDirection: "row", 
        fontFamily: PrimaryFontFamily,
        fontSize: "16px",
        fontWeight: 400, 
        paddingTop: "16px", 
    }

    const SubHeaderTextContainerStyle = {
        color: "#002542",
        padding: "3px 25px 0px 10px",
    }

    // const GenerateCreationTimeSpan = (data) => {
    //     return data.days > 0 ? data.days + (data.days === 1 ? " day ago" : " days ago") :
    //                 data.hours > 0 ? data.hours + (data.hours === 1 ? " hr ago" : " hrs ago") :
    //                     data.minutes > 0 ? data.minutes + (data.minutes === 1 ? " minute ago" : " minutes ago") :
    //                         data.seconds > 0 ? data.seconds + (data.seconds === 1 ? " second ago" : " seconds ago") : ""
    // }

    const SubHeaderContainer = () => {
        return (
            <div style={SubHeaderContainerStyle}>
                <ClockIcon width={25} height={25} />
                <div style={SubHeaderTextContainerStyle}>{props.data.date2}</div>

                <PinLocationIcon width={25} height={25} />
                <div style={SubHeaderTextContainerStyle}>{props.data.address1}</div>

                <FolderIcon width={25} height={25} />
                <div style={SubHeaderTextContainerStyle}>{props.data.incidentType}</div>
            </div>
        );
    }

    return (
        <CardContent sx={{ padding: "0px 0px 0px 35px", width: "100%" }}>
                <CardContent sx={{ flex: '1 0 auto' }}>
                    <CardHeader style={{padding: "25px 25px 0px 0px"}} 
                                action={ <ActionContainer />}
                                title={ <TitleContainer />} 
                                subheader={ <SubHeaderContainer /> }/>
                </CardContent>
                <CardContent>
                    <ReadMore>
                        {props.data.description}
                    </ReadMore>
                </CardContent>
            </CardContent>

    );
}

// Need to separate the Read More under InnerDetailInfoContainer due to bug found when collapsing the item description
export const ReadMore = ({ children }) => {
    const text = children;
    const [isReadMore, setIsReadMore] = useState(true);
    const toggleReadMore = () => {
      setIsReadMore(!isReadMore);
    };
    return (
        <p className="text" style={{fontFamily: PrimaryFontFamily, fontWeight: 500, fontSize: "16px"}}>
            {isReadMore ? text.slice(0, 200) : text} {"  "}
            { text.length > 200 ? 
                ( <span onClick={toggleReadMore} className="read-or-hide" style={{color: "#002542", cursor: "pointer", fontSize: "16px", fontWeight: 400, textDecoration: "underline",fontFamily: PrimaryFontFamily}}>
                    {isReadMore ? "Read More..." : "Show Less..."}
                </span>) 
                : (<></>)
            }
        </p>
    );
  };

const IncidentPostInnerDetailContainer = (props) => {

    return ( 
        <Card sx={{ display: "flex", margin: "10px 0px 20px 0px", boxShadow: "0px 2px 34px rgba(0, 65, 162, 0.08)" }}>
            <InnerDetailImageContainer src={props.data.imageSource}  />
            <InnerDetailInfoContainer data={props.data} />
        </Card>);
}

const IncidentPostFooterContainer = ({expanded, onClick}) => {
    return (
    <CardActions disableSpacing style={{alignItems: "center", justifyContent: "center"}}>
        <div style={ExpandMoreContainerStyle} onClick={onClick}>
            {expanded ? "Hide posts" : "See posts"}
        </div>
        <ExpandMore expand={expanded} onClick={onClick} aria-expanded={expanded} aria-label="show more" >
            <ExpandMoreIcon />
        </ExpandMore>
    </CardActions>
    );
}

const PopoverSuspendContainer = (props) => {

    const departmentContext = UsedDepartmentContext();

    const handleSuspendAccount = (userId) => {
        departmentContext.onSetUserId(userId);
        departmentContext.onShowSuspendAccountDialog(true);
    }

    return (
        <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', borderRadius: "10px" }}>
        <List sx={{padding: "20px"}}>
         <ListItem disablePadding>
             <ListItemButton onClick={() => handleSuspendAccount(props.userId)}>
                 <ListItemIcon >
                     <FontAwesomeIcon className="iconPopup" icon={faTriangleExclamation} size="xl" />
                 </ListItemIcon>
                 <ListItemText primary="Suspend Account" />
             </ListItemButton>
         </ListItem>
         </List>
     </Box>
    );
}

const PopoverEditRemoveContainer = (props) => {
    const departmentContext = UsedDepartmentContext();


    const handleRemovePost = (incidentId) => {
        departmentContext.onSetIncidentId(incidentId);
        departmentContext.onShowDeleteDialog(true);
    }

    return (
        <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', borderRadius: "10px" }}>
           <List sx={{padding: "20px"}} key={"edit-remove-item-" + props.data.incidentId}>
                <ListItem disablePadding>
                    <ListItemButton href={"/post/edit/" + props.data.incidentId}>
                        <ListItemIcon>
                            <FontAwesomeIcon className="iconPopup" icon={faTriangleExclamation} size="xl"/>
                        </ListItemIcon>
                        <ListItemText primary="Edit post" />
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemButton onClick={() => handleRemovePost(props.data.incidentId)}>
                        <ListItemIcon>
                            <FontAwesomeIcon className="iconPopup" icon={faTrashCan} size="xl"/>
                        </ListItemIcon>
                        <ListItemText primary="Remove post" />
                    </ListItemButton>
                </ListItem>
            </List>
        </Box>
    );
}

export const NoResultFoundContainer = (props) => {
    return (
        <div style={{display: "flex", flexDirection: "column", width: "100%", alignItems: "center", padding: "15px"}}>
            <div style={{paddingLeft: "20px"}}>
                <NoResultFoundImage/>
            </div>
            <div style={{fontFamily: PrimaryFontFamily, fontWeight: 400, fontSize: "16px", paddingTop: "15px"}}>
                {props.message ? (<>{props.message}</>) : ("No results found.")}
            </div>
        </div>
    );
}

export const PostShimmeringEffect = () => {
    const AvatarContainer = () => {
        return (
            <Skeleton variant="circular" width={80} height={80} />
        );
    }

    const ActionContainer = () => {
        return (
            <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center"}}>
                <Skeleton variant="rounded" width={4} height={24} />
            </div>
        );
    }

    const TitleContainer = () => {
        return (
            <div style={{display: "flex", flexDirection: "row",  alignItems: "center"}}>
                <div style={{marginRight: "10px"}}><Skeleton variant="rounded" width={140} height={30} /></div>
                <div><Skeleton variant="rounded" width={76} height={20} /></div>
            </div>
        );
    }

    const SubDetails = () => {
        return (
                <>
                <div style={{marginRight: "10px"}}>
                    <Skeleton variant="rounded" width={25} height={15} />
                </div>
                <div style={{marginRight: "10px"}}>
                    <Skeleton variant="rounded" width={135} height={15} />
                </div>
                </>
             
        );
    }

    const SubHeaderContainer = () => {
        return (
            <div style={SubHeaderContainerStyle}>
                <SubDetails />
                <SubDetails />
                <SubDetails />
            </div>
        );
    }

    return (
        <Card {...IncidentPostMainContainerStyle}>
            <CardContent>
                <CardHeader avatar={ <AvatarContainer /> }
                            action={ <ActionContainer />}
                            title={ <TitleContainer />}
                            subheader={ <SubHeaderContainer /> }
                            style={{paddingTop: "25px"}}/>
                <CardActions disableSpacing style={{alignItems: "center", justifyContent: "center"}}>
                    <div style={{marginRight: "10px"}}><Skeleton variant="rounded" width={75} height={15} /></div>
                    <div><Skeleton variant="rounded" width={15} height={15} /></div>
                </CardActions>
            </CardContent>
        </Card>
    );
}

export const CenterAllStyle = {
    alignItems: "center", 
    justifyContent: "center", 
    alignContent: "center",
}

export const ButtonFontStyle ={
    fontfamily: PrimaryFontFamily,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div  role="tabpanel" 
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other} >
        {value === index && (
          <Box style={{paddingTop: "20px"}}>
            <div>{children}</div>
          </Box>
        )}
      </div>
    );
}

const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
    })(({ theme, expand }) => ({ 
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    transition: theme.transitions.create('transform', {duration: theme.transitions.duration.shortest,}),
}));

const ExpandMoreContainerStyle = {
    color: "#002542", 
    cursor: "pointer",
    fontFamily: PrimaryFontFamily, 
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 500,
}

const IncidentPostMainContainerStyle = {
    sx: {
        boxShadow: "0px 4px 24px rgba(0, 31, 77, 0.1)",
        maxWidth: "99%",
    }
}
//[End] - Incident Post Container
