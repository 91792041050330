/*eslint unicode-bom: ["error", "always"]*/
import React, { useEffect, useState } from "react";
import Axios from "axios";
import './MyPost.css';
import { Link } from 'react-router-dom';
import { Row, Col,Nav } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Input, Modal, NavItem, NavLink } from 'reactstrap';
import { faCalendarDays, faShield } from '@fortawesome/free-solid-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core';
import MyCardPost from "../MyPost/MyCardPost";
import { useHistory } from 'react-router-dom';
import 'react-notifications/lib/notifications.css';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import AuthService from "../../services/auth.service";
import Loader from "../Common/loader";
import api from "../../services/api";

library.add(faCalendarDays, faShield);
const MyPost = () => {
    let history = useHistory();
    const [dateStart, setDateStart] = useState("");
    const [dateEnd, setDateEnd] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [draft, setDraft] = useState(true);
    const [published, setPublished] = useState(true);
    const [removed, setRemoved] = useState(true);
    const [search, setSearch] = useState("");
    const [listPost, setListPost] = useState([]);
    const SearchFilterDateStart = (e) => {
        setDateStart(e.target.value);
        if (dateEnd !="") {
            let startdate = new Date(e.target.value);
            let endate = new Date(dateEnd);
            if (endate > startdate) {
                SearchMyPost(e.target.value, dateEnd,search,draft,published,removed);
            } else {
                NotificationManager.error('Error message', 'The final date cannot be greater than the initial date', 5000);
            
            }
        }
    }
    const ToogleDraft = (e) => {
        setDraft(!draft);
        SearchMyPost(dateStart, dateEnd, search,!draft,published,removed);
    }
    const TooglePublished = (e) => {
        setPublished(!published);
        SearchMyPost(dateStart, dateEnd, search,draft,!published,removed);
    }
    const ToogleRemoved = (e) => {
        setRemoved(!removed);
        SearchMyPost(dateStart, dateEnd, search, draft, published,!removed);
    }
    const ChangeSearch = (e) => {
        setSearch(e.target.value);
        
    }
    const SearchGo = (e) => {
        SearchMyPost(dateStart, dateEnd, search, draft, published,removed);

    }
    const SearchFilterDateEnd = (e) => {
        setDateEnd(e.target.value);
        if (dateStart != "") {
            let endate = new Date(e.target.value);
            let startdate = new Date(dateStart);
            if (endate > startdate) {
                SearchMyPost(dateStart, e.target.value, search, draft, published);
            } else {
                NotificationManager.error('Error message', 'The final date cannot be greater than the initial date', 5000);
                
            }
        }
    }
    const SearchMyPost = (x, y, z,draft,published,removed) => {
        if (x==undefined) {
            x = "";
        }
        if (y == undefined) {
            y = "";
        }
        if (z == undefined) {
            z = "";
        }
        if (draft == undefined) {
            draft = true;
        }
        if (published == undefined) {
            published = true;
        }
        if (removed == undefined) {
            removed = true;
        }
        setIsLoading(true);
        api.get(
            "/incidents/mypost?dstart=" + x + "&dend=" + y + "&search=" + z + "&draft=" + draft + "&published=" + published + "&removed=" + removed
        )
            .then((response) => {
                let listfeed = [];
                console.log(response);
                response.data.forEach((element) => {

                    listfeed.push(element);
                });
                setListPost(listfeed);
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error);
            });
    }
    useEffect(() => {
        const formData = new FormData();
        SearchMyPost();

    }, [setListPost]);
    return (
        <>
          
            
                    <Row>

                        <Col sm={12}>

                            <div className="container">

                                <legend className='legendpost' >My Post</legend>
                                <div className="header-content">

                                    <div className="row">
                                        <div className="col-3">
                                            <div className="input-group mb-12">
                                                <input type="text" className="form-control" placeholder="Keywords search..." onChange={ChangeSearch} value={search} />
                                                <div className="input-group-append">
                                                    <span className="input-group-text" id="basic-addon2" onClick={SearchGo}>GO!</span>
                                                </div>
                                            </div>
                                            <div className="row">

                                            </div>
                                        </div>
                                        <div className="col-1"></div>
                                        <div className="col-4">

                                            <div className="input-group mb-5">
                                                <label className="labelinputgroup">From:</label>
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text" id="basic-addon1">  <FontAwesomeIcon icon={faCalendarDays} /></span>
                                                </div>
                                                <input type="datetime-local" className="form-control" placeholder="Incident Date and Time" onChange={SearchFilterDateStart} aria-label="Username" aria-describedby="basic-addon1" value={dateStart} />
                                            </div>
                                        </div>
                                        <div className="col-4">

                                            <div className="input-group mb-5">
                                                <label className="labelinputgroup">To:</label>
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text" id="basic-addon1">  <FontAwesomeIcon icon={faCalendarDays} /></span>
                                                </div>
                                                <input type="datetime-local" className="form-control" placeholder="Incident Date and Time" onChange={SearchFilterDateEnd} aria-label="Username" aria-describedby="basic-addon1" value={dateEnd} />
                                            </div>
                                        </div>

                                    </div>
                                    <div className="row">
                                        <div className="col-1">
                                            <input type="checkbox" className="btn-check" id="btn-check-2-outlined" onChange={ToogleDraft} checked={draft} />
                                            <label className={`btn btn-primary ${draft ? "" : "inactive"}`} htmlFor="btn-check-2-outlined">Draft</label><br />
                                        </div>
                                        <div className="col-1">
                                            <input type="checkbox" className="btn-check" id="btn-check-3-outlined" onChange={TooglePublished} checked={published} />
                                            <label className={`btn btn-primary ${published ? "" : "inactive"}`} htmlFor="btn-check-3-outlined">Published</label><br />

                                </div>
                                <div className="col-1">
                                    <input type="checkbox" className="btn-check" id="btn-check-4-outlined" onChange={ToogleRemoved} checked={removed} />
                                    <label className={`btn btn-primary ${removed ? "" : "inactive"}`} htmlFor="btn-check-4-outlined">Removed</label><br />

                                </div>
                                    </div>
                                </div>

                            {isLoading ? (
                                <Loader />
                            ) : (
                                <MyCardPost listpost={listPost} reloadData={()=>SearchMyPost(dateStart, dateEnd, search, draft, published,removed)}></MyCardPost>
                            )}
                            </div>


                        </Col>

                    </Row>
                    <div className="social-icons">
                        <div className="d-flex flex-row flex-lg-column justify-content-center align-items-center h-100 mt-3 mt-lg-0 buttonadd">

                            <NavLink tag={Link} className="btn btn-dark m-3" to="/post/create">Create Post</NavLink>

                        </div>
            </div>
            <Col sm={12} id="footer">

                <Nav variant="pills" className="flex-column navs">
                    <NavItem >
                        <NavLink className="active" eventKey="first">MY POSTS</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink tag={Link}  to="/mysavedposts">SAVED POSTS</NavLink>
                    </NavItem>
                    


                </Nav>
            </Col>
                    <NotificationContainer />
             
            
        </>
    );
};
export default MyPost;