import React, { Component } from 'react';

export class LayoutNoLogged extends Component {
    static displayName = LayoutNoLogged.name;

    render() {
        return (
            <div className="mainLoggedOut">
                {this.props.children}
            </div>
        );
    }
}
