import React, { useState } from 'react';
import { FaUser, FaHome } from "react-icons/fa";
import homelogo from "../../assets/home-w.png";
import msglogo from "../../assets/msg-w.png";
import dptlogo from "../../assets/department-w.png";
import accountlogo from "../../assets/account-w.png";
import officelogo from "../../assets/office-w.png";
import { Collapse, Navbar, NavbarToggler, NavItem, NavLink, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Link, useHistory } from 'react-router-dom';
import './NavMenu.css';
import AuthService from "../../services/auth.service";


const NavMenu = () => {
    const [url, setUrl] = useState(window.location.pathname);
    const [collapsed, setCollapsed] = useState(true);
    let history = useHistory();
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [dropdownOpenMenu, setDropdownOpenMenu] = useState(false);
    const toggle = () => {
        setDropdownOpen(!dropdownOpen);
    }
    const ChangeActive = (url) => {
        setUrl(url);
    }
    const toggleMenu = () => {
        setDropdownOpenMenu(!dropdownOpenMenu);
    }
    const toggleNavbar = () => {
        setCollapsed(!collapsed);
    }
    const logout = () => {
        history.push("/login");
        history.go(0);
        AuthService.logout();
    }

    let isLoggedIn = AuthService.islogged();
    return (
        <div>
            {isLoggedIn ? (
                <ul className="ulnav">
                    <NavItem className={`navbar-tab ${url == "/home" || url == "/" ? "active" : ""}`} onClick={() => ChangeActive("/home")}>
                        <NavLink tag={Link} className="text-dark navlink" to="/home"> <img src={homelogo} className="iconsnav" />  <span className="navtext">Home</span></NavLink>
         
                    </NavItem>
                    <NavItem className={`navbar-tab ${url == "/messages" ? "active" : ""}`} onClick={() => ChangeActive("/messages")}>
                        <NavLink tag={Link} className="text-dark navlink" to="/messages"> <img src={msglogo} className="iconsnav" />  <span className="navtext">Message</span></NavLink>

                    </NavItem>
                    <NavItem className={`navbar-tab ${url == "/agencies" ? "active" : ""}`} onClick={() => ChangeActive("/agencies")}>
                        <NavLink tag={Link} className="text-dark navlink" to="/agencies"> <img src={officelogo} className="iconsnav" />  <span className="navtext">Agencies</span></NavLink>

                    </NavItem>
              
                      
                        </ul>
                    ) : (
                        <ul className="flex-grow">
                            <NavItem className='noborder'>
                            <NavLink tag={Link} className="text-dark navlink" to="/login">Login</NavLink>
                            </NavItem>
                        </ul>
                    )}


</div>
    );
}
export default NavMenu;
