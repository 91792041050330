import DateRangePicker from 'react-bootstrap-daterangepicker';
import { useState } from "react";
import 'bootstrap-daterangepicker/daterangepicker.css';
import moment from 'moment';

const CustomPicker = (props) => {

    //DatePikcer 
    const [state, setState] = useState({
        start: moment().subtract(10, 'years'),
        end: moment(),
    });
    const { start, end } = state;
    const handleCallback = (start, end) => {
        setState({ start, end });
        props.SearchFilterDate({ start, end });
    };
    const label =
        start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY');


    return <>
        <DateRangePicker
            placeholder="Choose a date"
            initialSettings={{
                startDate: start,
                endDate: end,
                ranges: {
                    'All': [
                        moment().subtract(10, 'years').toDate(),
                        moment().toDate(),
                    ],
                    Today: [moment().toDate(), moment().toDate()],
                    Yesterday: [
                        moment().subtract(1, 'days').toDate(),
                        moment().subtract(1, 'days').toDate(),
                    ],
                   
                    'Last 7 Days': [
                        moment().subtract(6, 'days').toDate(),
                        moment().toDate(),
                    ],
                    'Last 30 Days': [
                        moment().subtract(29, 'days').toDate(),
                        moment().toDate(),
                    ],
                    'This Month': [
                        moment().startOf('month').toDate(),
                        moment().endOf('month').toDate(),
                    ],
                    'Last Month': [
                        moment().subtract(1, 'month').startOf('month').toDate(),
                        moment().subtract(1, 'month').endOf('month').toDate(),
                    ],
                },
            }}
            onCallback={handleCallback}
        >
            <div
                id="reportrange"
                className="col-12"
                style={{
                    background: '#fff',
                    cursor: 'pointer',
                    padding: '5px 10px',
                    border: '1px solid #ccc',
                    width: '100%',
                }}
            >
                <i className="fa fa-calendar"></i>&nbsp;
                <span>{label}</span> <i className="fa fa-caret-down"></i>
            </div>
        </DateRangePicker>
        </>
}

export default CustomPicker;